import React, { ReactElement } from "react";
import styles from "../../../index.module.scss";
import { IVacOfSubItemDep } from "../../../../../typec/IDocumentRequest";

const VacOfSubItemDep = (props: IVacOfSubItemDep): ReactElement => {
    const { nameDep, onClick = () => null } = props;
    const click = (nameDep) => {
        onClick(nameDep);
    };
    return (
        <>
            <div onClick={() => click(nameDep)} className={styles.doc_asg_invent_item}>
                <p className={styles.doc_asg_invent_item_title}>{nameDep}</p>
                <p className={styles.doc_asg_invent_item_quantity}> </p>
            </div>
        </>
    );
};

export { VacOfSubItemDep };
