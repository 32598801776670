import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Navigation from "../../../components/Navigation";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import AppealItem from "./components/AppealItem";
import AppealInfo from "./components/AppealInfo";
import { IMyListAppeals } from "../../../typec/IClientSupport";
import Notification from "../../../components/Notification";

const MyAppeals = (): ReactElement => {
    const context = useContext(NavigationContext);
    const [load, setLoad] = useState(true);
    const [infoScreen, setInfoScreen] = useState({
        view: false,
        numberAppeal: "",
        date: "",
        tag: "",
        req_id: "",
        text: " ",
    });
    const [userData, setData] = useState<IMyListAppeals>();

    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };

    const getLoad = () => {
        setLoad(true);
    };

    useEffect(() => {
        if (load) {
            fetch(`/dev/vacation_feedback_new?req_id=${""}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        setLoad(false);
                        setData(json);
                    } else {
                        setLoad(false);
                    }
                });
        }
    }, [load]);

    const clickAppeal = (numberAppeal, date, tag, photos, text) => {
        setInfoScreen({
            view: true,
            numberAppeal: numberAppeal,
            date: date,
            tag: tag,
            req_id: photos,
            text: text,
        });
    };

    const closeInfo = () => {
        setInfoScreen({
            view: false,
            numberAppeal: "",
            date: "",
            tag: "",
            req_id: "",
            text: "",
        });
    };

    const isNotNullAppeals = (): boolean => {
        let bool = true;
        const schedule = userData?.requests_list;
        if (`${schedule}` == "" || userData?.res == false) {
            bool = false;
        }
        return bool;
    };

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    {isNotNullAppeals() ? (
                        <>
                            {infoScreen.view ? (
                                <>
                                    <Navigation prev={closeInfo} goToMain={goToMain}></Navigation>
                                    <AppealInfo
                                        key_info={`appl_info_&{infoScreen.req_id}`}
                                        numberAppeal={infoScreen.numberAppeal}
                                        date={infoScreen.date}
                                        tag={infoScreen.tag}
                                        req_id={infoScreen.req_id}
                                        text={infoScreen.text}
                                        closeInfo={closeInfo}
                                        getLoad={getLoad}
                                    />
                                </>
                            ) : (
                                <>
                                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                                    <div className={styles.mappl}>
                                        <p className={styles.mappl_title}>Мои обращения</p>
                                        {userData?.requests_list?.map((element, index) => (
                                            <AppealItem
                                                onClick={clickAppeal}
                                                date={element.CreatedTime}
                                                numberAppeal={String(element.Number)}
                                                text={element.Description}
                                                tag={element.Status}
                                                req_id={element.ID}
                                                key_item={`appeal_info_${index}`}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Navigation prev={prev} goToMain={goToMain}></Navigation>
                            <div className={styles.mappl}>
                                <p className={styles.mappl_title}>Мои обращения</p>
                                <Notification icon="info" type="info">
                                    У вас пока нет оформленных <br /> обращений. Возможность просмотра заявлений появится после успешного оформления.
                                </Notification>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default MyAppeals;
