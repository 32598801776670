import React, { createRef, CSSProperties, LegacyRef, ReactElement, useState } from "react";
import styles from "../index.module.scss";
import clsx from "clsx";
import { InputTitle } from "../InputTitle";
import { InputError } from "../InputError";
import { IMask, IMaskInput, useIMask } from "react-imask";

export interface IInputPhoneProps {
    onChange?: (value) => void;
    type: "phone";
    name?: string;
    value?: string;
    titleText?: string;
    errorMessage?: string;
    clearError?: () => void;
    editData?: (key, element) => void;
    placeholder?: string;
    style?: CSSProperties;
    onFocus?: () => void;
    onBlur?: () => void;
}

const InputPhone = (props: IInputPhoneProps): ReactElement => {
    const {
        onChange = (value) => null,
        type,
        name,
        titleText,
        clearError = () => null,
        editData,
        errorMessage,
        value,
        placeholder,
        style,
        onFocus = () => null,
        onBlur = () => null,
    } = props;
    const [ifValue, setValue] = useState(true);

    const [options] = useState({
        mask: "+7 000 000 00 00",
        placeholderChar: "_",
        lazy: true,
        unmask: true,
    });

    const { ref } = useIMask<HTMLInputElement & typeof IMaskInput>(options, {
        onAccept: (value) => {
            editData && editData(name, value.replace(/\s+/g, ""));
            onChange(value.replace(/\s+/g, ""));
            if (/[0-6]|[8-9]/.test(value)) setValue(false);
            else setValue(true);
            clearError();
        },
    });

    const refInput = createRef<HTMLInputElement>();

    const scrollDown = () => {
        // setTimeout(() => {
        refInput.current?.scrollIntoView({ behavior: "auto", block: "center" });
        // }, 200);
    };
    const keyDownBlur = (e) => {
        if (e.key === "Enter" || e.key === "enter") e.target.blur();
    };

    return (
        <div
            style={style}
            //  onClick={scrollDown}
            ref={refInput}
        >
            {titleText && <InputTitle titleText={titleText} />}
            <input
                onKeyDown={(e) => keyDownBlur(e)}
                inputMode="numeric"
                ref={ref as LegacyRef<HTMLInputElement> | undefined}
                defaultValue={value}
                placeholder={placeholder}
                onFocus={() => onFocus()}
                onBlur={() => onBlur()}
                // onChange={(value) => onChange(value)}
                className={clsx(styles["inp"], styles[`inp_${type}`], errorMessage && styles[`inp_error`], ifValue && styles[`inp_val`])}
            />
            {errorMessage && <InputError titleText={errorMessage} />}
        </div>
    );
};

export { InputPhone };
