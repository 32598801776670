import React, { ReactElement } from "react";
import styles from "../index.module.scss";
import { IInputTitle } from "../../../typec/IInputs";

const InputTitle = (props: IInputTitle): ReactElement => {
    const { titleText, style } = props;

    return (
        <p style={style} className={styles.inp_text_title}>
            {titleText}
        </p>
    );
};

export { InputTitle };
