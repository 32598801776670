import React, { ReactElement } from "react";
import styles from "../../index.module.scss";
import { IAssignedInventItem } from "../../../../typec/IDocumentRequest";

const AssignedInventItem = (props: IAssignedInventItem): ReactElement => {
    const { nameTMTS, kolichestvo, yedinitsaIzmereniya } = props;
    return (
        <>
            <div className={styles.doc_asg_invent_item}>
                <p className={styles.doc_asg_invent_item_title}>{nameTMTS}</p>
                <p className={styles.doc_asg_invent_item_quantity}>
                    {kolichestvo} {yedinitsaIzmereniya.toLowerCase()}
                </p>
            </div>
        </>
    );
};

export default AssignedInventItem;
