import React, { ReactElement, useState } from "react";
import styles from "../index.module.scss";
import { InputBill, InputTextArea } from "../../../../components/Inputs";
import SlideScreen from "../../../../components/SlideScreen";
import Button from "../../../../components/Button";
import { IEditDataPO } from "../../../../typec/IPODetails";
import Notification from "../../../../components/Notification";

const EditDataPO = (props: IEditDataPO): ReactElement => {
    const { saveNewData, load, errors, clearError, zypError } = props;
    const [data, setData] = useState({
        bank_name: "",
        INN: "",
        BIC: "",
        numberCheck: "",
    });
    const [viewSlider, setViewSlider] = useState(true);

    const editData = (key, element) => {
        setData((prevState) => {
            return {
                ...prevState,
                [key]: element,
            };
        });
    };

    const inp = document.querySelector("#test_inp") as HTMLElement;
    if (inp) {
        console.log("инпут есть");
        inp.addEventListener("focus", () => {
            inp.style.opacity = "0";
            setTimeout(() => (inp.style.opacity = "1"));
        });
    }
    return (
        <>
            <div className={styles.data_edit}>
                <p className={styles.data_edit_title}>Новые реквизиты для перечисления ЗП</p>
                {zypError !== "" && (
                    <div className={styles.error}>
                        <Notification type="err_not_margin" icon="exclamation-mark">
                            {zypError}
                        </Notification>
                    </div>
                )}
                <InputTextArea
                    editData={editData}
                    errorMessage={errors?.BankName}
                    clearError={clearError}
                    name="bank_name"
                    value={data.bank_name}
                    type="area"
                    titleText="Название банка"
                    placeholder="Введите полное название банка"
                    style={{ marginBottom: "24px" }}
                    onBlur={() => setViewSlider(true)}
                    onFocus={() => setViewSlider(false)}
                />
                <InputBill
                    editData={editData}
                    name="INN"
                    errorMessage={errors?.BankInn}
                    clearError={clearError}
                    value={data.INN}
                    type="INN"
                    titleText="ИНН"
                    placeholder="Введите ИНН банка"
                    onBlur={() => setViewSlider(true)}
                    onFocus={() => setViewSlider(false)}
                />
                <InputBill
                    editData={editData}
                    name="BIC"
                    errorMessage={errors?.BankBic}
                    clearError={clearError}
                    value={data.BIC}
                    type="BIC"
                    titleText="БИК"
                    placeholder="Введите БИК банка"
                    style={{ marginTop: "24px" }}
                    onBlur={() => setViewSlider(true)}
                    onFocus={() => setViewSlider(false)}
                />
                <InputBill
                    id_inp="test_inp"
                    editData={editData}
                    name="numberCheck"
                    errorMessage={errors?.BankSchet}
                    clearError={clearError}
                    value={data.numberCheck}
                    type="numberCheck"
                    titleText="Номер счета"
                    placeholder="Введите номер счёта"
                    style={{ marginTop: "24px" }}
                    onBlur={() => setViewSlider(true)}
                    onFocus={() => setViewSlider(false)}
                />
                <div style={{ marginTop: "88px" }} />
                {viewSlider ? (
                    <SlideScreen altitudeLevel="service">
                        <Button
                            disabled={!(data.bank_name && data.BIC && data.INN && data.numberCheck)}
                            load={load}
                            type="save_not_margin"
                            onClick={() => saveNewData && saveNewData(data)}
                        >
                            Отправить
                        </Button>
                    </SlideScreen>
                ) : null}
            </div>
        </>
    );
};

export default EditDataPO;
