import React, { ReactElement, useContext, useRef, useState } from "react";
import { InputDropDown, InputError, InputPhone, InputTextArea } from "../../../../components/Inputs";
import SlideScreen from "../../../../components/SlideScreen";
import Button from "../../../../components/Button";
import styles from "./index.module.scss";
import NewPhotoButtonLimit from "../../../ClietSupport/NewAppeal/components/NewPhotoButtonLimit";
import { NavigationContext } from "../../../../template";
import Docs from "../docs";

const ChoseReason = (props): ReactElement => {
    const { actionElement = () => null, reasons, addData, VidyPD, clearError = () => null, error } = props;
    const result = reasons.map((item) => item.VidPersonalnyhDannyh);
    const [data, setData] = useState({
        VidPersonalnyhDannyh: VidyPD.VidPersonalnyhDannyh,
        Prichina: VidyPD.Prichina,
        screen_zayav: VidyPD.screen_zayav,
        screens: VidyPD.screens,
        Comment: VidyPD.Comment,
    });

    const [viewSlider, setViewSlider] = useState(true);

    const [err, setErr] = useState("");
    const [reset, setReset] = useState(false);

    const context = useContext(NavigationContext);

    const CurrentData = reasons.find((item) => item.VidPersonalnyhDannyh === data.VidPersonalnyhDannyh);

    const Prichina = CurrentData?.Prichiny.map((item) => item.Prichina);

    const OpisanieEmpty = CurrentData?.Prichiny.map((item) => item.Opisanie);

    const Opisanie = CurrentData?.Prichiny.find((item) => item.Prichina === data.Prichina);

    const editData = (key, element) => {
        clearErr();
        if (key != "VidPersonalnyhDannyh") {
            setData({
                ...data,
                [key]: element,
            });
        } else {
            setReset(true);
            setData({
                ...data,
                [key]: element,
                screen_zayav: [],
                Prichina: "",
                Comment: "",
                screens: [],
            });
        }
    };

    const saveData = (key, element) => {
        addData(key, element);
        actionElement();
    };
    const clearErr = () => {
        clearError();
        setErr("");
    };

    const addPhoto = (el) => {
        setData((prevState) => ({
            ...prevState,
            screen_zayav: el.map((element) => ({
                id: `${element.id}`,
                data: `${element.data}`,
            })),
        }));
    };

    const addPhotoDocument = (el) => {
        setData((prevState) => ({
            ...prevState,
            screens: el.map((element) => ({
                id: `${element.id}`,
                data: `${element.data}`,
            })),
        }));
    };

    const [vis, setVis] = useState(false);

    const switchCancel = () => {
        if (vis) {
            setVis(false);
            document.body.style.overflow = "auto";
        } else {
            setVis(true);
            document.body.style.overflow = "hidden";
        }
    };

    return (
        <>
            <InputDropDown
                key="InpDropDownVidPersonalnyhDannyh"
                errorMessage={error?.Error}
                value={data.VidPersonalnyhDannyh}
                editData={editData}
                name="VidPersonalnyhDannyh"
                items={result}
                icon="icon_drop_down"
                titleText="Вид персональных данных"
            />
            {Prichina?.length > 1 ? (
                <InputDropDown
                    key="InpDropDownPrichina"
                    errorMessage={error?.Error}
                    value={data.Prichina}
                    editData={editData}
                    name="Prichina"
                    items={Prichina}
                    icon="icon_drop_down"
                    titleText="Причина изменения"
                />
            ) : null}
            {err && <InputError titleText={err} />}
            {Opisanie !== undefined ? (
                <>
                    <p className={styles.second_title}>Фото заявления</p>
                    <p className={styles.third_title}>
                        Напишите заявление по <span onClick={switchCancel}>образцу</span>
                    </p>
                    {vis ? (
                        <SlideScreen altitudeLevel="full" titleText={"Образец заявления"} onClose={() => switchCancel()}>
                            <div className={styles.doc}>
                                <Docs file={Opisanie?.length !== 0 ? Opisanie?.VidPers : null} />
                            </div>
                        </SlideScreen>
                    ) : null}
                    <div key="DivDismissalPhotos" className={styles.photos}>
                        <NewPhotoButtonLimit onChange={(value) => addPhoto(value)} limit={1} reset={reset} setReset={setReset} />
                    </div>
                    <p className={styles.second_title}>{CurrentData?.AddFiles ? "Фото подтверждающих документов" : "Укажите новые данные"}</p>
                    <p className={styles.third_title}>{OpisanieEmpty?.length == 1 ? OpisanieEmpty : Opisanie?.Opisanie}</p>
                    {CurrentData?.AddFiles ? (
                        <div key="DivDismissalDocument" className={styles.photos}>
                            <NewPhotoButtonLimit onChange={(value) => addPhotoDocument(value)} limit={20} reset={reset} setReset={setReset} />
                        </div>
                    ) : data.VidPersonalnyhDannyh === "Изменение личного номера мобильного телефона" ? (
                        <div className={styles.input}>
                            <InputPhone
                                editData={editData}
                                name="Comment"
                                type="phone"
                                placeholder="+7 999 999 99 99"
                                value={data.Comment}
                                errorMessage={err}
                                clearError={clearError}
                                onBlur={() => setViewSlider(true)}
                                onFocus={() => setViewSlider(false)}
                            />
                        </div>
                    ) : (
                        <div className={styles.input}>
                            <InputTextArea
                                editData={editData}
                                name="Comment"
                                value={data.Comment}
                                type="area"
                                placeholder="Новые данные"
                                counterView={true}
                                counterValue="200"
                                errorMessage={error?.Error}
                                style={{ marginBottom: "24px" }}
                                onBlur={() => setViewSlider(true)}
                                onFocus={() => setViewSlider(false)}
                            />
                        </div>
                    )}
                </>
            ) : null}
            {/* <div className={styles.footer}> */}
            {viewSlider ? (
                <SlideScreen altitudeLevel="service">
                    <Button
                        onClick={() => saveData("VidyPD", data)}
                        disabled={
                            !data.VidPersonalnyhDannyh ||
                            data.screen_zayav.length == 0 ||
                            (data.screens.length == 0 && data.Comment.length == 0) ||
                            (data.screens.length == 0 && data.Comment.length < 12 && data.VidPersonalnyhDannyh === "Изменение личного номера мобильного телефона") ||
                            (Prichina[0] != "" && data.Prichina.length == 0)
                        }
                        type="save_not_margin"
                    >
                        Сформировать заявление
                    </Button>
                </SlideScreen>
            ) : null}
            {/* </div> */}
        </>
    );
};

export default ChoseReason;
