import React, { useContext } from "react";
import styles from "./index.module.scss";
import Button from "../Button";
import BackStrelka from "../../icons/StatesGray.svg";
import { NavigationContext } from "../../template";

const Navigation = (props) => {
    const { children, onClick = () => null } = props;
    const context = useContext(NavigationContext);
    return (
        <div className={styles.navigation}>
            <Button onClick={props.prev} type="back">
                <BackStrelka />
                Назад
            </Button>
            <Button onClick={props.goToMain} type="exit">
                Главное меню
            </Button>
        </div>
    );
};

export default Navigation;
