import React, { ReactElement } from "react";
import styles from "../index.module.scss";
import { IInputError } from "../../../typec/IInputs";

const InputError = (props: IInputError): ReactElement => {
    const { titleText, style } = props;

    return (
        <p style={style} className={styles.inp_text_error}>
            {titleText}
        </p>
    );
};

export { InputError };
