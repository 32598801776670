import React, { ReactElement, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Notification from "../../../../../components/Notification";
import Button from "../../../../../components/Button";
import { IVacationItemShell } from "../../../../../typec/IVacations";
import word from "../../../../../components/word";
import clsx from "clsx";

const VacationItemShell = (props: IVacationItemShell): ReactElement => {
    const {
        typeItem, // тип карточки отпуска
        valueStartDay, // начало отпуска
        valueFinishDay, // конец отпуска
        valueAmount, // кол-во дней
        typeText,
        onActivElement = () => null,
    } = props;

    return (
        <>
            <div className={clsx(styles["vacation"])}>
                <div className={styles.vacation_day}>
                    <span className={styles.vacation_day_validity}>
                        {valueStartDay}&nbsp;–&nbsp;
                        {valueFinishDay ? (
                            <span className={styles.vacation_day_validity_true}>{valueFinishDay}</span>
                        ) : (
                            <span className={styles.vacation_day_validity_false}></span>
                        )}
                    </span>
                    <span className={styles.vacation_day_duration}>
                        {valueAmount} {word(Number(valueAmount), ["день", "дня", "дней"])}
                    </span>
                </div>
                <div className={styles.vacation_buttons}>
                    <Button
                        disabled={typeItem == "signed" ? true : false}
                        icon={typeItem === "graph" || typeItem === "change" ? "change" : ""}
                        children="Изменить"
                        type={typeText == "paid" ? "invisible" : typeItem == "consider" ? "cancel" : typeItem === "graph" || typeItem === "change" ? "change" : "invisible"}
                        onClick={() => onActivElement()}
                    />
                    {!(typeItem === "change") && !(typeItem === undefined) ? (
                        <Notification type={typeItem === "consider" ? "coordination" : typeItem === "graph" ? "graph" : "signed"}>{typeText}</Notification>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default VacationItemShell;
