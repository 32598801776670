import React, { useEffect, useState } from "react";
import styles from "../index.module.scss";
import clsx from "clsx";
import DynamicIcon from "../../DynamicIcon";
import { InputError, InputTitle } from "..";
import SlideScreen from "../../SlideScreen";
import Button from "../../Button";
import CalendarClear from "react-calendar";
import "./Calendar.scss";
import ArrowLeft from "../../../icons/arrow-left-calendar.svg";
import ArrowRight from "../../../icons/arrow-right-calendar.svg";
import { IInputCalendar } from "../../../typec/IInputs";

const InputCalendar = (props: IInputCalendar): React.ReactElement => {
    const {
        icon,
        minDate,
        maxDate,
        titleText,
        type = "dropdown",
        editData,
        editVacation,
        clearError,
        name = "valueStartDay",
        value,
        errorMessage,
        placeholder,
        onClick = () => null,
        titleTextSlide,
        dateNow,
        disable,
        style,
        maxDetail = "month",
        minDetail = "year",
        disableWeeks = false,
        defaultValue = new Date(),
        disableValue = false,
        recDate,
    } = props;
    const [prev, setPrev] = useState<string | undefined>(value);
    const [showSlide, setShow] = useState({
        show: false,
        showCancel: false,
        modified: prev != placeholder ? true : false,
    });

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const getMaketDate = (element: string, array: Array<string>): string => {
        const date = String(element).split(" ");
        const year = `${date[3]}`.replace("20", "");
        const month = array.indexOf(date[1], 0) + 1;
        const day = date[2];
        const trueFormat = `${day}.${month < 10 ? `0${month}` : month}.${year}`;
        return trueFormat;
    };

    const show = (element) => {
        setShow({
            ...showSlide,
            ...element,
        });
    };
    const click = () => {
        onClick();
        show({ show: true });
    };

    const editClearData = (element) => {
        editPrev(String(element));
    };
    const editPrev = (element) => {
        setPrev(getMaketDate(element, months));
        if (clearError) clearError("text");
    };
    const saveDate = (key, element) => {
        if (maxDetail === "year") {
            const data = element.split(".").map((el, index) => {
                if (index > 0) return el;
            });
            if (editData) editData(key, data.join(".").replace(".", ""));
        } else {
            if (editData) editData(key, element);
        }
        if (editVacation) editVacation({ [key]: element });
        if (clearError) clearError("text");
        show({ show: false });
    };

    const toDate = (element: string | undefined): string | undefined => {
        const getYearDateToCentury = (date: Date): Array<number> => {
            return date.getFullYear() > 1999 ? [2, 0] : [1, 9];
        };

        let date: string | undefined = undefined;
        const lastCentury = [1, 9];
        const newCentury = [2, 0];
        if (element != "" && element != undefined) {
            // const resCentury = Number(`${new Date().getFullYear()}`.slice(2)) >= Number(element.split(".")[2]) ? newCentury : lastCentury;
            const resCentury = Number(`${new Date().getFullYear()}`.slice(2)) >= Number(element.split(".")[2]) ? newCentury : minDate ? getYearDateToCentury(minDate) : lastCentury;
            //console.log(Number(`${new Date().getFullYear()}`.slice(2)));
            //console.log(Number(element.split(".")[2]));
            //console.log(Number(`${new Date().getFullYear()}`.slice(2)) >= Number(element.split(".")[2]));
            const year = [...resCentury, ...element.split(".")[2].split("")].join("");
            //console.log(year);
            const month = element.split(".")[1];
            const day = element.split(".")[0];
            date = `${year}-${month}-${day}`;
        }
        return date;
    };

    useEffect(() => {
        if (showSlide.show) {
            document.body.style.overflow = "hidden";
        } else document.body.style.overflow = "auto";
    });

    useEffect(() => {
        if (dateNow) {
            if (editVacation) {
                editVacation({ [name]: String(`${new Date().toLocaleDateString("ru-RU")}`).replace(".20", ".") });
            }
        }
    }, [disable]);

    return (
        <div style={style}>
            {titleText && <InputTitle titleText={titleText} />}
            <div className={styles.inp_dropdown_container}>
                <button disabled={disable} onClick={click} className={clsx(styles["inp"], styles["inp_dropdown"], styles[`inp_${type}`], errorMessage && styles[`inp_error`])}>
                    <p className={value ? styles["inp_dropdown_btn_value"] : styles["inp_dropdown_btn_placeholder"]}>{value ? value : placeholder}</p>
                    {icon ? (
                        <div style={{ height: "22px", width: "20px" }}>
                            <DynamicIcon name={icon} />
                        </div>
                    ) : null}
                </button>
                {showSlide.show ? (
                    <SlideScreen show={show} titleText={titleTextSlide} altitudeLevel="calendar">
                        {recDate ? <div className={styles.inp_dropdown_container_calendar_rec_date}>Рекомендуемая дата: {recDate}</div> : null}
                        <div className={styles.inp_dropdown_container_calendar}>
                            <CalendarClear
                                tileDisabled={disableWeeks ? ({ date }) => date.getDay() === 6 || date.getDay() === 0 : undefined}
                                defaultValue={defaultValue}
                                value={maxDetail == "year" || disableValue ? undefined : toDate(prev)}
                                onChange={(value) => editClearData(value as object)}
                                minDetail={minDetail}
                                maxDetail={maxDetail}
                                prev2Label={null}
                                prevLabel={<ArrowLeft />}
                                next2Label={null}
                                nextLabel={<ArrowRight />}
                                navigationLabel={({ label }) => `${label}`.replace("г.", "")}
                                minDate={minDate}
                                maxDate={maxDate}
                            />
                            <div style={{ height: "48px", margin: "8px 0px 20px 0px" }}>
                                <Button onClick={() => saveDate(name, prev)} disabled={!prev} type="save_not_margin">
                                    Выбрать
                                </Button>
                            </div>
                        </div>
                    </SlideScreen>
                ) : null}
            </div>
            {errorMessage && <InputError titleText={errorMessage} />}
        </div>
    );
};

export { InputCalendar };
