import React, { ReactElement } from "react";
import styles from "../../../index.module.scss";
import clsx from "clsx";
import { ITimeOffItemName } from "../../../../../typec/ITimeOff";

const TimeOffItemName = (props: ITimeOffItemName): ReactElement => {
    const { name, forDate, workDate, onClick = () => null, isActual = true, isActive = false } = props;
    const click = (name) => {
        onClick(name);
    };
    return (
        <>
            <div
                onClick={() => click(name)}
                className={clsx(styles["doc_time_off_item"], !isActual && styles["doc_time_off_item_not_actual"], isActive && styles["doc_time_off_item_active"])}
            >
                <p className={styles.doc_time_off_item_title}>{name}</p>
                {forDate && workDate && (
                    <div className={styles.doc_time_off_item_date}>
                        <p className={styles.doc_time_off_item_date_text}>{forDate}</p>
                        <p className={styles.doc_time_off_item_date_work_date}>за рабочий день {workDate}</p>
                    </div>
                )}
            </div>
        </>
    );
};

export default TimeOffItemName;
