import React, { createRef, LegacyRef, ReactElement, useEffect, useState } from "react";
import styles from "../index.module.scss";
import clsx from "clsx";
import { InputTitle } from "../InputTitle";
import { InputError } from "../InputError";
import { IMask, IMaskInput, useIMask } from "react-imask";
import { IInputDateProps } from "../../../typec/IInputs";

const templateYear = Number(`${new Date().getFullYear()}`.slice(2));

const InputDate = (props: IInputDateProps): ReactElement => {
    const { onChange = () => null, type, titleText, clearError, name = "valueStartDay", editVacation, errorMessage, value, disable = false, dateNow, style } = props;

    const addZero = (value) => (value < 10 ? `0${value}` : value);
    const [ifValue, setIfValue] = useState(false);

    const [options] = useState({
        mask: Date,
        pattern: "d{.}`m{.}`Y",
        blocks: {
            d: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31,
                maxLength: 2,
                placeholderChar: "Д",
            },
            m: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12,
                maxLength: 2,
                placeholderChar: "М",
            },
            Y: {
                mask: IMask.MaskedEnum,
                enum: [`${templateYear}`, `${templateYear + 1}`],
                maxLength: 2,
                placeholderChar: "Г",
            },
        },
        format: (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getYear();

            return [addZero(day), addZero(month), year].join(".");
        },
        parse: (str) => {
            const yearMonthDay = str.split(".");
            return new Date(yearMonthDay[2], yearMonthDay[1] - 1, yearMonthDay[0]);
        },
        autofix: true,
        lazy: false,
    });

    const { ref } = useIMask<HTMLInputElement & typeof IMaskInput>(options, {
        onAccept: (value) => {
            editVacation && editVacation({ [name]: value });
            clearError && clearError("text");
            if (/\d/.test(value)) setIfValue(false);
            else setIfValue(true);
        },
    });

    useEffect(() => {
        if (dateNow) {
            if (editVacation) {
                editVacation({ [name]: String(`${new Date().toLocaleDateString()}`).replace(".20", ".") });
            }
        }
        // dateNow && editVacation && editVacation({ [name]: String(`${new Date().toLocaleDateString()}`).replace(".20", ".") });
    }, [disable]);

    const refInput = createRef<HTMLInputElement>();

    const scrollDown = () => {
        setTimeout(() => {
            refInput.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 200);
    };

    return (
        <div style={style} onClick={scrollDown} ref={refInput}>
            {titleText && <InputTitle titleText={titleText} />}
            <input
                inputMode="numeric"
                disabled={disable}
                ref={ref as LegacyRef<HTMLInputElement> | undefined}
                defaultValue={value}
                onChange={() => onChange()}
                className={clsx(styles["inp"], styles[`inp_${type}`], errorMessage && styles[`inp_error`], ifValue && styles[`inp_val`])}
            />
            {errorMessage && <InputError titleText={errorMessage} />}
        </div>
    );
};

export { InputDate };
