import React, { createRef, LegacyRef, ReactElement, useState } from "react";
import styles from "../index.module.scss";
import clsx from "clsx";
import { InputTitle } from "../InputTitle";
import { InputError } from "../InputError";
import { IMaskInput, useIMask } from "react-imask";
import { IInputINNProps } from "../../../typec/IInputs";

const InputBill = (props: IInputINNProps): ReactElement => {
    const {
        onChange = () => null,
        type,
        titleText,
        clearError,
        editData,
        name,
        errorMessage,
        value,
        placeholder,
        style,
        onFocus = () => null,
        onBlur = () => null,
        id_inp,
    } = props;

    const [optionsINN] = useState({
        mask: "0000000000",
        placeholderChar: "_",
        lazy: true,
    });
    const [optionsBIC] = useState({
        mask: "000000000",
        placeholderChar: "_",
        lazy: true,
    });
    const [optionsNCH] = useState({
        mask: "0000 0000 0000 0000 0000",
        placeholderChar: "_",
        lazy: true,
    });
    const [optionsNUM] = useState({
        mask: "0000 0000 0000 0000 000",
        placeholderChar: "_",
        lazy: true,
    });

    const options = type == "INN" ? optionsINN : type == "BIC" ? optionsBIC : type == "cardNum" ? optionsNUM : optionsNCH;

    const { ref } = useIMask<HTMLInputElement & typeof IMaskInput>(options, {
        onAccept: (value) => {
            clearError && clearError();
            editData && editData(name, value);
            const element = document.querySelector(`#inp_${name}`);
            const rect = element?.getBoundingClientRect();

            if (rect) {
                window.scrollTo(rect.y, rect?.bottom);
            }
        },
    });

    const keyDownBlur = (e) => {
        if (e.key === "Enter" || e.key === "enter") e.target.blur();
    };

    const refInput = createRef<HTMLInputElement>();

    // const scrollDown = () => {
    //     setTimeout(() => {
    //         refInput.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    //     }, 200);
    // };

    return (
        <div
            style={style}
            // onClick={scrollDown}
            ref={refInput}
        >
            {titleText && <InputTitle titleText={titleText} />}
            <input
                id={id_inp}
                onKeyDown={(e) => keyDownBlur(e)}
                onFocus={() => onFocus()}
                onBlur={() => onBlur()}
                inputMode="numeric"
                placeholder={placeholder}
                ref={ref as LegacyRef<HTMLInputElement> | undefined}
                defaultValue={value}
                onChange={(e) => onChange(e)}
                className={clsx(styles["inp"], styles[`inp_${type}`], errorMessage && styles[`inp_error`])}
            />
            {errorMessage && <InputError titleText={errorMessage} />}
        </div>
    );
};

export { InputBill };
