import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Navigation from "../../components/Navigation";
import { NavigationContext } from "../../template";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import MenuFiltr from "./MenuFiltr/index";
import NewRequest from "./NewRequest/index";
import List from "./List";
import ListNull from "./ListNull";
import menu from "../../components/Menu/Menu";

const RequestsList = (props): ReactElement => {
    const context = useContext(NavigationContext);
    const [load, setLoad] = useState(false);
    const [reqNull, setReqNull] = useState(true);
    const [jsonData, setJsonData] = useState<Array<any>>([]);
    const [currentFilter, setCurrentFilter] = useState<Array<string>>([]);
    const [rawData, setRawData] = useState<Array<any>>([]);
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
        context.prev();
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };
    useEffect(() => {
        if (!load) {
            setLoad(true);
        }
    }, []);

    const filterAdd = (filterName: string) => {
        setCurrentFilter([...currentFilter, filterName]);
    };
    const filterDelete = (filterName) => {
        const newArr = currentFilter.filter((str) => str !== filterName);
        setCurrentFilter(newArr);
    };
    const filterAction = (arr: Array<string>) => {
        setCurrentFilter(arr);
    };

    useEffect(() => {
        if (currentFilter.length) {
            const newList: Array<any> = [];
            if (currentFilter.includes("В работе")) {
                newList.push(...rawData.filter((el) => el.ApprDecl == 0));
            }
            if (currentFilter.includes("Завершено")) {
                newList.push(...rawData.filter((el) => el.ApprDecl == 1));
            }
            if (currentFilter.includes("Отказано или отменено")) {
                newList.push(...rawData.filter((el) => el.ApprDecl == -1));
            }
            setJsonData(newList);
            console.log(currentFilter);
            console.log(currentFilter);
        }
    }, [currentFilter]);

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_list_new", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res == true) {
                        console.log("List of applications was received...");
                        if (json.list.length > 0) {
                            for (const app of json.list) {
                                console.log({
                                    IDApp: app.IDApp,
                                    Date: app.Date,
                                    Number: app.Number,
                                    Type: app.Type,
                                    StartDate: app.StartDate,
                                    KonetsDate: app.KonetsDate,
                                    NumberDays: app.NumberDays,
                                    Commentary: app.Commentary,
                                    Status: app.Status,
                                    Completed: app.Completed,
                                    YearPlanning: app.YearPlanning,
                                    Schedule: app.Schedule,
                                    NewSchedule: app.NewSchedule,
                                    SposobPolucheniya: app.SposobPolucheniya,
                                    StatusCommentary: app.StatusCommentary,
                                    NumberFiles: app.NumberFiles,
                                    ApprDecl: app.ApprDecl,
                                    SposobPolucheniyaSpravki: app?.SposobPolucheniyaSpravki,
                                    VidSpravki: app?.VidSpravki,
                                    PeriodSpravki: app?.PeriodSpravki,
                                    OpisanieMestaVydachiSpravki: app?.OpisanieMestaVydachiSpravki,
                                    KolichestvoEkzemplyarov: app?.KolichestvoEkzemplyarov,
                                    VidPersonalnyhDannyh: app.VidPersonalnyhDannyh, // Вид персональных данных для изменения
                                    PrichinaIzmeneniyaPersonalnyhDannyh: app.PrichinaIzmeneniyaPersonalnyhDannyh, // Причина изменения персональных данных
                                    Dayoffs: app.Dayoffs,
                                });
                                setLoad(false);
                                setReqNull(false);
                                setJsonData(json.list);
                                setRawData(json.list);
                            }
                        } else {
                            setLoad(false);
                            setReqNull(true);
                        }
                    } else {
                        console.log("List of applications was not received...");
                        setLoad(false);
                    }
                })
                .catch((e) => {
                    console.error(e);
                    console.log("List of applications was not received...");
                    setLoad(false);
                });
        }
    }, [load]);
    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <MenuFiltr menu={menu} filterAction={filterAction} data={jsonData}></MenuFiltr>
                    {reqNull ? (
                        <ListNull></ListNull>
                    ) : (
                        <div className={styles.list}>
                            <List isApprovePage={false} data={jsonData}></List>
                        </div>
                    )}
                    <NewRequest></NewRequest>
                </>
            )}
        </>
    );
};

export default RequestsList;
