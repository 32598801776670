import React from "react";
import styles from "./index.module.scss";
import Logotip from "../../icons/LogoSOKOLOV.svg";

const Logo = () => {
    return (
        <div className={styles.logoText}>
            <Logotip />
        </div>
    );
};

export default Logo;
