import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "../index.module.scss";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../components/Navigation";
import AssignedInventItem from "../componets/AssignedInventItem";
import Loader from "../../../components/Loader";
import { IAsgInventory } from "../../../typec/IDocumentRequest";

const AssignedInventory = (): ReactElement => {
    const [load, setLoad] = useState(true);
    const [invData, setData] = useState<IAsgInventory>();
    const [numbersInv, setNumbers] = useState();
    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_requests_new", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        setLoad(false);
                        setData(json);
                        setNumbers(json.TMCs.length);
                    }
                });
        }
    });

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <div className={styles.doc}>
                        <p className={styles.doc_title}>Закрепленные ТМЦ</p>
                        <p className={styles.doc_asg_invent_numbers}>
                            Всего: <a>{numbersInv}</a>
                        </p>
                        <p className={styles.doc_title_sub}>ТМЦ - товарно-материальные ценности</p>
                        {invData?.TMCs?.map((el, index) => (
                            <AssignedInventItem
                                key={`AssignedInventItem_${index}`}
                                kolichestvo={el.Kolichestvo}
                                nameTMTS={el.NameTMTS}
                                yedinitsaIzmereniya={el.YedinitsaIzmereniya}
                            />
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export default AssignedInventory;
