import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import { IVacationCheckItemDetails } from "../../../../../../typec/IVacations";

const VacationCheckItemDetails = (props: IVacationCheckItemDetails): ReactElement => {
    const { title, info, typeVacation } = props;
    return (
        <div className={styles.item_container}>
            <p className={styles.item_container_title}>{title}</p>
            {typeVacation == "Заказ справки" || typeVacation == "Изменение персональных данных" || typeVacation == "Вычет на детей" ? (
                ""
            ) : (
                <p className={styles.item_container_info}>{typeVacation}</p>
            )}
            {typeof info == "object" ? (
                <>
                    {info?.map((el, index) => (
                        <p key={`check_item_${index}_${el}`} className={styles.item_container_info} dangerouslySetInnerHTML={{ __html: el }} />
                    ))}
                </>
            ) : null}
        </div>
    );
};

export default VacationCheckItemDetails;
