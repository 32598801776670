import React, { ReactElement } from "react";
import VacationItem from "../VacationItem";
import styles from "./index.module.scss";
import { IVacationList } from "../../../../typec/IVacations";

const VacationList = (props: IVacationList): ReactElement => {
    const {
        userData,
        editPutInfo,
        loadHero,
        reRender,
        putVacationExternal,
        switchItem = () => null,
        ntfChange = false,
        typeVacation,
        postVacationExternal,
        divadeBool,
        switchDivadeBool,
        typeText,
    } = props;

    const isNotNullBoolean = (el) => {
        let bool = false;
        const arr = el;
        if (`${arr}` != "") {
            bool = true;
        }
        return bool;
    };

    return (
        <>
            {isNotNullBoolean(userData?.user_transfer)
                ? userData?.user_transfer?.map((element, index) => (
                      <VacationItem
                          typeVacation={typeVacation}
                          switchItem={switchItem}
                          loadHero={loadHero}
                          idVacation={userData.user_transfer_id}
                          editPutInfo={editPutInfo}
                          key={`vacationTransfer_num${index}`}
                          typeItem={element.Changed ? "consider" : "graph"}
                          valueStartDay={element.StartDateTransfer.replace(".20", ".")}
                          valueFinishDay={element.KonetsDateTransfer.replace(".20", ".")}
                          valueAmount={String(element.NumberDaysTransfer)}
                          putVacationExternal={putVacationExternal}
                          //   dateNow={false}
                          ntfChange={ntfChange}
                          typeText={element.Changed ? "На согласовании" : element.TypeVacationName}
                      />
                  ))
                : isNotNullBoolean(userData?.user_schedule)
                ? userData?.user_schedule?.map((element, index) => (
                      <VacationItem
                          typeVacation={typeVacation}
                          switchItem={switchItem}
                          loadHero={loadHero}
                          editPutInfo={editPutInfo}
                          key={`vacationSchedule_num${index}`}
                          typeItem={element.TransferForbidden ? "signed" : "graph"}
                          valueStartDay={element.StartDate.replace(".20", ".")}
                          valueFinishDay={element.KonetsDate.replace(".20", ".")}
                          valueAmount={String(element.NumberDays)}
                          //   dateNow={false}
                          ntfChange={ntfChange}
                          divadeBool={divadeBool}
                          switchDivadeBool={switchDivadeBool}
                          typeText={element.TypeVacationName}
                      />
                  ))
                : null}
            {isNotNullBoolean(userData?.user_vacations)
                ? userData?.user_vacations
                      ?.filter((element) => !element.Status.includes("Отмен") && !element.Status.includes("Отказ"))
                      .map((element, index) => (
                          <>
                              {isNotNullBoolean(userData?.user_transfer) || isNotNullBoolean(userData?.user_schedule) ? <div className={styles.line} /> : null}
                              <VacationItem
                                  typeVacation={typeVacation}
                                  switchItem={switchItem}
                                  reRender={reRender}
                                  loadHero={loadHero}
                                  editPutInfo={editPutInfo}
                                  key={`vacations_num${index}`}
                                  typeItem={element.Completed ? "signed" : "consider"}
                                  //   typeItem="signed"
                                  valueStartDay={element.StartDate.replace(".20", ".")}
                                  valueFinishDay={element.KonetsDate.replace(".20", ".")}
                                  valueAmount={String(element.NumberDays)}
                                  idVacation={element.IDApp}
                                  ntfChange={ntfChange}
                                  postVacationExternal={postVacationExternal}
                                  putVacationExternal={putVacationExternal}
                                  //   dateNow={true}
                                  typeText={element.Completed ? "Оформлен" : "На рассмотрении"}
                              />
                          </>
                      ))
                : null}
        </>
    );
};

export default VacationList;
