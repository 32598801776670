import React, { useState, ReactElement, useContext } from "react";
import styles from "./index.module.scss";
import Button from "../../../components/Button";
import SlideScreen from "../../../components/SlideScreen";
import { NavigationContext } from "../../../template";
import { InputPhone, InputRadio, InputText } from "../../../components/Inputs";

const WhoWillGo = (props) => {
    const [openElement, setOpenElement] = useState(0);
    const click = (e) => {
        setOpenElement(Number(e.target.dataset.id));
    };
    const context = useContext(NavigationContext);
    const [isContentVisible1, setContentVisible1] = useState(false);
    const [isContentVisible2, setContentVisible2] = useState(context.guest);
    const [isContentVisible3, setContentVisible3] = useState(context.guest);
    const handleTextClick1 = () => {
        setContentVisible1(true);
        setContentVisible2(false);
        infofr.phone_number = "";
        infofr.full_name = "";
        setClear(true);
        setContentVisible3(true);
    };
    const handleTextClick2 = () => {
        setContentVisible2(true);
        setContentVisible1(false);
        setContentVisible3(true);
    };
    const { actionElement = () => null, setInfofr, infofr, stores, setClear } = props;
    const [data, setData] = useState({
        stores: stores,
    });
    const editData = (name: string, element: string) => {
        setInfofr({
            ...infofr,
            [name]: element,
        });
    };
    const [err, setError] = useState("");
    const createError = (element) => {
        setError(element);
    };
    const clearError = () => {
        setError("");
    };
    console.log(context);
    return (
        <>
            <div className={styles.magazin}>
                <div className={styles.magazin_date}>{data.stores.local_time.replace(":00.000", "")}</div>
                <div className={styles.magazin_name}>{data.stores.store_name}</div>
                <div className={styles.magazin_adress}>{data.stores.address}</div>
                <div className={styles.magazin_rose}>Необходимое количество роз: {data.stores.employees_number}</div>
            </div>
            {!context.guest ? <div className={styles.zagolovok}>Ты сам вручишь розы или в магазин поедет другой человек?</div> : null}
            <div>
                {!context.guest ? (
                    <div className={styles.choose}>
                        <div className={styles.choose_question} onClick={handleTextClick1}>
                            <InputRadio secondTitle="Вручу сам(а)" id_key="sam" name="why" />
                        </div>
                        <div className={styles.choose_question} onClick={handleTextClick2}>
                            <InputRadio secondTitle="Другой человек" id_key="nesam" name="why" />
                        </div>
                    </div>
                ) : null}
                {isContentVisible1 && (
                    <div className={styles.choose_answer} data-id={openElement == 1 ? 0 : 1} onClick={click}>
                        Благодарим тебя за участие. Приглашай своих друзей, каждый участник получит по 2222 бонусных рубля.
                    </div>
                )}
                {isContentVisible2 && (
                    <div className={styles.choose_answer} data-id={openElement == 2 ? 0 : 2}>
                        <div className={styles.choose_answer_content} id="content2">
                            <div className={styles.choose_zagolovok}>Имя и Фамилия</div>
                            <InputText type={"smallFixRosa"} onChange={(value) => setInfofr({ ...infofr, full_name: value })} placeholder="Имя Фамилия" />
                            <div className={styles.choose_zagolovok}>Телефон</div>
                            {/* <InputText type={"smallFixRosa"} onChange={(value) => setInfofr({ ...infofr, phone_number: value })} placeholder="+7 999 999 99 99" /> */}
                            <InputPhone
                                // onChange={() => setInfofr({ ...infofr.phone_number })}
                                editData={editData}
                                name="phone_number"
                                type="phone"
                                // titleText="Телефон"
                                placeholder="+7 999 999 99 99"
                                value={infofr.phone_number}
                                errorMessage={err}
                                clearError={clearError}
                            />
                        </div>
                    </div>
                )}
                <div className={styles.footer}>
                    {isContentVisible3 && (
                        <SlideScreen altitudeLevel="service">
                            <Button
                                onClick={actionElement}
                                disabled={(infofr.full_name == "" || infofr.phone_number.length < 12) && isContentVisible2 ? true : false}
                                type="save_not_margin"
                            >
                                Далее
                            </Button>
                        </SlideScreen>
                    )}
                </div>
            </div>
        </>
    );
};

export default WhoWillGo;
