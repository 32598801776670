import React, { ReactElement, useContext, useEffect, useState } from "react";
import Button from "../../../../components/Button";
import VacationInputItem from "./components/VacationInputItem";
import styles from "./index.module.scss";
import { InputCheckBox } from "../../../../components/Inputs";
import { IOverlayseVacation, IVacationEditProps } from "../../../../typec/IVacations";
import word from "../../../../components/word";
import { VacOfSubItemName } from "../../../DocumentRequest/componets/VacOfSubItem";
import Notification from "../../../../components/Notification";
import { NavigationContext } from "../../../../template";

const VacationEdit = (props: IVacationEditProps): ReactElement => {
    const {
        valueCause = "",
        valueStartDay = "",
        valueAmount,
        editVacation,
        postVacation,
        error,
        clearError,
        load,
        textButton,
        causeBool = false,
        editForIT = false,
        quantityDay,
        dateNow,
        typeVacation,
        editDevideVacation = () => null,
        defValueAmount,
        switchDivadeBool = () => null,
        divadeBool,
        idVacationOnTransition,
        addVacationExternal,
        defValueStartDay,
        disableDivade = false,
        valueStartDayDivade,
        userVacation,
        divadeVacation,
        oldValueFinishDay,
        oldValueStartDay,
        oldValueAmount,
        typeText = "",
    } = props;

    const context = useContext(NavigationContext);

    const [disableDate, setDisableDate] = useState(causeBool);

    const templateYear = Number(`${new Date().getFullYear()}`);
    const templateMonth = Number(`${new Date().getMonth()}`);
    const templateDay = Number(`${new Date().getDate()}`);

    const [jsonOverlays, setJsonOverlayse] = useState<IOverlayseVacation>();

    const click = () => {
        // debugger;
        if (postVacation) {
            if (idVacationOnTransition && defValueStartDay) {
                if (addVacationExternal) {
                    addVacationExternal(idVacationOnTransition, defValueStartDay, valueStartDay, Number(valueAmount), valueCause, typeText, defValueAmount, oldValueFinishDay);
                    postVacation(idVacationOnTransition, defValueStartDay, valueStartDay, valueStartDay, Number(valueAmount));
                }
            } else postVacation();
        }
    };
    const disDate = () => {
        setDisableDate(!disableDate);
    };

    useEffect(() => {
        if (context.userdata.overlays_show) {
            fetch("/dev/vacation_list_new", {
                method: "PUT",
                body: JSON.stringify({
                    Periods:
                        valueStartDayDivade && valueStartDayDivade != valueStartDay && divadeBool
                            ? [
                                  { StartDate: valueStartDay.replace(".2", ".202"), NumberDays: 7 },
                                  { StartDate: valueStartDayDivade.replace(".2", ".202"), NumberDays: 7 },
                              ]
                            : [{ StartDate: valueStartDay.replace(".2", ".202"), NumberDays: valueAmount }],
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        console.log(json);
                        setJsonOverlayse(json);
                    }
                });
        }
    }, [valueStartDay, valueAmount, valueStartDayDivade]);

    return (
        <>
            {typeVacation == "paid" || typeVacation == "transition" ? (
                <>
                    {!disableDivade ? (
                        <>
                            <p className={styles.edit_count_title}>Количество дней для изменения:</p>
                            <p className={styles.edit_count_day}>
                                {defValueAmount} {word(Number(defValueAmount), ["день", "дня", "дней"])}
                            </p>
                            <InputCheckBox
                                titleText="Разделить отпуск"
                                checked={divadeBool}
                                onClick={() => {
                                    switchDivadeBool();
                                }}
                            />
                        </>
                    ) : null}
                    <div className={styles.edit_container}>
                        <VacationInputItem
                            editVacation={editVacation}
                            error={error}
                            clearError={clearError}
                            typeEditInput="text"
                            titleText="Причина переноса отпуска"
                            defaultValueInput={userVacation?.valueCause}
                        />
                        {divadeBool ? (
                            <>
                                <p className={styles.edit_title}>Первый отпуск</p>
                                <div className={styles.edit_vac}>
                                    <VacationInputItem
                                        minDate={new Date(templateYear, templateMonth, templateDay + 14)}
                                        maxDate={new Date(templateYear + 1, 11, 31)}
                                        editVacation={editVacation}
                                        error={error}
                                        clearError={clearError}
                                        typeEditInput="date"
                                        titleText="Дата начала"
                                        defaultValueInput={userVacation?.valueStartDay}
                                        disable={!disableDate}
                                        dateNow={false}
                                        style={{ marginTop: "16px", marginBottom: "-24px" }}
                                    />
                                    <VacationInputItem
                                        editVacation={editVacation}
                                        error={error}
                                        clearError={clearError}
                                        typeEditInput="num"
                                        titleText="Кол-во дней"
                                        defaultValueInput={
                                            Number(userVacation?.valueAmount) % 2 == 0 ? Number(userVacation?.valueAmount) / 2 : Math.floor(Number(userVacation?.valueAmount) / 2)
                                        }
                                        style={{ marginTop: "16px" }}
                                    />
                                </div>

                                <p className={styles.edit_title}>Второй отпуск</p>
                                <div className={styles.edit_vac}>
                                    <VacationInputItem
                                        minDate={new Date(templateYear, templateMonth, templateDay + 14)}
                                        maxDate={new Date(templateYear + 1, 11, 31)}
                                        editVacation={editDevideVacation}
                                        error={error}
                                        clearError={clearError}
                                        typeEditInput="date"
                                        titleText="Дата начала"
                                        defaultValueInput={divadeVacation?.valueStartDay}
                                        disable={!disableDate}
                                        dateNow={false}
                                        style={{ marginTop: "16px", marginBottom: "-24px" }}
                                    />
                                    <VacationInputItem
                                        editVacation={editDevideVacation}
                                        error={error}
                                        clearError={clearError}
                                        typeEditInput="num"
                                        titleText="Кол-во дней"
                                        defaultValueInput={
                                            Number(defValueAmount) - Number(userVacation?.valueAmount) >= 0 ? Number(defValueAmount) - Number(userVacation?.valueAmount) : 0
                                        }
                                        style={{ marginTop: "16px" }}
                                        disable
                                    />
                                </div>
                            </>
                        ) : (
                            <div className={styles.edit_vac}>
                                <VacationInputItem
                                    minDate={new Date(templateYear, templateMonth, templateDay + 14)}
                                    maxDate={new Date(templateYear + 1, 11, 31)}
                                    editVacation={editVacation}
                                    error={error}
                                    clearError={clearError}
                                    typeEditInput="date"
                                    titleText="Дата начала"
                                    defaultValueInput={valueStartDay}
                                    disable={!disableDate}
                                    dateNow={dateNow}
                                    style={{ marginBottom: "-24px" }}
                                />
                                <VacationInputItem
                                    editVacation={editVacation}
                                    error={error}
                                    clearError={clearError}
                                    typeEditInput="num"
                                    titleText="Кол-во дней"
                                    disable
                                    defaultValueInput={defValueAmount ? defValueAmount : valueAmount ? valueAmount : 0}
                                />
                            </div>
                        )}
                        {typeVacation == "transition" ? (
                            <p className={styles.quantity_day}>
                                При редактировании одного оплачиваемого отпуска вы не сможете редактировать другие оплачиваемые отпуска до согласования с вашим руководителем. Для
                                редактирования нескольких отпусков перейдите в раздел "Перенос отпуска".
                            </p>
                        ) : null}
                        {context.userdata.overlays_show ? (
                            <>
                                {jsonOverlays?.Overlays && jsonOverlays?.Overlays.length > 0 ? (
                                    <>
                                        <Notification type="info" icon="info">
                                            Посмотрите, с кем из коллег пересекается ваш отпуск, при необходимости отредактируйте даты.
                                        </Notification>
                                        <div className={styles.edit_container_list}>
                                            {jsonOverlays?.Overlays?.map((el) => (
                                                <VacOfSubItemName name={el.Name} startDays={el.StartDate} konetsDays={el.KonetsDate} numberDays={`${el.NumberDays}`} />
                                            ))}
                                        </div>
                                    </>
                                ) : (
                                    <Notification type="info" icon="info">
                                        Ваш отпуск не пересекается с коллегами из отдела!
                                    </Notification>
                                )}
                            </>
                        ) : null}

                        <Button type="save" load={load} onClick={click}>
                            {textButton && textButton}
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.edit_container}>
                        {editForIT ? (
                            <div className={styles.null_count_text}>
                                На сегодня доступно дней: <a>{quantityDay}</a>
                            </div>
                        ) : null}
                        {editForIT ? null : (
                            <VacationInputItem
                                editVacation={editVacation}
                                error={error}
                                clearError={clearError}
                                typeEditInput="text"
                                titleText="Причина отпуска"
                                defaultValueInput={valueCause}
                            />
                        )}

                        <div className={styles.edit_vac}>
                            <VacationInputItem
                                minDate={editForIT ? undefined : new Date(templateYear, templateMonth, templateDay)}
                                maxDate={editForIT ? undefined : new Date(templateYear + 1, 11, 31)}
                                editVacation={editVacation}
                                error={error}
                                clearError={clearError}
                                typeEditInput="date"
                                titleText="Дата начала"
                                defaultValueInput={valueStartDay}
                                disable={disableDate}
                                dateNow={editForIT}
                            />

                            <VacationInputItem
                                editVacation={editVacation}
                                error={error}
                                clearError={clearError}
                                typeEditInput="num"
                                titleText="Кол-во дней"
                                defaultValueInput={valueAmount ? valueAmount : 0}
                            />
                        </div>
                        {editForIT ? (
                            <>
                                <InputCheckBox
                                    style={{ marginTop: "-24px" }}
                                    titleText={`Сегодня, ${new Date().toLocaleDateString()}`}
                                    checked={disableDate}
                                    onClick={() => disDate()}
                                />
                            </>
                        ) : null}
                        {editForIT ? (
                            <p className={styles.quantity_day}>
                                На сегодня доступно дней: <a className={styles.quantity_day_num}>{quantityDay}</a>
                            </p>
                        ) : null}
                        <Button type="save" load={load} onClick={click}>
                            {textButton && textButton}
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

export default VacationEdit;
