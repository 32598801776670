import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "../index.module.scss";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../components/Navigation";
import Loader from "../../../components/Loader";
import { LabelCalendar } from "../componets/LabelCalendar";
import { IListVacBalancItem, IListVacBalancItemNew, IVacationBalaces } from "../../../typec/IDocumentRequest";
import VacationBalanceItemNew from "../componets/VacBalanceItemNew";

const VacationBalances = (): ReactElement => {
    const [load, setLoad] = useState(true);
    const [vacData, setData] = useState<IVacationBalaces>();

    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_requests_new", {
                method: "PUT",
                body: JSON.stringify({
                    Date: date.date,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    setLoad(false);
                    setData(json);
                });
        }
    });

    const balancBuilderList = (object) => {
        const list: IListVacBalancItem = [];
        Object.entries(object).forEach(([key, value]) => {
            list.push({ name: key, value: value as number });
        });
        return list;
    };

    const itemsBuilderList = (object) => {
        const list: IListVacBalancItemNew = [];
        Object.entries(object).forEach(([key, value]) => {
            list.push({ years: key, value: value as undefined });
        });
        return list;
    };

    const dateNow = (): string => {
        const addZero = (value) => (value < 10 ? `0${value}` : value);
        const year = new Date().getFullYear();
        const month = new Date().getMonth();
        const day = new Date().getDate();
        return `${addZero(day)}.${addZero(month + 1)}.${year}`;
    };

    const [date, setDate] = useState({
        date: dateNow(),
    });
    const editData = (key, element) => {
        setDate((prevState) => ({
            ...prevState,
            [key]: element.replace(".2", ".202"),
        }));
        setLoad(true);
    };

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <div className={styles.doc}>
                        <p className={styles.doc_title}>
                            Остатки отпусков <br /> на{" "}
                            <LabelCalendar editData={editData} name="date" titleTextSlide="Выберите дату" value={date.date.replace(".20", ".")}></LabelCalendar>
                        </p>
                        {balancBuilderList(vacData?.Ostatki)?.map((element, index) => (
                            <p key={`p_leftovers_${index}_${element.name}`} className={styles.doc_title_leftovers}>
                                {element.name}: <a>{element.value}</a>
                            </p>
                        ))}
                        {itemsBuilderList(vacData?.OstatkiPoGodam)?.map((element, index) => (
                            <VacationBalanceItemNew key={`${element.years}_${index}`} years={element.years} value={element.value} />
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export default VacationBalances;
