import React, { ReactElement, useContext, useState } from "react";
import styles from "./index.module.scss";
import Navigation from "../../../components/Navigation";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import { InputTextArea } from "../../../components/Inputs";
import SlideScreen from "../../../components/SlideScreen";
import SendingData from "../../PODetails/components/SendingData";
import { v4 as uuidv4 } from "uuid";
import NewPhotoButtonLimit from "./components/NewPhotoButtonLimit";

const NewAppeals = (): ReactElement => {
    const context = useContext(NavigationContext);
    const [load, setLoad] = useState(false);
    const [putScreen, setScreen] = useState(false);
    const [data, setData] = useState({
        text: "",
        photos: [
            {
                id: uuidv4(),
                data: "data:image/jpeg;base64",
            },
        ],
    });

    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };

    const editData = (key, element) => {
        setData({
            ...data,
            [key]: element,
        });
    };

    const addPhoto = (element) => {
        setData((prevState) => ({
            ...prevState,
            photos: element,
        }));
    };

    const groupPhoto = () => {
        const group = data.photos
            .filter((value) => value.data != "data:image/jpeg;base64")
            .map((element, index) => {
                return {
                    Name: `screen_${index}.${element.data.split("/")[1].split(";")[0]}`,
                    Content: element.data,
                };
            });
        return group;
    };

    const postFeedback = () => {
        const screens = groupPhoto();
        setLoad(true);
        fetch("/dev/vacation_feedback_new", {
            method: "POST",
            body: JSON.stringify({
                Message: data.text,
                Screens: screens,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    setScreen(true);
                    setLoad(false);
                } else {
                    setLoad(false);
                }
            });
    };

    return (
        <>
            {!putScreen ? (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <div className={styles.appl}>
                        <p className={styles.appl_title}>Новое обращение</p>
                        <InputTextArea
                            type="area_large"
                            value={data.text}
                            editData={editData}
                            name="text"
                            placeholder="Введите текст обращения"
                            counterView={true}
                            counterValue="400"
                            style={{ marginBottom: "24px" }}
                        />
                        <p className={styles.appl_second_title}>Добавьте изображение</p>
                        <div className={styles.appl_photos}>
                            <NewPhotoButtonLimit onChange={(value) => addPhoto(value)} limit={20} />
                        </div>
                        <div style={{ marginTop: "100px" }} />
                        <SlideScreen altitudeLevel="service">
                            <Button load={load} onClick={postFeedback} type="save_not_margin" disabled={!data.text}>
                                Отправить обращение
                            </Button>
                        </SlideScreen>
                    </div>
                </>
            ) : (
                <SendingData
                    goToMain={goToMain}
                    closeScreenPut={() => navigate("/myAppeals")}
                    seconstBtn="Мои обращения"
                    title="Обращение успешно отправлено в поддержку"
                    secTitle="В ближайшее время с вами свяжется специалист и поможет решить возникшие проблемы."
                />
            )}
        </>
    );
};

export default NewAppeals;
