import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Navigation from "../../components/Navigation";
import { NavigationContext } from "../../template";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import QuestionsList from "./components/QuestionsList";
import WorkBookAddress from "./components/WorkBookAddress";
import DismissalCheck from "./components/DismissalCheck";
import HomeScreenDismissal from "./components/HomeScreenDismissal";
import { IDismissalStaff } from "../../typec/IDismissalStaff";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";

const DismissalStaff = (): ReactElement => {
    const [load, setLoad] = useState(true);
    const [loadScreen, setLoadScreen] = useState(false);
    const [screens, setScreen] = useState(10);
    const [data, setData] = useState({
        homeScreen: {
            cause: "",
            details: "",
            dateDismissal: "",
            scan: [
                {
                    id: uuidv4(),
                    data: "",
                },
            ],
        },
        questionsList: undefined,
        address: "",
    });
    const [jsonData, setJson] = useState<IDismissalStaff>();
    const [dateApplication, setDate] = useState();
    const [error, setError] = useState({
        ErrorCode: "",
        Error: "",
    });

    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        if (screens == 0) {
            navigate("/");
        } else {
            if (screens === 2 && !jsonData?.paper_tk) setScreen(0);
            else setScreen(screens - 1);
        }
    };
    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };

    const createError = (errorNum, errorText) => {
        setError({
            ErrorCode: errorNum,
            Error: errorText,
        });
    };
    const clearError = () => {
        setError({
            ErrorCode: "",
            Error: "",
        });
    };

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_dismissal_new", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        setJson(json);
                        setLoad(false);
                        editData("homeScreen", {
                            dateDismissal: getDate(new Date().getTime(), json.prob_end),
                            details: "",
                            cause: "",
                            scan: [
                                {
                                    id: uuidv4(),
                                    data: "",
                                },
                            ],
                        });
                        setScreen(0);
                    } else {
                        setJson(json);
                    }
                });
        }
    }, [load]);
    const groupPhoto = (el) => {
        const group = el
            .filter((value) => value.data != "data:image/jpeg;base64")
            .map((element, index) => {
                return {
                    Name: `screen_${index}.${element.data.split("/")[1].split(";")[0]}`,
                    Content: element.data,
                };
            });
        // .filter((value) => value.Name != `screen_0.${value.Content.split("/")[1].split(";")[0]}`);
        return group;
    };
    const postDismissal = () => {
        const screens = groupPhoto(data.homeScreen.scan);
        setLoadScreen(true);
        fetch("/dev/vacation_dismissal_new", {
            method: "POST",
            body: JSON.stringify({
                DismDate: data.homeScreen.dateDismissal.replace(".2", ".202"),
                Reason: data.homeScreen.cause,
                Answers: data.questionsList,
                Address: data.address,
                Screens: screens,
                Comment: data.homeScreen.details,
            }),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    setDate(json.Date);
                    next();
                    setLoadScreen(false);
                } else {
                    setLoadScreen(false);
                    createError(json.ErrorCode, json.Error);
                    console.log(json);
                }
            });
    };
    const putDismissal = () => {
        setLoadScreen(true);
        fetch("/dev/vacation_dismissal_new", {
            method: "PUT",
            body: JSON.stringify({}),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    goToMain();
                    setLoadScreen(false);
                } else {
                    createError(json.ErrorCode, json.Error);
                    setLoadScreen(false);
                }
            });
    };

    const getDate = (el: number, prob_end: boolean): string => {
        let date;
        if (prob_end) {
            let i = 1211600000;
            while (new Date(el + i).getDay() == 6 || new Date(el + i).getDay() == 0) {
                i += 11600000;
            }
            date = new Date(el + i).toLocaleDateString("ru-RU");
        } else {
            let i = 259200000;
            while (new Date(el + i).getDay() == 6 || new Date(el + i).getDay() == 0) {
                i += 11600000;
            }
            date = new Date(el + i).toLocaleDateString("ru-RU");
        }
        console.log(date);
        return date.replace(".20", ".");
    };

    const next = () => {
        if (screens === 0 && !jsonData?.paper_tk) {
            setScreen(2);
        } else setScreen(screens + 1);
    };
    const start = () => {
        setScreen(0);
    };

    const editData = (key, element) => {
        setData({
            ...data,
            [key]: element,
        });
    };

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <div className={styles.dis}>
                        <p className={clsx(styles["dis_title"], screens == 3 && styles[`dis_title_check`])}>Заявление на увольнение</p>
                        {screens == 0 && (
                            <HomeScreenDismissal
                                key="HomeScreenDismassalKey"
                                error={error}
                                clearError={clearError}
                                homeScreenData={data.homeScreen}
                                actionElement={next}
                                addData={editData}
                                reasons={jsonData?.reasons}
                                prob_end={jsonData?.prob_end}
                            />
                        )}
                        {screens == 1 && jsonData?.paper_tk && (
                            <WorkBookAddress actionElement={next} addData={editData} address={jsonData?.address} dataAddress={data.address} error={error} clearError={clearError} />
                        )}
                        {screens == 2 && (
                            <QuestionsList
                                error={error}
                                clearError={clearError}
                                questionsData={data.questionsList}
                                addData={editData}
                                questions={jsonData?.questions}
                                postDismissal={postDismissal}
                                load={loadScreen}
                            />
                        )}
                        {screens == 3 && (
                            <DismissalCheck
                                fullName={context.userdata.name}
                                cause={data.homeScreen.cause}
                                details={data.homeScreen.details}
                                dateDismissal={data.homeScreen.dateDismissal}
                                address={data.address}
                                actionElement={start}
                                dateApplication={dateApplication}
                                putDismissal={putDismissal}
                                load={loadScreen}
                            />
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default DismissalStaff;
