import React, { ReactElement } from "react";
import styles from "../../../index.module.scss";
import { IAssInvOfName } from "../../../../../typec/IDocumentRequest";

const AsgInvOfName = (props: IAssInvOfName): ReactElement => {
    const { name, quantity, onClick = () => null } = props;

    const click = (name, quantity) => {
        onClick(name, quantity);
    };
    return (
        <>
            <div onClick={() => click(name, quantity)} className={styles.doc_asg_invent_item}>
                <p className={styles.doc_asg_invent_item_title}>{name}</p>
                <p className={styles.doc_asg_invent_item_quantity}>{quantity} шт.</p>
            </div>
        </>
    );
};

export { AsgInvOfName };
