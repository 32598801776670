import React, { ReactElement } from "react";
import styles from "../../index.module.scss";
import { IVacScheduleItem } from "../../../../typec/IDocumentRequest";

const VacScheduleItem = (props: IVacScheduleItem): ReactElement => {
    const { typeVacationName, startDate, konetsDate, numberDays } = props;
    return (
        <>
            <div className={styles.doc_vac_schedule_item}>
                <div className={styles.doc_vac_schedule_item_date}>
                    <p className={styles.doc_vac_schedule_item_date_text}>
                        {startDate} – {konetsDate}
                    </p>
                    <p className={styles.doc_vac_schedule_item_date_quantity}>{numberDays} дней</p>
                </div>
                <p className={styles.doc_vac_schedule_item_title}>{typeVacationName}</p>
            </div>
        </>
    );
};

export default VacScheduleItem;
