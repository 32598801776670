import React, { ReactElement, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Notification from "../../../../components/Notification";
import Button from "../../../../components/Button";
import VacationEdit from "../VacationEdit";
import SlideScrin from "../../../../components/SlideScreen";
import CancelVacation from "./components/CancelVacation";
import { IVacationItem } from "../../../../typec/IVacations";
import word from "../../../../components/word";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";

const VacationItem = (props: IVacationItem): ReactElement => {
    const {
        typeItem, // тип карточки отпуска
        valueCause,
        valueStartDay, // начало отпуска
        valueFinishDay, // конец отпуска
        valueAmount, // кол-во дней
        editPutInfo, // измение данных отпуска для окна формирования заявления
        addVacationExternal, // внешнее добавление отпуска в массив отпусков
        idVacation, // ИН отпуска для его удаления
        loadHero, // вызов загрузки главного экрана
        postVacationExternal, // внешний POST запрос
        putVacationExternal, // внешний PUT запрос
        loadExternal = false, // внешний пропс загрузки окна
        errorExternal, // внешние ошибки
        clearErrorExternal, // очистка внешних ошибок
        reRender, // пропс на перезагрузку компонента
        switchItem = () => null,
        ntfChange = false,
        typeVacation,
        divadeBool,
        switchDivadeBool,
        idVacationOnTransition = "",
        defValueStartDay,
        disableDivade,
        switchDisableDivade = () => null,
        userVacNewList,
        disableHiddenVacation = false,
        modifiedVacation = false,
        oldValueFinishDay,
        oldValueStartDay,
        oldValueAmount,
        typeText = "",
    } = props;
    const [load, setLoad] = useState(loadExternal); // стейт лоадера с внешним пропсом
    const [hiddenVacation, setHiddenVacation] = useState(false); // стейт для скрытия разделенного отпуска для раздела переноса отпуска, дальнейшие два отпуска редактируются отдельно
    const [error, setError] = useState({
        text: "",
        num: "",
    });
    const [userVacation, setUserVacations] = useState({
        valueCause: valueCause ? valueCause : "По семейным обстоятельствам",
        oldStartDay: valueStartDay,
        valueStartDay: valueStartDay,
        valueFinishDay: valueFinishDay,
        valueAmount: valueAmount,
    });
    const [divadeVacation, setDivadeVacation] = useState({
        valueCause: "По семейным обстоятельствам",
        oldStartDay: valueStartDay,
        valueStartDay: valueStartDay,
        valueFinishDay: valueFinishDay,
        valueAmount: valueAmount,
    });
    const [showChange, setShowChange] = useState({
        show: false, // показ окна редактирования
        showCancel: false, // показ окна отмены отпуска
        modified: false, // изменялся ли отпуск
    });
    const oldPeriod = {
        oldNumberDay: valueAmount,
        oldValueFinishDay: valueFinishDay,
    };

    const createError = (el) => {
        setError({
            ...error,
            ...el,
        });
    };

    const clearError = (key: string) => {
        setError({
            ...error,
            [key]: "",
        });
    };

    const editVacation = (element) => {
        setUserVacations({
            ...userVacation,
            ...element,
        });
    };
    const editDevideVacation = (element) => {
        setDivadeVacation({
            ...divadeVacation,
            ...element,
        });
    };

    const show = (element) => {
        setShowChange({
            ...showChange,
            ...element,
        });
    };

    const clearVacationPOST = () => {
        fetch("/dev/vacation_vac_shed_new", {
            method: "DELETE",
        }).then((res) => res.json());
    };

    const period_1 = {
        StartDateOrigin: userVacation.oldStartDay.replace(".2", ".202"),
        StartDateTransfer: userVacation.valueStartDay.replace(".2", ".202"),
        NumberDays: userVacation.valueAmount, //userVacation.valueAmount,
    };
    const period_2 = {
        StartDateOrigin: divadeVacation.oldStartDay.replace(".2", ".202"),
        StartDateTransfer: divadeVacation.valueStartDay.replace(".2", ".202"),
        NumberDays: Number(valueAmount) - Number(userVacation.valueAmount) >= 0 ? `${Number(valueAmount) - Number(userVacation.valueAmount)}` : "0", //divadeVacation.valueAmount,
    };

    const periods = () => {
        let arr;
        if (divadeBool) {
            if (userVacNewList) {
                arr = [
                    period_1,
                    period_2,
                    userVacNewList?.map((el) => {
                        return {
                            StartDateOrigin: el.StartDateOrigin,
                            StartDateTransfer: el.StartDateTransfer,
                            NumberDays: el.NumberDays,
                        };
                    }),
                ];
            } else arr = [period_1, period_2];
        } else {
            if (userVacNewList) {
                arr = [
                    period_1,
                    userVacNewList?.map((el) => {
                        return {
                            StartDateOrigin: el.StartDateOrigin,
                            StartDateTransfer: el.StartDateTransfer,
                            NumberDays: el.NumberDays,
                        };
                    }),
                ];
            } else arr = [period_1];
        }
        return arr.flat();
    };

    const postVacation = () => {
        clearError("text");
        clearVacationPOST();
        setLoad(true);
        if (typeVacation != "transition") {
            fetch("/dev/vacation_vac_shed_new", {
                method: "POST",
                body: JSON.stringify({
                    Transfer: true,
                    Periods: periods(),
                    Comment: userVacation.valueCause,
                }),
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        show({ show: false, modified: true });
                        document.body.style.overflow = "auto";
                        setLoad(false);
                        setUserVacations({
                            valueCause: userVacation.valueCause,
                            oldStartDay: userVacation.oldStartDay,
                            valueStartDay: json.Schedule[json.Schedule.length - 1].StartDateTransfer.replace(".20", "."),
                            valueFinishDay: json.Schedule[json.Schedule.length - 1].KonetsDateTransfer.replace(".20", "."),
                            valueAmount: userVacation.valueAmount,
                        });
                        if (editPutInfo) {
                            editPutInfo({
                                periodArr: json.Schedule?.map((el) => {
                                    if (el.KonetsDate != el.KonetsDateTransfer || el.StartDate != el.StartDateTransfer) {
                                        return [
                                            `<s>c ${el.StartDate} по ${el.KonetsDate}</s> `,
                                            `</br>c ${el.StartDateTransfer} по ${el.KonetsDateTransfer}`,
                                            ` ${el.NumberDaysTransfer} дней`,
                                        ];
                                    }
                                }),
                                quantityDay: "",
                                headerInfo: { Date: json.Date },
                                typeVacation: json.Schedule[json.Schedule.length - 1].TypeVacationName,
                            });
                        }
                        if (putVacationExternal) {
                            putVacationExternal();
                        }
                        // if (addVacationExternal) {
                        //     addVacationExternal(
                        //         idVacationOnTransition,
                        //         divadeVacation.oldStartDay,
                        //         userVacation.valueFinishDay,
                        //         Number(userVacation.valueAmount),
                        //         userVacation.valueCause
                        //     );
                        //     if (divadeBool) {
                        //         addVacationExternal(
                        //             uuidv4(),
                        //             divadeVacation.oldStartDay.replace(".2", ".202"),
                        //             divadeVacation.valueFinishDay,
                        //             Number(valueAmount) - Number(userVacation.valueAmount),
                        //             userVacation.valueCause
                        //         );
                        //         setHiddenVacation(true);
                        //         switchDisableDivade();
                        //     }
                        // }
                        switchItem();
                    } else {
                        createError({ text: json.Error, num: json.ErrorCode });
                        show({ show: true });
                        setLoad(false);
                    }
                });
        } else {
            if (addVacationExternal) {
                addVacationExternal(
                    idVacationOnTransition,
                    divadeVacation.oldStartDay,
                    userVacation.valueStartDay,
                    Number(userVacation.valueAmount),
                    userVacation.valueCause,
                    typeText,
                    oldValueAmount ? oldValueAmount : oldPeriod.oldNumberDay,
                    oldValueFinishDay ? oldValueFinishDay : oldPeriod.oldValueFinishDay
                );
                if (divadeBool) {
                    addVacationExternal(
                        uuidv4(),
                        divadeVacation.oldStartDay.replace(".2", ".202"),
                        divadeVacation.valueStartDay,
                        Number(valueAmount) - Number(userVacation.valueAmount),
                        userVacation.valueCause,
                        typeText,
                        oldPeriod.oldNumberDay,
                        oldPeriod.oldValueFinishDay
                    );
                    switchDisableDivade();
                }
            }
            if (putVacationExternal) {
                putVacationExternal();
            }
            setTimeout(() => {
                if (!disableHiddenVacation) {
                    setHiddenVacation(true);
                }
                show({ show: false, modified: true });
                document.body.style.overflow = "auto";
                setLoad(false);
            }, 600);
        }
    };

    const cancelVacation = () => {
        fetch("/dev/vacation_cancel_new", {
            method: "POST",
            body: JSON.stringify({
                IDApp: idVacation,
                Comment: "",
            }),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    reRender && reRender();
                }
            });
        loadHero && loadHero();
        show({ showCancel: false });
    };

    const showSlideScreen = () => {
        if (typeItem === "consider") {
            show({ showCancel: true });
        } else show({ show: true });
    };

    useEffect(() => {
        if (showChange.show) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
            if (divadeBool && switchDivadeBool) switchDivadeBool();
        }
        if (showChange.modified === false) {
            setUserVacations({
                valueCause: valueCause ? valueCause : "По семейным обстоятельствам",
                oldStartDay: valueStartDay,
                valueStartDay: valueStartDay,
                valueFinishDay: valueFinishDay,
                valueAmount: valueAmount,
            });
        }
    }, [showChange]);

    useEffect(
        () =>
            setUserVacations({
                valueCause: valueCause ? valueCause : "По семейным обстоятельствам",
                oldStartDay: valueStartDay,
                valueStartDay: valueStartDay,
                valueFinishDay: valueFinishDay,
                valueAmount: valueAmount,
            }),
        [valueStartDay, valueFinishDay]
    );

    useEffect(() => {
        if (!errorExternal?.text) {
            if (typeVacation != "transition") {
                loadExternal ? show({ show: true }) : show({ show: false });
            } else {
                !loadExternal ? show({ show: false }) : null;
            }
        }
        loadExternal ? setLoad(true) : setLoad(false);
    }, [loadExternal]);

    return (
        <>
            {hiddenVacation ? null : (
                <>
                    <div className={clsx(styles["vacation"], modifiedVacation && styles["vacation_modified"])}>
                        {modifiedVacation ? (
                            <div className={styles.vacation_day_modified}>
                                <s>
                                    <span className={styles.vacation_day_validity}>
                                        {oldValueStartDay}&nbsp;–&nbsp;{oldValueFinishDay}
                                        {/* {oldValueFinishDay ? (
                                            <span className={styles.vacation_day_validity_true}>{oldValueFinishDay}</span>
                                        ) : (
                                            <span className={styles.vacation_day_validity_false}></span>
                                        )} */}
                                    </span>
                                </s>
                                <s>
                                    <span className={styles.vacation_day_duration}>
                                        {oldValueAmount} {word(Number(oldValueAmount), ["день", "дня", "дней"])}
                                    </span>
                                </s>
                            </div>
                        ) : null}
                        <div className={styles.vacation_day}>
                            <span className={styles.vacation_day_validity}>
                                {userVacation.valueStartDay}&nbsp;–&nbsp;
                                {userVacation.valueFinishDay ? (
                                    <span className={styles.vacation_day_validity_true}>{userVacation.valueFinishDay}</span>
                                ) : (
                                    <span className={styles.vacation_day_validity_false}></span>
                                )}
                            </span>
                            <span className={styles.vacation_day_duration}>
                                {userVacation.valueAmount} {word(Number(userVacation.valueAmount), ["день", "дня", "дней"])}
                            </span>
                        </div>
                        <div className={styles.vacation_buttons}>
                            <Button
                                disabled={typeVacation == "paid" || typeItem == "signed" ? true : false}
                                icon={typeItem === "graph" || typeItem === "change" ? "change" : ""}
                                children={typeVacation == "paid" ? "" : typeItem === "consider" ? "Отменить" : "Изменить"}
                                type={
                                    typeVacation == "paid"
                                        ? "invisible"
                                        : typeItem == "consider"
                                        ? "cancel"
                                        : typeItem === "graph" || typeItem === "change"
                                        ? "change"
                                        : "invisible"
                                }
                                onClick={() => showSlideScreen()}
                            />
                            {!(typeItem === "change") && !(typeItem === undefined) ? (
                                <Notification
                                    // children={typeItem === "consider" ? "На рассмотрении" : typeItem === "graph" || typeItem == "graph_not_change" ? "По графику" : "Подписан"}
                                    type={typeItem === "consider" ? "coordination" : typeItem === "graph" ? "graph" : "signed"}
                                >
                                    {typeText}
                                </Notification>
                            ) : null}
                        </div>
                    </div>
                    {showChange.show ? (
                        <SlideScrin titleText="Изменение отпуска" altitudeLevel="full" show={show}>
                            <VacationEdit
                                load={loadExternal ? loadExternal : load}
                                error={errorExternal ? errorExternal : error}
                                clearError={clearErrorExternal ? clearErrorExternal : clearError}
                                editVacation={postVacationExternal && editPutInfo ? editPutInfo : editVacation}
                                editDevideVacation={editDevideVacation}
                                postVacation={postVacationExternal ? postVacationExternal : postVacation}
                                // postVacation={postVacation}
                                valueCause={userVacation.valueCause}
                                valueStartDay={userVacation.valueStartDay}
                                valueStartDayDivade={divadeVacation.valueStartDay}
                                valueAmount={userVacation.valueAmount}
                                defValueAmount={valueAmount}
                                textButton="Сохранить изменения"
                                // dateNow={dateNow}
                                ntfChange={ntfChange}
                                causeBool={typeVacation == "paid" || typeVacation == "transition" ? true : false}
                                typeVacation={typeVacation == "transition" ? "paid" : typeVacation}
                                switchDivadeBool={switchDivadeBool}
                                divadeBool={divadeBool}
                                editForIT={typeVacation == "IT" ? true : false}
                                idVacationOnTransition={idVacationOnTransition}
                                defValueStartDay={defValueStartDay}
                                addVacationExternal={addVacationExternal}
                                disableDivade={disableDivade}
                                userVacation={userVacation}
                                divadeVacation={divadeVacation}
                            />
                        </SlideScrin>
                    ) : null}
                    {showChange.showCancel ? (
                        <SlideScrin altitudeLevel="middle" show={show} titleText="Отмена переноса">
                            <CancelVacation
                                show={show}
                                cancelVacation={cancelVacation}
                                textSubTitle={
                                    typeVacation == "paid" || typeVacation == "transition"
                                        ? "Вы уверены что хотите отменить перенос отпуска?"
                                        : typeVacation == "IT"
                                        ? "Вы уверены что хотите отменить отпуск ИТ?"
                                        : "Вы уверены что хотите отменить отпуск за свой счет?"
                                }
                            />
                        </SlideScrin>
                    ) : null}
                </>
            )}
        </>
    );
};

export default VacationItem;
