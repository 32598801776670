import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import Button from "../../../../../../components/Button";
import { ICancelVacation } from "../../../../../../typec/IVacations";

const CancelVacation = (props: ICancelVacation): ReactElement => {
    const { show, cancelVacation, textSubTitle = "Вы уверены что хотите отменить перенос отпуска?" } = props;
    return (
        <div className={styles.title}>
            <p className={styles.title_text}>{textSubTitle}</p>
            <Button type="cancel_red" onClick={cancelVacation}>
                Отменить
            </Button>
            <Button onClick={() => show({ showCancel: false })} type="cancel_grey" children={"Закрыть"} />
        </div>
    );
};

export default CancelVacation;
