import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import Info from "./../../../icons/info.svg";

const ListNull = (): ReactElement => {
    return (
        <>
            <div className={styles.flex}>
                <div>
                    <Info></Info>
                </div>
                <div className={styles.text}>
                    У вас пока нет заявлений.<br></br> Все заявления будут отображены в этом разделе после успешного сформирования.
                </div>
            </div>
        </>
    );
};

export default ListNull;
