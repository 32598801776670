import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import { IVacationCheckItem } from "../../../../../../typec/IVacations";

const VacationCheckItem = (props: IVacationCheckItem): ReactElement => {
    const { title, info } = props;
    return (
        <div className={styles.item_container}>
            <p className={styles.item_container_title}>{title}</p>
            {typeof info != "object" ? (
                <p className={styles.item_container_info}>{info}</p>
            ) : (
                <>
                    {info.map((el) => (
                        <p className={styles.item_container_info} dangerouslySetInnerHTML={{ __html: el }}>
                            {/* {el} */}
                        </p>
                    ))}
                </>
            )}
        </div>
    );
};

export default VacationCheckItem;
