import React, { ReactElement, ReactNode, RefObject, useEffect } from "react";
import styles from "./index.module.scss";
import clsx from "clsx";
import { DataAttributes } from "../../typec/common";

interface LoaderProps {
    message?: string;
    bold?: string;
    parentElement?: RefObject<HTMLElement>;
    children?: ReactNode;
    className?: string;
    dataAttributes?: DataAttributes;
    forBtn?: "blue" | "green" | "red";
    type?: "overlay" | "search";
}

const Loader: React.FC<LoaderProps> = (props: LoaderProps): ReactElement => {
    const { message = "", bold = false, className = "", dataAttributes = {}, forBtn = false, type = "overlay" } = props;
    const parent = props.parentElement?.current;

    useEffect(() => {
        if (parent) {
            parent.classList.add(clsx(styles["loader-block"], styles["loader-block__overlay-container"]));
        }
        return () => {
            if (parent) {
                parent.classList.remove(clsx(styles["loader-block"], styles["loader-block__overlay-container"]));
            }
        };
    }, [parent]);

    return (
        <div
            className={clsx(styles[`loader-block__${type}`], bold && styles[`loader-block__overlay--${bold}`], forBtn && styles[`loader-block__overlay_btn_${forBtn}`], className)}
            {...dataAttributes}
        >
            <div className={clsx(styles["loader-block__spinner"], message && styles["loader-block__spinner--text"], forBtn && styles[`loader-block__spinner_btn_${forBtn}`])}>
                {message ? message : null}
            </div>
        </div>
    );
};

export default Loader;
