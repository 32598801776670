import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Closes from "../../../icons/Close.svg";
import Checkbox from "./components/FilterItem/index";
import Button from "../../../components/Button";

export type IFilterCheckboxes = Array<{
    label: string;
    isSelected: boolean;
}>;

const FilterComponent = (props) => {
    const { isElementVisible, close } = props;
    const [checkboxes, setCheckboxes] = useState<IFilterCheckboxes>(props.filterData);
    const handleToggle = () => {
        close();
        console.log(props.filterData);
        props.filterData.filter((el) => el.isSelected).map((el) => el.label);
        props.filterAction(props.filterData.filter((el) => el.isSelected).map((el) => el.label));
    };
    const handleCheckboxChange = (index) => {
        const updatedCheckboxes = checkboxes.map((checkbox, i) => (i === index ? { ...checkbox, isSelected: !checkbox.isSelected } : checkbox));
        setCheckboxes(updatedCheckboxes);
        props.updateFilter(updatedCheckboxes);
    };

    return (
        <>
            {isElementVisible && (
                <div className={styles.fon}>
                    <div className={styles.block}>
                        <div className={styles.menuFlex}>
                            <div className={styles.zagolovok}>Фильтр</div>
                            <button onClick={handleToggle} className={styles.closes}>
                                <Closes />
                            </button>
                        </div>
                        <div className={styles.statut}>Статус заявки</div>
                        <div>
                            {checkboxes.map((checkbox, index) => (
                                <Checkbox key={index} label={checkbox.label} isSelected={checkbox.isSelected} onCheckboxChange={() => handleCheckboxChange(index)} />
                            ))}
                        </div>
                        <div className={styles.buttons}>
                            <Button onClick={handleToggle} type="save_not_margin">
                                Применить
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FilterComponent;
