import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import VacationCheckItem from "../VacationCheckItem";
import { IVacationCheckList } from "../../../../../../typec/IVacations";
import VacationCheckItemDetails from "../VacationCheckItemDetails";

const VacationCheckList = (props: IVacationCheckList): ReactElement => {
    const { fullName, position, subdivision, supervisor, typeVacation, period, quantityDay, disableDetails } = props;
    return (
        <div className={styles.item_container}>
            {fullName ? <VacationCheckItem title="ФИО сотрудника" info={fullName} /> : null}
            {position && !disableDetails ? <VacationCheckItem title="Должность" info={position} /> : null}
            {subdivision && !disableDetails ? <VacationCheckItem title="Подразделение" info={subdivision} /> : null}
            {supervisor ? <VacationCheckItem title="Руководитель" info={supervisor} /> : null}
            {typeVacation && !disableDetails ? <VacationCheckItem title="Тип отпуска" info={typeVacation} /> : null}
            {period && !disableDetails ? <VacationCheckItem title="Даты отпуска" info={period} /> : null}
            {quantityDay && !disableDetails ? <VacationCheckItem title="Количество дней" info={`${quantityDay}`} /> : null}
            {typeVacation && period ? <VacationCheckItemDetails info={period ? period : ""} title="Подробная информация" typeVacation={typeVacation} /> : null}
        </div>
    );
};

export default VacationCheckList;
