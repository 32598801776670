import React, { LegacyRef, createRef, useState } from "react";
import styles from "../index.module.scss";
import clsx from "clsx";
import { InputTitle } from "../InputTitle";
import { InputError } from "../InputError";
import keyHelper from "../../../shared/helpers/keyHelper";
import { IInputTextArea } from "../../../typec/IInputs";

const InputTextArea = (props: IInputTextArea) => {
    const {
        type,
        name,
        value,
        titleText,
        errorMessage,
        placeholder,
        editData,
        onlySymbol = false,
        counterView = false,
        counterValue = 400,
        clearError,
        blurBool = () => null,
        style,
        onFocus = () => null,
        onBlur = () => null,
    } = props;
    const [codes, setCode] = useState<string>("w");
    const ref = createRef<HTMLInputElement>();

    const keyDown = (e) => {
        setCode(keyHelper(e));
    };

    const change = (e) => {
        if (Number(value?.length) < Number(counterValue) || value == undefined) {
            if (onlySymbol) {
                if ((/^\D$/.test(codes) && /^\D*$/.test(e.target.value)) || /Backspace|Arrow(?:Left|Right)/.test(codes)) {
                    editData && editData(name, e.target.value);
                    clearError && clearError();
                } else {
                    e.preventDefault();
                }
            } else {
                editData && editData(name, e.target.value);
                clearError && clearError();
            }
        } else {
            if (/Backspace|backspace|Arrow(?:Left|Right)/.test(codes)) {
                editData && editData(name, e.target.value);
                clearError && clearError();
            }
        }
    };

    const refInput = createRef<HTMLInputElement>();

    // const scrollDown = () => {
    //     setTimeout(() => {
    //         refInput.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    //     }, 200);
    // };

    // const keyDownBlur = (e) => {
    //     if (e.key === "Enter" || e.key === "enter") e.target.blur();
    // };

    return (
        <div
            style={style}
            // onClick={scrollDown}
            ref={refInput}
        >
            {titleText && <InputTitle titleText={titleText} />}
            <textarea
                ref={ref as unknown as LegacyRef<HTMLTextAreaElement> | undefined}
                onChange={change}
                onKeyDown={keyDown}
                onFocus={() => onFocus()}
                onBlur={() => onBlur()}
                placeholder={placeholder}
                value={value}
                className={clsx(styles["inp"], styles[`inp_${type}`], errorMessage && styles[`inp_error`], !ref && styles[`inp_val`])}
            />
            {counterView ? (
                <p className={styles.inp_counter}>
                    {value?.length} / {counterValue}
                </p>
            ) : null}
            {errorMessage && <InputError titleText={errorMessage} />}
        </div>
    );
};

export { InputTextArea };
