import React, { ReactElement } from "react";
import styles from "../../index.module.scss";
import { IItemVacBalancItemNew, IListVacBalancItem } from "../../../../typec/IDocumentRequest";

const VacationBalanceItemNew = (props: IItemVacBalancItemNew): ReactElement => {
    const { years, value } = props;
    const balancBuilderList = (object) => {
        const list: IListVacBalancItem = [];
        Object.entries(object).forEach(([key, value]) => {
            list.push({ name: key, value: value as number });
        });
        return list;
    };
    return (
        <>
            <div className={styles.doc_vac_balce_item}>
                <p className={styles.doc_vac_balce_item_years}>
                    Рабочий год: <a>{years}</a>
                </p>
                {balancBuilderList(value && value)?.map((element, index) => (
                    <p key={`${element.name}_${element.value}_${index}`} className={styles.doc_vac_balce_item_quantity}>
                        {element.name}: {element.value}
                    </p>
                ))}
            </div>
        </>
    );
};

export default VacationBalanceItemNew;
