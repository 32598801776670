import React, { useEffect, useState } from "react";
import styles from "../index.module.scss";
import clsx from "clsx";
import DynamicIcon from "../../DynamicIcon";
import { InputError, InputRadio, InputTitle } from "..";
import SlideScreen from "../../SlideScreen";
import Button from "../../Button";
import { IInputDropDown } from "../../../typec/IInputs";

const InputDropDown = (props: IInputDropDown): React.ReactElement => {
    const { icon, titleText, type = "dropdown", editData, name, value, errorMessage, onClick = () => null, items } = props;
    const [prev, setPrev] = useState(value);
    const [showSlide, setShow] = useState({
        show: false,
        showCancel: false,
        modified: false,
    });

    const show = (element) => {
        setShow({
            ...showSlide,
            ...element,
        });
    };
    const click = () => {
        onClick();
        show({ show: true });
    };
    const editPrev = (element) => {
        setPrev(element);
    };
    const saveData = (key, element) => {
        editData && editData(key, element);
        show({ show: false });
    };
    useEffect(() => {
        if (showSlide.show) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [showSlide, prev]);

    return (
        <>
            <>
                {titleText && <InputTitle titleText={titleText} />}
                <div className={styles.inp_dropdown_container}>
                    <button
                        id="dropdown"
                        onClick={click}
                        className={clsx(styles["inp"], styles[`inp_${type}`], value && styles[`inp_dropdown_val`], errorMessage && styles[`inp_error`])}
                    >
                        <p>{value ? value : "Выберите"}</p>
                        {icon ? (
                            <div className={clsx(styles[`inp_icon`], showSlide.show ? styles[`inp_icon_up`] : styles[`inp_icon_down`])}>
                                <DynamicIcon name={icon} />
                            </div>
                        ) : null}
                    </button>
                    {showSlide.show ? (
                        <SlideScreen show={show} titleText={titleText} altitudeLevel="auto">
                            <div className={styles.inp_dropdown_container_items}>
                                {items?.map((element, index) => (
                                    <InputRadio editData={editPrev} name={name} id_key={`radio_inp_${index}`} firstTitle={element} selected={prev} value={element} />
                                ))}
                            </div>
                            <div style={{ marginTop: "10px" }} />
                            <Button disabled={prev != "Выберите" ? false : true} onClick={() => saveData(name, prev)} type="save_not_margin">
                                Выбрать
                            </Button>
                        </SlideScreen>
                    ) : null}
                </div>
                {errorMessage && <InputError titleText={errorMessage} />}
            </>
        </>
    );
};

export { InputDropDown };
