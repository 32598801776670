import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Button from "../../../components/Button";

const TextInfo = (props) => {
    const { actionElement, switchRole, role } = props;

    return (
        <>
            <div className={styles.info}>
                {role ? (
                    <div>
                        <div className={styles.info_zagolovok}>
                            Каждый из нас – часть общей команды, мы связаны друг с другом! Всё, что мы делаем, приближает нас к одной общей цели – росту прибыли компании! При её
                            достижении важно проявлять заботу друг о друге.
                        </div>
                        <div className={styles.info_zagolovok_notmargin}>Впереди 8 Марта – один из самых трудных периодов для команд магазинов.</div>
                        <div className={styles.info_zagolovok}>
                            Давайте проявим заботу о наших продавцах и поможем компании достичь амбициозных целей по выручке в эти праздничные дни.
                        </div>
                        <div className={styles.info_zagolovok_notmargin}>
                            5 марта в 12:00 (по Москве) по всей России и странам СНГ нам нужны добровольцы, которые смогут купить розы, прийти в один из 651 магазинов SOKOLOV и
                            вдохновить продавцов!
                        </div>
                        <div className={styles.info_zagolovok}>
                            Внеси свой вклад в проект: приди в магазин сам, привлеки друга или скинь любую сумму на розы. Все участники, пришедшие в магазин, получат 2222 бонусных
                            рубля SOKOLOV и стальную звезду.
                        </div>
                        <div className={styles.info_zagolovok}>Любая помощь приближает нас к общей цели.</div>
                    </div>
                ) : (
                    <div className={styles.info_sponsor}>
                        <div className={styles.info_sponsor_info}>
                            <div className={styles.info_zagolovok}>Пополнить копилку</div>
                            <div className={styles.info_zagolovok}>
                                198 городов. 651 магазин. 2222 розы. Эта цель достижима, потому что ты с нами! Пополни копилку на розы и закинь любую сумму.{" "}
                            </div>
                            <div className={styles.info_zagolovok_notmargin}>Благодарим тебя за вклад и шлём лучики добра!</div>
                        </div>
                        <div className={styles.info_pay}>
                            <a
                                className={styles.info_link}
                                target="_blank"
                                href="https://www.tinkoff.ru/collectmoney/crowd/polovinkina.olga2/ng9zy16067/?short_link=7djqxytORYi&httpMethod=GET"
                            >
                                <Button type="tinkoff">Перевести через Tinkoff</Button>
                            </a>
                            <a className={styles.info_link} target="_blank" href="https://www.sberbank.com/ru/person/dl/jc?linkname=qudWeW42LHsTfdjwg">
                                <Button type="sber">Перевести через СБЕР</Button>
                            </a>
                            <a className={styles.info_link} target="_blank" href="https://vtb.paymo.ru/collect-money/?transaction=d4d071d3-eab3-4b9a-9762-fd6d714c1edb">
                                <Button type="vtb">Перевести через ВТБ</Button>
                            </a>

                            <div className={styles.info_zagolovok}>
                                Или перевод СБП на номер <span>+7 953 652 67 42</span> Получатель: Половинкина Ольга Михайловна с комментарием “Роза”
                            </div>
                        </div>
                    </div>
                )}
                <div className={styles.putScr_btn}>
                    <Button
                        onClick={() => {
                            switchRole(true);
                            actionElement();
                        }}
                        type="save_not_margin"
                    >
                        Стать участником
                    </Button>
                    <Button onClick={switchRole} type="cancel_grey">
                        {role ? "Стать спонсором" : "О проекте"}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default TextInfo;
