import React, { ReactElement, useState } from "react";
import styles from "../../index.module.scss";
import Delete from "../../../../../icons/delete_round.svg";
import clsx from "clsx";
import { IImageItem } from "../../../../../typec/IClientSupport";

const ImageItem = (props): ReactElement => {
    const { src, onClick = () => null, deleteBtn = true, increasePhoto = false } = props;
    const [size, setSize] = useState(false);

    const click = () => {
        onClick();
    };

    const setIncreasePhoto = () => {
        if (increasePhoto) {
            size ? setSize(false) : setSize(true);
        }
    };

    const downloadAsFile = (data, name) => {
        const a = document.createElement("a");
        a.href = data;
        a.download = name;
        a.click();
    };

    return (
        <div id="image_item" className={styles.appl_photos_container}>
            {src?.split(";")[0]?.split(":")[1]?.split("/")[0] == "image" ? (
                <>
                    <img className={clsx(!size && styles["appl_photos_item"], size && styles["appl_photos_item_large"])} src={src} alt="" onClickCapture={setIncreasePhoto} />
                    {size && <div className={styles.appl_photos_item_modal} onClick={setIncreasePhoto} />}
                    {deleteBtn && (
                        <div onClick={click} className={styles.appl_photos_item_icon}>
                            <Delete />
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div onClick={() => downloadAsFile(src, "suppotr-tg-bot.pdf")} className={styles.appl_photos_item_file}>
                        <a>.{src?.split(";")[0]?.split(":")[1]?.split("/")[1]}</a>
                    </div>
                    {deleteBtn && (
                        <div onClick={click} className={styles.appl_photos_item_icon}>
                            <Delete />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ImageItem;
