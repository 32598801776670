import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import clsx from "clsx";
import { ITimeOffItemDep } from "../../../../../typec/ITimeOff";

const TimeOffItemDep = (props: ITimeOffItemDep): ReactElement => {
    const { forDate, workDate, isActual = true, isActive = false } = props;

    return (
        <>
            <div className={clsx(styles["item"], !isActual && styles["item_not_actual"], isActive && styles["item_active"])}>
                <div className={styles.item_container}>
                    <span className={styles.item_container_day_actual}>{forDate}</span>
                </div>
                <div className={styles.item_text}>за рабочий день {workDate}</div>
            </div>
        </>
    );
};

export default TimeOffItemDep;
