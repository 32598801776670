import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Navigation from "../../components/Navigation";
import { NavigationContext } from "../../template";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import ChoseReason from "./components/ChooseReason";
import { IChangeData } from "../../typec/IChangePersonalData";
import PersonalDataCheck from "./components/PersonalDataCheck";
import clsx from "clsx";

const ChangePersonalData = (props): ReactElement => {
    const [load, setLoad] = useState(true);
    const [loadScreen, setLoadScreen] = useState(false);
    const [screens, setScreen] = useState(10);
    const [data, setData] = useState({
        VidyPD: {
            VidPersonalnyhDannyh: props.vidPersonalnyhDannyh ? props.vidPersonalnyhDannyh : "",
            Prichina: props.prichina ? props.prichina : "",
            screen_zayav: [],
            screens: [],
            Comment: "",
        },
    });
    const [jsonData, setJson] = useState<IChangeData>();
    const [error, setError] = useState({
        ErrorCode: "",
        Error: "",
    });

    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        if (screens == 0) {
            navigate("/");
        } else {
            setScreen(screens - 1);
        }
    };
    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };

    const clearError = () => {
        setError({
            ErrorCode: "",
            Error: "",
        });
    };

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_pers_data_new", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res == true) {
                        setJson(json);
                        setLoad(false);
                        editData("VidyPD", {
                            VidPersonalnyhDannyh: props.vidPersonalnyhDannyh ? props.vidPersonalnyhDannyh : "",
                            Prichina: props.prichina ? props.prichina : "",
                            screen_zayav: [],
                            screens: [],
                            Comment: "",
                        });
                        setScreen(0);
                    } else {
                        setJson(json);
                    }
                });
        }
    }, [load]);

    const groupPhotoDocument = (el, single = false) => {
        const group = el.map((element, index) => {
            return {
                Name: `screen_${index}.${element.data.split("/")[1].split(";")[0]}`,
                Content: element.data,
            };
        });
        if (single) {
            return group[0];
        } else {
            return group;
        }
    };

    const postDismissal = () => {
        const screen_zayav = groupPhotoDocument(data.VidyPD.screen_zayav, true);
        const screens = groupPhotoDocument(data.VidyPD.screens);
        setLoadScreen(true);
        fetch("/dev/vacation_pers_data_new", {
            method: "POST",
            body: JSON.stringify({
                VidPD: data.VidyPD.VidPersonalnyhDannyh,
                PrichinaPD: data.VidyPD.Prichina,
                ScreenZayav: screen_zayav,
                Screens: screens,
                Comment: data.VidyPD.Comment,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    // Заявление сформировано
                    setLoadScreen(false);
                    goToMain();
                } else {
                    // Заявление не сформировано
                    console.log(json);
                    setLoadScreen(false);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const next = () => {
        setScreen(screens + 1);
    };
    const start = () => {
        setScreen(0);
    };

    const editData = (key, element) => {
        setData({
            ...data,
            [key]: element,
        });
    };

    //to add
    document.addEventListener("touchmove", Touchyhandler, false);
    //to remove
    document.removeEventListener("touchmove", Touchyhandler);
    function Touchyhandler(e) {
        e.preventDefault();
    }

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <div className={styles.dis}>
                        <p className={clsx(styles["dis_title"], screens == 1 && styles[`dis_title_check`])}>Заявление на изменение персональных данных</p>
                        <div className={screens == 0 ? "" : styles.none}>
                            <ChoseReason
                                key="ChoseReason"
                                error={error}
                                clearError={clearError}
                                VidyPD={data.VidyPD}
                                actionElement={next}
                                addData={editData}
                                reasons={jsonData?.VidyPD}
                            />
                        </div>
                        <div className={screens == 1 ? "" : styles.none}>
                            <PersonalDataCheck
                                fullName={context.userdata.name}
                                reason={data.VidyPD.VidPersonalnyhDannyh}
                                prichina={data.VidyPD.Prichina}
                                screen_zayav={groupPhotoDocument(data.VidyPD.screen_zayav)}
                                screens={groupPhotoDocument(data.VidyPD.screens)}
                                comment={data.VidyPD.Comment}
                                actionElement={start}
                                postDismissal={postDismissal}
                                load={loadScreen}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ChangePersonalData;
