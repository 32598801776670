import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "../index.module.scss";
import Loader from "../../../components/Loader";
import Button from "../../../components/Button";
import Navigation from "../../../components/Navigation";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router-dom";
import VacationList from "../components/VacationList";
import SlideScreen from "../../../components/SlideScreen";
import VacationEdit from "../components/VacationEdit";
import VacationItem from "../components/VacationItem";
import VacationCheck from "../components/VacationCheck";
import VacITNull from "./components/VacITNull";
import { IUserVacationIT } from "../../../typec/IVacations";
import word from "../../../components/word";

const VacationIT = (): ReactElement => {
    const [showChange, setShowChange] = useState({
        show: false,
        showCancel: false,
        modified: false,
    });
    const [screenCheck, setScreenCheck] = useState(false);
    const [error, setError] = useState({
        text: "",
        num: "",
    });
    const [userData, setUserData] = useState<IUserVacationIT>();
    const [newData, setNewData] = useState({
        valueStartDay: "",
        valueFinishDay: "",
        valueAmount: "",
        Date: "",
        view: false,
    });
    const [load, setLoad] = useState(true);
    const [loadBtn, setLoadBtn] = useState(false);

    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };

    const createError = (el) => {
        setError({
            ...error,
            ...el,
        });
    };
    const clearError = (key: string) => {
        setError({
            ...error,
            [key]: "",
        });
    };

    const loadHero = () => {
        setLoad(true);
    };

    const editPutInfo = (element) => {
        setUserData({
            ...userData,
            ...element,
        });
    };
    const editVacationNew = (element) => {
        setNewData({
            ...newData,
            ...element,
        });
    };
    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_vac_it_new", {
                method: "GET",
            })
                .then((res) => res.json())
                .then((json) => {
                    setUserData(json);
                    setLoad(false);
                    console.log(json);
                });
        }
    }, [load]);
    const reRender = () => {
        setLoad(true);
        setScreenCheck(false);
        setShowChange({
            modified: false,
            show: false,
            showCancel: false,
        });
    };
    const postVacationNew = () => {
        setLoadBtn(true);
        fetch("/dev/vacation_vac_it_new", {
            method: "POST",
            body: JSON.stringify({
                StartDate: newData.valueStartDay.replace(".2", ".202"),
                NumberDays: newData.valueAmount,
            }),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    setShowChange({
                        show: false,
                        showCancel: false,
                        modified: true,
                    });
                    setLoadBtn(false);
                    setNewData({
                        valueStartDay: newData.valueStartDay,
                        valueFinishDay: json.KonetsDate.replace(".20", "."),
                        valueAmount: newData.valueAmount,
                        Date: json.Date,
                        view: true,
                    });
                    showScreenCheck();
                } else {
                    createError({ text: json.Error, num: json.ErrorCode });
                    setLoadBtn(false);
                }
            });
    };
    const putVacationNew = () => {
        setLoadBtn(true);
        fetch("/dev/vacation_vac_it_new", {
            method: "PUT",
            body: JSON.stringify({}),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    setLoadBtn(false);
                    showScreenCheck();
                    setLoad(true);
                    setNewData({
                        valueStartDay: "",
                        valueFinishDay: "",
                        valueAmount: "",
                        Date: "",
                        view: false,
                    });
                    setShowChange({
                        show: false,
                        showCancel: false,
                        modified: false,
                    });
                } else {
                    setLoadBtn(false);
                    createError({ text: json.Error, num: json.ErrorCode });
                }
            });
    };

    const show = (element) => {
        setShowChange({
            ...showChange,
            ...element,
        });
    };
    const showScreenCheck = () => {
        screenCheck ? setScreenCheck(false) : setScreenCheck(true);
    };

    const loading = () => {
        setLoad(true);
    };

    const isNullVac = (): boolean => {
        let bool = false;
        const vacations = userData?.user_vacations
            ?.map((el) => {
                return el.Status;
            })
            .filter((value) => value != "Отменена" && value != "Информация передана руководителю IT, заявка закрыта");

        if (`${vacations}` == "") {
            bool = true;
        }
        return bool;
    };

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    {!screenCheck ? (
                        <>
                            {isNullVac() ? (
                                <VacITNull loading={loading} daysLeft={String(userData?.days_left)} />
                            ) : (
                                <div className={styles.hero_container_vacations}>
                                    <p className={styles.main_heading}>
                                        Заявление на <br /> дополнительный отпуск <br /> для IT
                                    </p>
                                    <p className={styles.second_title}>Выберите даты отпуска</p>
                                    <p className={styles.second_title_quantity_day}>
                                        У вас осталось:
                                        <a className={styles.second_title_quantity_day_num}>
                                            {" "}
                                            {String(userData?.days_left)} {word(Number(userData?.days_left), ["день", "дня", "дней"])}
                                        </a>
                                    </p>
                                    <VacationList typeVacation="IT" userData={userData} loadHero={loadHero} editPutInfo={editPutInfo} reRender={reRender} />
                                    {newData.view && (
                                        <VacationItem
                                            valueAmount={newData.valueAmount}
                                            valueFinishDay={newData.valueFinishDay}
                                            valueStartDay={newData.valueStartDay}
                                            typeItem="change"
                                            postVacationExternal={postVacationNew}
                                            editPutInfo={editVacationNew}
                                            loadExternal={loadBtn}
                                            errorExternal={error}
                                            clearErrorExternal={clearError}
                                            typeVacation="IT"
                                        />
                                    )}
                                    {showChange.modified ? null : (
                                        <Button type="add_vacation" icon="plus-interface" onClick={() => show({ show: true })}>
                                            Добавить отпуск
                                        </Button>
                                    )}
                                    <p className={styles.hero_container_vacations_next_vacation}>
                                        Следующие 3 отпускных дня будут доступны с 1 января {Number(`${new Date().getFullYear()}`) + 1} года.
                                    </p>
                                </div>
                            )}
                            {showChange.show ? (
                                <SlideScreen altitudeLevel="full" titleText="Новая дата отпуска" show={show}>
                                    <VacationEdit
                                        valueCause="По семейным обстоятельствам"
                                        clearError={clearError}
                                        editVacation={editVacationNew}
                                        error={error}
                                        load={loadBtn}
                                        postVacation={postVacationNew}
                                        textButton="Сформировать заявление"
                                        causeBool={false}
                                        editForIT={true}
                                        quantityDay={String(userData?.days_left)}
                                        dateNow={true}
                                        valueStartDay={newData.valueStartDay}
                                        typeVacation="IT"
                                    />
                                </SlideScreen>
                            ) : null}
                            {showChange.modified ? (
                                <SlideScreen altitudeLevel="service">
                                    <Button type="save_not_margin" onClick={() => showScreenCheck()}>
                                        Сформировать заявление
                                    </Button>
                                </SlideScreen>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <VacationCheck
                                finishDay={""}
                                quantityDay={newData.valueAmount}
                                typeVacation="Дополнительный отпуск для IT"
                                startDay={""}
                                statementDay={newData.Date}
                                nameStaff={context.userdata.name}
                                position={context.userdata.job_name}
                                subdivision={context.userdata.subdiv_name}
                                supervisor={context.userdata.boss_name}
                                periodArr={[
                                    `С ${newData.valueStartDay?.replace(".2", ".202")} по ${newData.valueFinishDay?.replace(".2", ".202")}, ${newData.valueAmount} ${word(
                                        Number(newData.valueAmount),
                                        ["день", "дня", "дней"]
                                    )}`,
                                ]}
                                disableDetails
                            />
                            <SlideScreen altitudeLevel="service">
                                <Button load={loadBtn} type="save_not_margin" onClick={() => putVacationNew()}>
                                    Отправить на согласование
                                </Button>
                            </SlideScreen>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default VacationIT;
