import DynamicIcon from "../DynamicIcon";
import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { NavigationContext } from "../../template";
import { Link } from "react-router-dom";
import Notification from "../Notification";
import clsx from "clsx";
import SlideScreen from "../SlideScreen";
import { InputCheckBox } from "../Inputs";
import Button from "../Button";
// import { click } from "@testing-library/user-event/dist/types/setup/directApi";

const MenuElement = (props) => {
    const [isOpen, setOpen] = useState(false);
    const context = useContext(NavigationContext);
    const { menu, click, nocheck = false, toggleSetting } = props;
    const clickFunc = () => {
        if (menu.component) {
            setOpen(true);
        } else {
            click(menu);
        }
    };

    const nowDate = new Date().getFullYear();

    const openChannel = (id) => {
        fetch("/dev/vacation_tg_channel_new", {
            method: "POST",
            body: JSON.stringify({
                ChannelID: id,
            }),
            // -1001745314862 - Лидеры розницы SOKOLOV
            // -1001279045860 - RealPeople
            // -1001682056666 - Дивизион на миллион
            // -1001928348498 - 1 линия поддержки IT-Retail
            // -1001919086024 - СБОРНАЯ МСК
            // -1001829198205 - ДИРЕКЦИЯ МСК
            // -1001735192018 - Север7 "Играем, чтобы выиграть!"
            // -1001666211396 - Мерч Север7, вопросы и ФО
            // -1002172998794 - SOKOLOV Офис
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    const tg = window.Telegram.WebApp; // Получаем объект WebApp Telegram
                    try {
                        tg.openTelegramLink(json.link); // Пробуем открыть ссылку непосредственно в Telegram
                    } catch {
                        try {
                            tg.openLink(json.link); // Пробуем открыть ссылку в браузере Telegram-методом
                        } catch {
                            window.open(json.link); // Пробуем открыть ссылку в браузере обычным методом
                        }
                    }
                }
            });
    };

    const Condition = props.link
        ? (propsChild) => (
              <Link className={styles.link} to={props.link}>
                  {propsChild.children}
              </Link>
          )
        : (propsChild) => <div>{propsChild.children}</div>;

    return (
        <>
            {nocheck ? (
                <>
                    <Condition>
                        <div className={styles.buttonOut} id={menu.id}>
                            <div className={styles.button} onClick={clickFunc} key={menu.title}>
                                {(menu.number && context.userdata[menu.number]) || !menu.number ? <span className={styles.number}>{context.userdata[menu.number]}</span> : null}

                                <span className={styles.span} dangerouslySetInnerHTML={menu.title}>
                                    {menu.dynamicDate ? `${menu.title} на ${nowDate}` : menu.title}
                                </span>
                                <div className={styles.icons}>
                                    <DynamicIcon name={menu.icon}></DynamicIcon>
                                </div>
                                {menu.dynamicDate ? <div className={styles.dynamicDate}>{nowDate}</div> : null}
                            </div>
                            {isOpen ? menu.component : null}
                        </div>
                    </Condition>
                </>
            ) : null}
            {(((menu.checkId && context.userdata[menu.checkId]) || !menu.checkId) && menu.type !== "TgCh") ||
            (menu.checkId && context.userdata[menu.checkId] && menu.type !== "TgCh" && menu.type !== "setting_ntf") ? (
                <>
                    <Condition>
                        {((menu.checkId && context.userdata[menu.checkId]) || !menu.checkId) && menu.type !== "TgCh" ? (
                            <>
                                <div className={styles.buttonOut} id={menu.id}>
                                    <div className={styles.button} onClick={menu.type == "setting_ntf" ? toggleSetting : clickFunc} key={menu.title}>
                                        {(menu.number && context.userdata[menu.number]) || !menu.number ? (
                                            <span className={styles.number}>{context.userdata[menu.number]}</span>
                                        ) : null}
                                        {/* <span className={styles.span}>{menu.title}</span> */}
                                        {menu.rescheduling ? (
                                            <span className={styles.span_rescheduling}>{menu.title}</span>
                                        ) : (
                                            <span className={styles.span}>{menu.dynamicDate ? `${menu.title} на ${nowDate}` : menu.title}</span>
                                        )}
                                        <div className={styles.icons}>
                                            <DynamicIcon name={menu.icon}></DynamicIcon>
                                        </div>
                                        {menu.dynamicDate ? <div className={styles.dynamicDate}>{nowDate}</div> : null}
                                    </div>
                                    {isOpen ? menu.component : null}
                                </div>
                            </>
                        ) : null}
                    </Condition>
                </>
            ) : null}
            {(menu.checkId && context.userdata[menu.checkId] && menu.type == "TgCh") || (!menu.checkId && (menu.ID_channel || menu.url)) ? (
                <div className={styles.gridspan} id={menu.id}>
                    <div className={styles.buttonCh} onClick={menu.ID_channel ? () => openChannel(menu.ID_channel) : () => window.open(menu.url, "_blank")} key={menu.title}>
                        {(menu.number && context.userdata[menu.number]) || !menu.number ? <span className={styles.number}>{context.userdata[menu.number]}</span> : null}
                        <div className={styles.text}>
                            <span className={styles.span}>{menu.title}</span>
                            <span className={styles.spam}>{menu.spam}</span>
                        </div>
                        <div>
                            <DynamicIcon name={menu.icon}></DynamicIcon>
                        </div>
                    </div>
                    {isOpen ? menu.component : null}
                </div>
            ) : null}
        </>
    );
};

export default MenuElement;
