import React, { ReactElement, useState } from "react";
import styles from "./index.module.scss";
import Loader from "../../../../components/Loader";
const MainItem2 = (props): ReactElement => {
    const { result = false } = props;
    const [load, setLoad] = useState(false);
    return (
        <>
            {load ? <Loader /> : null}
            <div>
                <div className={result ? styles.block_view : styles.block}>{props.current.Name}</div>
            </div>
        </>
    );
};

export default MainItem2;
