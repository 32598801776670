import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import stylemenu from "./index.module.scss";
import Navigation from "../Navigation";
import MenuElement from "./MenuElement";
import Logo from "../Logo";
import Poisk from "../Search";
import { Link, useNavigate } from "react-router-dom";
import Notification from "../Notification";
import { NavigationContext } from "../../template";
import DynamicIcon from "../DynamicIcon";
import menu from "./Menu";
import Rose from "../../pages/Rose";
import SlideScreen from "../SlideScreen";
import { InputCheckBox } from "../Inputs";
import Button from "../Button";
//import { userInfo } from "os";

export interface ISetting {
    DniRozhdeniya?: boolean;
    Otpuska?: boolean;
    Godovschinyi?: boolean;
    ReminderBD?: boolean;
    res?: boolean;
}

const Menu = (props) => {
    const context = useContext(NavigationContext);

    // настройки уведомлений

    const [viewSetting, setViewSetting] = useState(false);
    const [dataNotification, setDataNotification] = useState<ISetting>();
    const [loadSetting, setLoadSetting] = useState(false);

    const toggleSetting = () => {
        setViewSetting(!viewSetting);
    };

    const editData = (key, element) => {
        setDataNotification((prevState) => {
            return {
                ...prevState,
                [key]: element,
            };
        });
    };

    useEffect(() => {
        fetch("/dev/vacation_notification_info", {
            method: "GET",
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    setDataNotification({
                        Otpuska: json.Otpuska,
                        DniRozhdeniya: json.DniRozhdeniya,
                        Godovschinyi: json.Godovschinyi,
                        ReminderBD: json.ReminderBD,
                    });
                }
            });
    }, []);

    const putSetting = (data) => {
        setLoadSetting(true);
        fetch("/dev/vacation_notification_info", {
            method: "PUT",
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    setViewSetting(false);
                }
                setLoadSetting(false);
            });
    };

    useEffect(() => {
        if (viewSetting) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [viewSetting]);

    //

    return (
        <>
            <div className={stylemenu.obertka}>
                <nav className={stylemenu["menu"]}>
                    {context?.history?.length !== 0 && (
                        <Navigation prevStop={context.history.length < 3 ? true : false} prev={context.prev} goToMain={context.goToMain}></Navigation>
                    )}
                    {context?.history?.length == 0 && (
                        <div>
                            <Logo />
                            {/* <div className={stylemenu["outerDiv"]}>
                                <Poisk type="poisk">Поиск</Poisk>
                            </div> */}
                            {context?.userdata?.name ? <div className={styles.zagolovokMenu}>{context?.userdata?.name?.split(" ")[1]}, чем могу помочь?</div> : null}
                        </div>
                    )}
                    <div className={styles.titleZagolovok}>{context.currentMenu[0].menuTitle}</div>
                    <div className={styles.zagolovok}>{context.currentMenu[0].description}</div>
                    {context?.userdata.res == true && (
                        <>
                            {context?.currentMenu[0].title == "Отпуск" && (!context?.userdata?.edo_aval1 || context?.userdata?.edo_srok_istek) ? (
                                <>
                                    <div style={{ marginTop: "24px" }} />
                                    <Notification type="err" icon="exclamation-mark">
                                        {context?.userdata?.edo_srok_istek ? (
                                            <div>
                                                Срок действия сертификата электронной подписи истек {context?.userdata?.edo_srok}. Перевыпустите сертификат в&nbsp;
                                                <a target="_blank" style={{ textDecoration: "none", color: "#257AE8", fontWeight: "600" }} href="https://lk.hr-link.ru">
                                                    личном кабинете HR-Link
                                                </a>
                                            </div>
                                        ) : (
                                            `Мы не нашли действующую электронную подпись, функция электронной подачи заявок ограничена. Обратитесь в ОКА.`
                                        )}
                                    </Notification>
                                </>
                            ) : context?.currentMenu[0].title == "Оплачиваемый отпуск" && !context?.userdata?.boss_teleg ? (
                                <>
                                    <div style={{ marginTop: "24px" }} />
                                    <Notification type="err" icon="exclamation-mark">
                                        Ваш руководитель {context?.userdata?.boss_name} не зарегистрирован в Telegram-боте SOKOLOVHR. Вы не можете оформить заявление на отпуск.
                                    </Notification>
                                </>
                            ) : (
                                <>
                                    <div className={stylemenu.menuGl}>
                                        {context?.currentMenu.map((menu, index) => (
                                            // menu.link ? (
                                            //     <Link className={stylemenu.link} to={menu.link}>
                                            //         <MenuElement click={context.click} menu={menu} goToMain={context.goToMain} />
                                            //     </Link>
                                            // ) : (
                                            <MenuElement
                                                toggleSetting={toggleSetting}
                                                key={`menu_element_${index}`}
                                                link={menu.link}
                                                click={context.click}
                                                menu={menu}
                                                goToMain={context.goToMain}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {context?.userdat.res == false && (
                        // (context?.userdat.errc == "ER_HRMCN" ||
                        //     context?.userdat.errc == "ER_HRMDC" ||
                        //     context?.userdat.errc == "ER_HRMUN" ||
                        //     context?.userdat.errc == "ER_HRM") && (
                        // <Notification type="err" icon="exclamation-mark">
                        //     {context?.userdat.err}
                        // </Notification>
                        <>
                            {context?.userdat.errc == "ER_HRMDC" || context?.userdat.errc == "ER_HRMCN" ? (
                                <Notification type="err" icon="exclamation-mark">
                                    <div style={{ width: "calc(100% - 60px)" }}>
                                        Технические работы!
                                        <br />
                                        Вернитесь чуть позже!
                                    </div>
                                </Notification>
                            ) : (
                                <Notification type="err" icon="exclamation-mark">
                                    {context?.userdat.err}
                                </Notification>
                            )}
                        </>
                    )}
                    {context?.userdat.res == true && context?.userdata.res == false && (
                        <Notification type="err" icon="exclamation-mark">
                            Не удалось получить доступ к карточке сотрудника. Обратитесь к разработчикам
                        </Notification>
                    )}
                    {context?.guest && (
                        <>
                            <>
                                <div className={stylemenu.menuGl}>
                                    {context?.currentMenu
                                        .filter((element) => element.id == 6)
                                        .map((menu, index) => (
                                            <MenuElement
                                                nocheck={true}
                                                key={`menu_element_${index}`}
                                                link={menu.link}
                                                click={context.click}
                                                menu={menu}
                                                goToMain={context.goToMain}
                                                toggleSetting={toggleSetting}
                                            />
                                        ))}
                                </div>
                            </>
                        </>
                    )}
                </nav>
            </div>
            {viewSetting ? (
                <>
                    <SlideScreen altitudeLevel="notification" titleText="Уведомления" onClose={() => toggleSetting()}>
                        <div className={styles.stt}>
                            <div>
                                <p className={styles.stt_title}>Выберите уведомления, которые вы хотите получать</p>
                                <InputCheckBox
                                    checked={dataNotification?.DniRozhdeniya}
                                    onClick={() => editData("DniRozhdeniya", !dataNotification?.DniRozhdeniya)}
                                    titleText={context.userdata?.is_boss ? "Дни рождения подчиненных" : "Дни рождения коллег"}
                                    reverse
                                    style={{ display: "flex", flexDirection: "column", marginTop: "-2px", letterSpacing: "0.5px", lineHeight: "24px" }}
                                />
                                {context.userdata?.is_boss ? (
                                    <InputCheckBox
                                        checked={dataNotification?.ReminderBD}
                                        onClick={() => editData("ReminderBD", !dataNotification?.ReminderBD)}
                                        reverse
                                        style={{ display: "flex", flexDirection: "column", marginTop: "-2px", letterSpacing: "0.5px", lineHeight: "24px" }}
                                    >
                                        <div>
                                            Дни рождения подчиненных&nbsp;<a style={{ color: "#979797" }}>(за 7 дней)</a>
                                        </div>
                                    </InputCheckBox>
                                ) : (
                                    <InputCheckBox
                                        checked={dataNotification?.ReminderBD}
                                        onClick={() => editData("ReminderBD", !dataNotification?.ReminderBD)}
                                        reverse
                                        style={{ display: "flex", flexDirection: "column", marginTop: "-2px", letterSpacing: "0.5px", lineHeight: "24px" }}
                                    >
                                        <div>
                                            Дни рождения коллег&nbsp;<a style={{ color: "#979797" }}>(за 3 дня)</a>
                                        </div>
                                    </InputCheckBox>
                                )}
                                <InputCheckBox
                                    checked={dataNotification?.Otpuska}
                                    onClick={() => editData("Otpuska", !dataNotification?.Otpuska)}
                                    titleText={context.userdata?.is_boss ? "Отпуска подчиненных" : "Отпуска коллег"}
                                    reverse
                                    style={{ display: "flex", flexDirection: "column", marginTop: "-2px", letterSpacing: "0.5px", lineHeight: "24px" }}
                                />
                                {context.userdata?.is_boss ? (
                                    <InputCheckBox
                                        checked={dataNotification?.Godovschinyi}
                                        onClick={() => editData("Godovschinyi", !dataNotification?.Godovschinyi)}
                                        titleText="Стаж подчиненных"
                                        reverse
                                        style={{ display: "flex", flexDirection: "column", marginTop: "-2px", letterSpacing: "0.5px", lineHeight: "24px" }}
                                    />
                                ) : null}
                            </div>
                            <div>
                                <Button load={loadSetting} type="save_not_margin" onClick={() => putSetting(dataNotification)}>
                                    Применить
                                </Button>
                            </div>
                        </div>
                    </SlideScreen>
                </>
            ) : null}
        </>
    );
};

export default Menu;
