import React, { ReactElement } from "react";
import styles from "../../index.module.scss";
import Notification from "../../../../components/Notification";
import SlideScreen from "../../../../components/SlideScreen";
import Button from "../../../../components/Button";
import { IDismissalCheck } from "../../../../typec/IDismissalStaff";

const DismissalCheck = (props: IDismissalCheck): ReactElement => {
    const { fullName, cause, details, dateDismissal, address, actionElement, dateApplication, putDismissal = () => null, load } = props;
    return (
        <>
            <div className={styles.dis_check_title}>от {dateApplication}</div>
            <Notification type="info" icon="info">
                Проверьте введённые данные и отправьте заявку на согласование.
            </Notification>
            <div className={styles.dis_check_title_item}>
                ФИО сотрудника
                <p className={styles.dis_check_text}>{fullName}</p>
            </div>
            <div className={styles.dis_check_title_item}>
                Причина увольнения
                <p className={styles.dis_check_text}>{cause}</p>
            </div>
            {details ? (
                <>
                    <div className={styles.dis_check_title_item}>
                        Подробности
                        <p className={styles.dis_check_text}>{details}</p>
                    </div>
                </>
            ) : null}
            <div className={styles.dis_check_title_item}>
                Дата увольнения
                <p className={styles.dis_check_text}>{dateDismissal}</p>
            </div>
            {address ? (
                <>
                    <div className={styles.dis_check_title_item}>
                        Адрес
                        <p className={styles.dis_check_text}>{address}</p>
                    </div>
                </>
            ) : null}
            <div style={{ marginTop: "188px" }} />
            <SlideScreen altitudeLevel="service">
                <Button load={load} onClick={putDismissal} type="save_not_margin">
                    Отправить на согласование
                </Button>
                <Button onClick={actionElement} type="cancel_grey">
                    Отмена
                </Button>
            </SlideScreen>
        </>
    );
};

export default DismissalCheck;
