import React, { ReactElement } from "react";
import styles from "../../index.module.scss";
import Notification from "../../../../components/Notification";
import SlideScreen from "../../../../components/SlideScreen";
import Button from "../../../../components/Button";
import ImageItem from "../../../ClietSupport/NewAppeal/components/ImageItem";

const PersonalDataCheck = (props): ReactElement => {
    const { reason, prichina, fullName, screen_zayav, screens, comment, actionElement, postDismissal = () => null, load } = props;
    const currentDate = new Date().toLocaleDateString();

    return (
        <>
            <div className={styles.dis_check_title}>от {currentDate}</div>
            <Notification type="info" icon="info">
                Проверьте данные и отправьте заявление.
            </Notification>
            <div className={styles.dis_check_title_item}>
                ФИО сотрудника
                <p className={styles.dis_check_text}>{fullName}</p>
            </div>
            <div className={styles.dis_check_title_item}>
                Вид персональных данных
                <p className={styles.dis_check_text}>{reason}</p>
            </div>
            {prichina != "" ? (
                <div className={styles.dis_check_title_item}>
                    Причина изменения персональных данных
                    <p className={styles.dis_check_text}>{prichina}</p>
                </div>
            ) : null}
            {screen_zayav.length !== 0 ? (
                <div className={styles.dis_check_title_item}>
                    <p className={styles.mappl_info_title_files}>Фото заявления</p>
                    <div className={styles.mappl_info_container_files}>
                        {screen_zayav?.map((element, index) => (
                            <ImageItem increasePhoto={true} deleteBtn={false} src={element.Content} key_photo={`listAttach_photo_${index}`} />
                        ))}
                    </div>
                </div>
            ) : null}

            {screens.length !== 0 ? (
                <div className={styles.dis_check_title_item}>
                    <p className={styles.mappl_info_title_files}>Фото подтверждающих документов</p>
                    <div className={styles.mappl_info_container_files}>
                        {screens?.map((element, index) => (
                            <ImageItem increasePhoto={true} deleteBtn={false} src={element.Content} key_photo={`listAttach_photo_${index}`} />
                        ))}
                    </div>
                </div>
            ) : null}
            {comment !== "" ? (
                <div className={styles.dis_check_title_item}>
                    Новые данные
                    <p className={styles.dis_check_text}>{comment}</p>
                </div>
            ) : null}
            <div style={{ marginTop: "188px" }} />
            <SlideScreen altitudeLevel="service">
                <Button load={load} onClick={postDismissal} type="save_not_margin">
                    Отправить
                </Button>
                <Button onClick={actionElement} type="cancel_grey">
                    Отмена
                </Button>
            </SlideScreen>
        </>
    );
};

export default PersonalDataCheck;
