import React, { ReactElement, useContext, useState } from "react";
import styles from "./index.module.scss";
import ListItem from "../ListItem";
import Loader from "../../../components/Loader";
import { NavigationContext } from "../../../template";

const List = (props): ReactElement => {
    console.log(props);
    const context = useContext(NavigationContext);
    return (
        <>
            <div>
                {context.dataRedirect != null ? (
                    <>
                        <Loader />
                        <div className={styles.load} />
                    </>
                ) : null}
                {props.data.map((list, index) => (
                    <ListItem getLoad={props.getLoad} isApprovePage={props.isApprovePage} current={list} key={`List_is_approve_${props.isApprovePage}_id_${index}`} />
                ))}
            </div>
        </>
    );
};

export default List;
