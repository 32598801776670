import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Navigation from "../../../components/Navigation";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import HomeUser from "./HomeUser";
const DeleteUser = (props): ReactElement => {
    const [load, setLoad] = useState(true);
    const [screens, setScreen] = useState(0);
    const [data, setData] = useState({
        homeScreen: {
            cause: props.current.PrichinaUvolneniya,
            details: "",
            dateDismissal: "",
            dismBool: undefined,
        },
        questionsList: undefined,
        address: "",
    });
    const [jsonData, setJson] = useState<any>({});
    const [error, setError] = useState({
        ErrorCode: "",
        Error: "",
    });

    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };
    const clearError = () => {
        setError({
            ErrorCode: "",
            Error: "",
        });
    };

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_list_boss_new", {
                method: "POST",
                body: JSON.stringify({
                    IDApp: props.current.IDApp,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        console.log(json);
                        setJson(json);
                        setLoad(false);
                    } else {
                        console.log(json);
                        setJson(json);
                    }
                });
        }
    }, [load]);
    const [isElementVisible, setElementVisible] = useState(true);
    const next = () => {
        props.editData("homeScreen", {
            cause: data.homeScreen.cause,
            details: data.homeScreen.details,
            dateDismissal: data.homeScreen.dateDismissal,
            dismBool: data.homeScreen.dismBool,
        });
        props.setElementVisible(!isElementVisible);
    };
    const editData = (key, element) => {
        setData({
            ...data,
            [key]: element,
        });
    };

    useEffect(() => {
        if (data.homeScreen.cause && data.homeScreen.dateDismissal && data.homeScreen.details) next();
    }, [data]);
    // console.log(props.HasADFS);

    console.log(jsonData);
    return (
        <>
            {isElementVisible && (
                <div className={styles.buttonBlock}>
                    {load ? (
                        <>
                            <Loader></Loader>
                            <Navigation></Navigation>
                        </>
                    ) : (
                        <>
                            <Navigation prev={next} goToMain={goToMain}></Navigation>
                            <div className={styles.menu}>
                                {props.isApprovePage ? (
                                    <p className={styles.zagolovok}>
                                        Заявление на увольнение №{props.current.Number} от {props.current.Date}, {props.current.EmpName}
                                    </p>
                                ) : (
                                    <p className={styles.zagolovok}>
                                        Заявление на увольнение №{props.current.Number} от {props.current.Date}, {context.userdata.name}
                                    </p>
                                )}
                                <HomeUser
                                    error={error}
                                    current={props.current}
                                    clearError={clearError}
                                    homeScreenData={data.homeScreen}
                                    // actionElement={next}
                                    addData={editData}
                                    reasons={jsonData.dism_reasons}
                                    empProbEnd={jsonData?.app?.EmpProbEnd}
                                    HasADFS={props.setHasADFS}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default DeleteUser;
