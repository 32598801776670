import React, { ReactElement } from "react";
import TimeOffItem from "../TimeOffItem";
import { ITimeOffList } from "../../../../typec/ITimeOff";

const TimeOffList = (props: ITimeOffList): ReactElement => {
    const { userData, editData, clearError } = props;

    const isNotNullBoolean = (el) => {
        let bool = false;
        const arr = el;
        if (`${arr}` != "") {
            bool = true;
        }
        return bool;
    };

    return (
        <>
            {isNotNullBoolean(userData)
                ? userData?.list?.map((element, index) => (
                      <TimeOffItem disableEdit={userData.dayoff_transfer} clearError={clearError} editData={editData} key={`offTime_num${index}`} data={element} />
                  ))
                : null}
        </>
    );
};

export default TimeOffList;
