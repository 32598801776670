import React, { ReactElement, useEffect, useState } from "react";
import styles from "../../index.module.scss";
import { InputError, InputRadio, InputSearchDrodpown, InputTextArea } from "../../../../components/Inputs";
import Notification from "../../../../components/Notification";
import SlideScreen from "../../../../components/SlideScreen";
import Button from "../../../../components/Button";
import { IWorkBook } from "../../../../typec/IDismissalStaff";
import { useDebounce } from "../../../../shared/helpers/useDebounce";
import { ISearchAddress } from "../../../../typec/IInputs";

const WorkBookAddress = (props: IWorkBook): ReactElement => {
    const { address, addData = () => null, dataAddress, actionElement = () => null, error, clearError = () => null } = props;
    const [data, setData] = useState({
        address: dataAddress ? dataAddress : "",
    });
    const [searchAdr, setSearchAdr] = useState<ISearchAddress>();
    const [activSrc, setActivSrc] = useState(false);
    const [click, setClick] = useState(false);
    const [newAddress, setNewAddress] = useState(dataAddress && dataAddress != address ? false : true);

    const editView = (element) => {
        setNewAddress(element == "Да" ? true : false);
        setData({
            address: "",
        });
        clearError();
        setActivSrc(false);
    };

    const setVievSearch = () => {
        setClick(true);
        setActivSrc(false);
    };

    const editAddress = (key, element) => {
        setData({
            ...data,
            [key]: element,
        });
        clearError();
        if (!click) {
            console.log("qwert");
            search(data.address);
            setActivSrc(true);
        }
        setClick(false);
    };
    const saveData = () => {
        actionElement();
    };

    const search = useDebounce((data) => {
        fetch("https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Token " + "96a4fea1de63d1ccf8ed5bc7cd5a72346ead58e2",
            },
            body: JSON.stringify({ query: data }),
        })
            .then((response) => response.json())
            .then((json) => {
                console.log(json);
                setSearchAdr(json?.suggestions);
            });
    }, 850);

    useEffect(() => {
        addData("address", data.address ? data.address : address);
    }, [data]);
    return (
        <>
            <p className={styles.dis_text}>Хотите получить трудовую книжку по адресу Вашей прописки?</p>
            <p className={styles.dis_text_item}>{address}</p>
            <div className={styles.dis_questions}>
                <InputRadio editData={editView} name={address} id_key={`1_${address}`} checked={newAddress} secondTitle="Да" />
                <InputRadio editData={editView} name={address} id_key={`2_${address}`} checked={!newAddress} secondTitle="Нет" />
            </div>
            {!newAddress ? (
                <>
                    <div style={{ margin: "24px 0px 0px 0px" }} />
                    <InputTextArea
                        editData={editAddress}
                        name="address"
                        value={data.address}
                        type="area_middle"
                        titleText="Новый адрес получения"
                        placeholder="Город, улица, дом, квартира..."
                        counterView={true}
                        counterValue="200"
                        errorMessage={error?.Error}
                        style={{ marginBottom: "24px" }}
                    />
                    <InputSearchDrodpown onClick={setVievSearch} editData={editAddress} name="address" value={searchAdr} active={activSrc} />
                </>
            ) : null}
            <Notification type="info" icon="info">
                Отправка средствами почты россии.
            </Notification>
            <div style={{ marginTop: "100px" }} />
            <SlideScreen altitudeLevel="service">
                <Button onClick={() => saveData()} disabled={!(newAddress || data.address)} type="save_not_margin">
                    Далее
                </Button>
                {error?.Error && <InputError titleText={error?.Error} />}
            </SlideScreen>
        </>
    );
};

export default WorkBookAddress;
