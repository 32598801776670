import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { InputRadioAddress } from "../../../../../components/Inputs/InputRadioAddress";

const AddressList = (props) => {
    const { items, name, editData, setPrev, selectedStore } = props;

    const editPrev = (element) => {
        setPrev(element);
        editData(name, element);
    };

    return (
        <>
            {items?.stores?.map((element) => (
                <InputRadioAddress name={name} editData={editPrev} key={element.guid} id_key={`radio_inp_${element.guid}`} value={element} selectedStore={selectedStore} />
            ))}
        </>
    );
};

export default AddressList;
