import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import clsx from "clsx";
import { InputTitle } from "../../../../../components/Inputs";
import Button from "../../../../../components/Button";
import SlideScreen from "../../../../../components/SlideScreen";
import DynamicIcon from "../../../../../components/DynamicIcon";
import { FilterItemRegion } from "./components/FilterItemRegion";

const FilterCity = (props): React.ReactElement => {
    const { icon, titleText, type = "dropdown", editData, name, value, errorMessage, onClick = () => null, items, postAddress } = props;
    const [region, setRegion] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [showSlide, setShow] = useState({
        show: false,
        showCancel: false,
        modified: false,
    });
    const [isElementVisible, setElementVisible] = useState(false);
    const handleToggle = () => {
        isElementVisible ? setElementVisible(false) : setElementVisible(true);
    };

    const editRegion = (element) => {
        setRegion(element);
    };

    const editCity = (element) => {
        setCity(element);
    };

    const show = (element) => {
        setShow({
            ...showSlide,
            ...element,
        });
    };
    const click = () => {
        handleToggle();
        // onClick();
        show({ show: true });
        setRegion("");
        setCity("");
    };
    const saveData = (key, element) => {
        editData(key, element);
        show({ show: false });
    };
    useEffect(() => {
        if (isElementVisible) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isElementVisible, region]);

    const chooseAddress = () => {
        if (city) {
            postAddress("", city, "");
        } else {
            postAddress("", "", region);
        }
        handleToggle();
    };

    return (
        <>
            <button id="dropdown" onClick={click} className={clsx(styles["btn"], styles[`btn_save_not_margin`])}>
                {titleText}
                {icon ? (
                    <div className={clsx(styles[`inp_icon`], showSlide.show && styles[`inp_icon_up`], !showSlide.show && styles[`inp_icon_down`])}>
                        <DynamicIcon name={icon} />
                    </div>
                ) : null}
            </button>
            {isElementVisible ? (
                <SlideScreen show={show} titleText={titleText} altitudeLevel="full" onClose={click}>
                    <div className={styles.inp_dropdown_container_items}>
                        {Object.keys(items)?.map((element, index) => (
                            <FilterItemRegion
                                editData={editRegion}
                                editCity={editCity}
                                name={name}
                                key={index}
                                id_key={`radio_inp_${index}`}
                                value={element}
                                items={items}
                                region={region}
                            />
                        ))}
                    </div>
                    <div style={{ marginTop: "100px" }} />
                    <div className={styles.submit}>
                        <Button disabled={region != "Выберите" ? false : true} onClick={chooseAddress} type="save_not_margin">
                            Выбрать
                        </Button>
                    </div>
                </SlideScreen>
            ) : null}
        </>
    );
};

export { FilterCity };
