import React, { ReactElement } from "react";
import VacationItem from "../../../components/VacationItem";
import { IVacationList } from "../../../../../typec/IVacations";

const TranspositionList = (props: IVacationList): ReactElement => {
    const {
        userData,
        editPutInfo,
        loadHero,
        addVacationExternal,
        switchDivadeBool,
        divadeBool,
        idVacationOnTransition,
        typeVacation,
        disableDivade,
        switchDisableDivade,
        reRender,
        userVacNewList,
    } = props;

    const isNotNullBoolean = (el) => {
        let bool = false;
        const arr = el;
        if (`${arr}` != "") {
            bool = true;
        }
        return bool;
    };

    console.log(userData);

    return (
        <>
            {isNotNullBoolean(userData?.user_transfer)
                ? userData?.user_transfer?.map((element, index) => (
                      <VacationItem
                          loadHero={loadHero}
                          idVacation={userData.user_transfer_id}
                          editPutInfo={editPutInfo}
                          key={`vacationTransfer_num${index}`}
                          typeItem={element.Changed ? "consider" : "graph_not_change"}
                          valueStartDay={element.StartDateTransfer.replace(".20", ".")}
                          valueFinishDay={element.KonetsDateTransfer.replace(".20", ".")}
                          valueAmount={String(element.NumberDaysTransfer)}
                          typeText={element.Changed ? "На согласовании" : element.TypeVacationName}
                      />
                  ))
                : isNotNullBoolean(userData?.user_schedule)
                ? userData?.user_schedule?.map((element, index) => (
                      <VacationItem
                          reRender={reRender}
                          loadHero={loadHero}
                          editPutInfo={editPutInfo}
                          key={`vacationSchedule_num${index}`}
                          typeItem={element.TransferForbidden ? "signed" : "graph"}
                          valueStartDay={element.StartDate.replace(".20", ".")}
                          valueFinishDay={element.KonetsDate.replace(".20", ".")}
                          valueAmount={String(element.NumberDays)}
                          addVacationExternal={addVacationExternal}
                          typeVacation={typeVacation}
                          switchDivadeBool={switchDivadeBool}
                          divadeBool={divadeBool}
                          idVacationOnTransition={idVacationOnTransition}
                          disableDivade={disableDivade}
                          switchDisableDivade={switchDisableDivade}
                          userVacNewList={userVacNewList}
                          typeText={element.TypeVacationName}
                      />
                  ))
                : null}
        </>
    );
};

export default TranspositionList;
