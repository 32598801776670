import React, { ReactElement } from "react";
import styles from "../index.module.scss";
import { ICurrentData } from "../../../../typec/IPODetails";

const CurrentData = (props: ICurrentData): ReactElement => {
    const { bank_name, bank_acc } = props;
    return (
        <>
            <div className={styles.data_title}>Текущие</div>
            <div className={styles.data_item}>
                <p className={styles.data_item_title}>Наименование банка:</p>
                <p className={styles.data_item_text}>{bank_name}</p>
            </div>
            <div className={styles.data_item}>
                <p className={styles.data_item_title}>Номер счёта:</p>
                <p className={styles.data_item_text}>{bank_acc}</p>
            </div>
        </>
    );
};

export default CurrentData;
