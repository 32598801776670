const word = (count: number, array: Array<string>): string => {
    let result = "";
    if (array.length == 3) {
        switch (count >= 20 ? count % 10 : count) {
            case 1:
                result = array[0];
                break;
            case 2:
            case 3:
            case 4:
                result = array[1];
                break;
            default:
                result = array[2];
        }
    } else if (array.length == 2) {
        switch (count - Math.floor(count / 10) * 10) {
            case 1:
                result = array[0];
                break;
            default:
                result = array[1];
                break;
        }
    }
    return result;
};

export default word;
