import React, { ReactElement } from "react";
import styles from "../../../index.module.scss";
import { IAssInvOfDep } from "../../../../../typec/IDocumentRequest";

const AsgInvOfDep = (props: IAssInvOfDep): ReactElement => {
    const { nameDep, quantity, onClick = () => null } = props;
    const click = (nameDep, quantity) => {
        onClick(nameDep, quantity);
    };
    return (
        <>
            <div onClick={() => click(nameDep, quantity)} className={styles.doc_asg_invent_item}>
                <p className={styles.doc_asg_invent_item_title}>{nameDep}</p>
                <p className={styles.doc_asg_invent_item_quantity}>{quantity} шт.</p>
            </div>
        </>
    );
};

export { AsgInvOfDep };
