import React, { ReactElement } from "react";
import styles from "../../index.module.scss";
import Notification from "../../../../../components/Notification";
import { IAppealItem } from "../../../../../typec/IClientSupport";

const AppealItem = (props: IAppealItem): ReactElement => {
    const { numberAppeal, tag, date, onClick = () => void 0, req_id, text, key_item } = props;

    const click = () => {
        onClick(numberAppeal, date, tag, req_id, text);
    };

    return (
        <>
            <div key={key_item} onClick={click} className={styles.mappl_item}>
                <div key={key_item} className={styles.mappl_item_flex}>
                    <p key={key_item} className={styles.mappl_item_title}>
                        Обращение №{numberAppeal}
                    </p>
                    <Notification type={tag == "Открыта" ? "coordination" : "signed"}>{tag}</Notification>
                </div>
                <p className={styles.mappl_item_date}>{date}</p>
            </div>
        </>
    );
};

export default AppealItem;
