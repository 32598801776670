import React, { LegacyRef, ReactElement, useState } from "react";
import styles from "../index.module.scss";
import clsx from "clsx";
import { InputTitle } from "../InputTitle";
import { InputError } from "../InputError";
import { IMask, useIMask } from "react-imask";
import { IInputMonthYear } from "../../../typec/IInputs";

const InputMonthYear = (props: IInputMonthYear): ReactElement => {
    const { onChange = () => null, type, titleText, clearError, errorMessage, value, disable = false, style, editData, name = "", separator } = props;

    const [ifValue, setIfValue] = useState(false);

    const [options] = useState({
        mask: separator ? `mm${separator}yy` : "mm.yy",
        blocks: {
            mm: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12,
                maxLength: 2,
                placeholderChar: "М",
            },
            yy: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 99,
                maxLength: 2,
                placeholderChar: "Г",
            },
        },
        autofix: true,
        lazy: false,
    });

    const { ref } = useIMask(options, {
        onAccept: (value) => {
            editData && editData(name, value);
            clearError && clearError("text");
            if (/\d/.test(value)) setIfValue(false);
            else setIfValue(true);
        },
    });

    return (
        <div style={style}>
            {titleText && <InputTitle titleText={titleText} />}
            <input
                disabled={disable}
                ref={ref as LegacyRef<HTMLInputElement> | undefined}
                defaultValue={value}
                onChange={() => onChange()}
                className={clsx(styles["inp"], styles[`inp_${type}`], errorMessage && styles[`inp_error`], ifValue && styles[`inp_val`])}
            />
            {errorMessage && <InputError titleText={errorMessage} />}
        </div>
    );
};

export { InputMonthYear };
