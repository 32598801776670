import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { NavigationContext } from "../../template";
import Navigation from "../../components/Navigation";
import CurrentData from "./components/CurrentData";
import NewDataPO from "./components/NewDataPO";
import EditDataPO from "./components/EditDataPO";
import SendingData from "./components/SendingData";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import { IErrorPODetails, IPODetails } from "../../typec/IPODetails";

const PODetails = (): ReactElement => {
    const [load, setLoad] = useState(true);
    const [loadBtn, setLoadBtn] = useState(false);
    const [oldPOData, setOldData] = useState<IPODetails>();
    const [error, setError] = useState<IErrorPODetails>();
    const [show, setShow] = useState({
        newData: false,
        screenEdit: false,
        putScreen: false,
    });
    const [ntfError, setNtfError] = useState(false);
    const [zypError, setZYPError] = useState("");

    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
        setShow({
            newData: false,
            screenEdit: false,
            putScreen: false,
        });
    };

    const showScreenAddNewData = () => {
        setShow({
            newData: false,
            screenEdit: true,
            putScreen: false,
        });
    };
    const createError = (el) => {
        setError(el);
    };
    const clearError = () => {
        setError(undefined);
    };
    const saveNewData = (el) => {
        setLoadBtn(true);
        fetch("/dev/vacation_bank_card_new", {
            method: "PUT",
            body: JSON.stringify({
                BankName: el.bank_name,
                BankInn: el.INN,
                BankBic: el.BIC,
                BankSchet: el.numberCheck,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    setLoadBtn(false);
                    setShow({
                        newData: true,
                        screenEdit: false,
                        putScreen: true,
                    });
                } else {
                    setLoadBtn(false);
                    createError(json.Errors);
                    setZYPError(json.Error);
                }
            });
    };
    const saveFileNewData = (el) => {
        setLoadBtn(true);
        fetch("/dev/vacation_bank_card_new", {
            method: "POST",
            body: el,
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    setShow({
                        newData: true,
                        screenEdit: false,
                        putScreen: true,
                    });
                    setLoadBtn(false);
                } else {
                    setLoadBtn(false);
                    setNtfError(true);
                }
            });
    };
    const closeScreenPut = () => {
        setShow({
            newData: true,
            screenEdit: false,
            putScreen: false,
        });
        setLoad(true);
    };
    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_bank_card_new", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        setLoad(false);
                        setOldData(json);
                        setNtfError(false);
                    }
                });
        }
    }, [load]);

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    {!show.screenEdit ? (
                        show.putScreen ? (
                            <SendingData closeScreenPut={closeScreenPut} goToMain={goToMain} />
                        ) : (
                            <>
                                <Navigation prev={prev} goToMain={goToMain}></Navigation>
                                <div className={styles.po_container}>
                                    <p className={styles.po_container_title}>Реквизиты для перечисления ЗП</p>
                                    <CurrentData bank_name={oldPOData?.bank_name} bank_acc={oldPOData?.bank_acc} />
                                    <NewDataPO load={loadBtn} status={oldPOData?.app_active} saveFileNewData={saveFileNewData} showScreenNewData={showScreenAddNewData} />
                                    {ntfError && (
                                        <Notification type="err" icon="exclamation-mark">
                                            Ошибка отправки файла. Попробуйте ввести данные вручную.
                                        </Notification>
                                    )}
                                </div>
                            </>
                        )
                    ) : (
                        <>
                            <Navigation prev={prev} goToMain={goToMain}></Navigation>
                            <EditDataPO clearError={clearError} errors={error} load={loadBtn} saveNewData={saveNewData} zypError={zypError} />
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default PODetails;
