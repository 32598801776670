import React, { ReactElement, useContext, createContext, useEffect, useState } from "react";
import styles from "../index.module.scss";
import style from "./index.module.scss";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../components/Navigation";
import SlideScreen from "../../../components/SlideScreen";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import { InputRadio } from "../../../components/Inputs";
import Spravki from "./componets/ListInquiries";
import Listok from "./../../../icons/listok2.svg";
import Info from "./../../../icons/info.svg";
import { InputCalendar } from "../../../components/Inputs";
import Mail from "./../../../icons/mail-check-large.svg";
import Spravki2 from "./componets/Inqurie";
import { InputNumIMask } from "../../../components/Inputs";
import AddressListSpravka from "./componets/AddressListSpravka";
export const Spravka = createContext<{
    editInform: any;
    informats: any;
}>({
    editInform: () => null,
    informats: {},
});

const Inquiries = (): ReactElement => {
    const [screens, setScreen] = useState(0);
    const [load, setLoad] = useState(false);
    const [loadStart, setLoadStart] = useState(true);
    const [userData, setData] = useState({
        monthStart: "",
        monthEnd: "",
        type: "Бумажный",
        yearStart: "",
        yearEnd: "",
        kolvo: 0,
        adress: false,
        ID: "",
    });
    const [currentAddress, setCurrentAddress] = useState<any>({});
    const [viewSlide, setViewSlide] = useState(true);

    const [error, setError] = useState({
        Error: "",
    });
    const [err, setErr] = useState("");
    const [screenButton, setscreenButton] = useState(true);
    const visinlescreenButton = () => {
        setscreenButton(false);
    };
    const visinlescreenButtonHidden = () => {
        setscreenButton(true);
    };
    const [isContentVisible1, setContentVisible1] = useState(true);
    const [isContentVisible2, setContentVisible2] = useState(false);
    const [isContentVisible3, setContentVisible3] = useState(true);
    const handleTextClick1 = () => {
        setData({
            ...userData,
            type: "Бумажный",
        });
        setContentVisible1(true);
        setContentVisible2(false);
        setContentVisible3(false);
        // setInputCount("");
    };
    const handleTextClick2 = () => {
        setData({
            ...userData,
            type: "Электронный",
        });
        setContentVisible1(false);
        setContentVisible2(true);
        setContentVisible3(false);
    };
    const editData = (key, element) => {
        setData((prevState) => ({
            ...prevState,
            [key]: element,
        }));
    };
    const editInform = (element) => {
        setInformats({
            ...element,
        });
    };
    const [informats, setInformats] = useState<any>({});

    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        if (screens === 0) {
            navigate("/");
            setInputCount("");
        } else if (screens === 1) {
            setScreen(screens - 1);
            setLoadStart(false);
            setLoad(false);
            // setContentVisible1(false);
            // setContentVisible2(false);
            // setContentVisible3(true);
            setscreenButton(true);
            setCurrentAddress({});
            setInputCount("");
            setData({
                ...userData,
                monthEnd: "",
                monthStart: "",
                yearStart: "",
                yearEnd: "",
            });
        } else {
            setScreen(screens - 1);
            setLoadStart(false);
            setLoad(false);
            // setContentVisible1(false);
            // setContentVisible2(false);
            // setContentVisible3(true);
            setscreenButton(true);
            setCurrentAddress({});
        }
    };
    const prev2 = () => {
        if (screens === 0) {
            navigate("/");
        } else if (screens === 1) {
            setScreen(screens - 2);
            setLoadStart(false);
            setLoad(false);
            // setContentVisible1(false);
            // setContentVisible2(false);
            // setContentVisible3(true);
            setscreenButton(true);
            setCurrentAddress({});
            setErr("");
            setData({
                ...userData,
                monthEnd: "",
                monthStart: "",
                yearStart: "",
                yearEnd: "",
            });
        } else {
            setScreen(screens - 2);
            setLoadStart(false);
            setLoad(false);
            // setContentVisible1(false);
            // setContentVisible2(false);
            // setContentVisible3(true);
            setscreenButton(true);
            setCurrentAddress({});
            setErr("");
        }
    };
    const prevGL = () => {
        navigate("/");
    };
    const goToMain = () => {
        prevGL();
        context.goToMain();
        setCurrentAddress({});
    };
    const next = () => {
        if (screens === 0) {
            setLoadStart(false);
            setRadioButtonstate("Бумажный");
            setScreen(screens + 1);
            setContentVisible1(true);
            setContentVisible2(false);
            // setContentVisible3(false);
        }
        if (screens < 4) {
            setLoadStart(false);
            setScreen(screens + 1);
        } else {
            setScreen(0);
            setLoad(false);
        }
    };
    const nextbegining = () => {
        if (screens < 4) {
            setLoadStart(false);
            setScreen(screens + 1);
        } else {
            // setContentVisible1(false);
            // setContentVisible2(false);
            // setContentVisible3(true);
            setscreenButton(true);
            setScreen(0);
            setLoad(false);
            setErr("");
            setInputCount("");
        }
    };
    const next2 = () => {
        if (screens < 4) {
            setLoadStart(false);
            setScreen(screens + 2);
        } else {
            setScreen(0);
        }
    };
    const sp_vid = "";
    const [radioButtonstate, setRadioButtonstate] = useState("Бумажный");

    const radioclick = (name) => {
        setRadioButtonstate("Бумажный");
    };

    const radioclick2 = (name) => {
        setRadioButtonstate("Электронный");
    };

    const [jsonData, setJsonData] = useState<Array<any>>([]);
    const [dann, setDann] = useState<Array<any>>([]);

    useEffect(() => {
        if (loadStart) {
            setLoadStart(true);
            fetch(`/dev/vacation_spravki_new?sp_vid=${sp_vid}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res == true) {
                        // Получены данные
                        console.log(json);
                        setJsonData(json.VidySpravok);
                        setDann(json.VidySpravok.Name);
                        setLoadStart(false);
                    } else {
                        // Данные не получены
                        console.log(json);
                        const json_example = {
                            res: false,
                        };
                        setLoadStart(false);
                        // Может быть, только если не доступна ИБ HRM
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
        }
    });
    const templateYear = Number(`${new Date().getFullYear()}`);
    const templateMonth = Number(`${new Date().getMonth()}`);
    const templateDay = Number(`${new Date().getDate()}`);

    const Approve = () => {
        const mesStart = userData.monthStart.replace(".", ".20");
        const mesEnd = userData.monthEnd.replace(".", ".20");
        const godStart = userData.yearStart.replace("01.01.", "20");
        const godEnd = userData.yearEnd.replace("01.01.", "20");
        setLoad(true);
        fetch("/dev/vacation_spravki_new", {
            method: "POST",
            body: JSON.stringify({
                YearStart: godStart || "",
                // YearStart: godStart || "",
                YearEnd: godStart || "",
                MonthStart: mesStart || "",
                MonthEnd: mesEnd || "",
                VidSpravki: informats?.VidySpravok?.[0]?.ID || "",
                MestoPol: currentAddress.ID || "",
                KolvoEkz: inputCount || 0,
                TipDok: userData.type,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    // Заявление сформировано
                    console.log(json);
                    next();
                    setData({
                        ...userData,
                        monthEnd: "",
                        monthStart: "",
                        yearStart: "",
                        yearEnd: "",
                    });
                } else {
                    // Заявление не сформировано
                    setLoad(false);
                    setErr(json.Error);
                    console.log(json);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };
    const [inputCount, setInputCount] = useState("");

    console.log(informats);

    return (
        <Spravka.Provider
            value={{
                editInform,
                informats,
            }}
        >
            <>
                {screens === 0 ? (
                    <>
                        {loadStart ? <Loader /> : null}
                        <Navigation prev={prev} goToMain={goToMain}></Navigation>
                        <div className={styles.doc}>
                            <p className={styles.doc_title}>Какую справку хотите заказать?</p>
                            <div style={{ marginTop: "16px" }} />
                            <div className={styles.list}>
                                <Spravki load={load} setData={setData} setLoad={setLoad} next={next} data={jsonData}></Spravki>
                            </div>
                        </div>
                    </>
                ) : null}
                {screens === 1 ? (
                    <>
                        {load ? <Loader /> : null}
                        <Navigation prev={prev} goToMain={goToMain}></Navigation>
                        <div className={styles.doc}>
                            <p className={style.title}>Информация для справки</p>
                            <div className={style.menu}>
                                <div className={style.menu_listok}>
                                    <Listok />
                                </div>
                                <div className={style.block}>
                                    <Spravki2 userData={userData} setData={setData} next={next} data={informats}></Spravki2>
                                </div>
                            </div>
                            {informats.TipPerioda === "PoMesyacam" && informats?.VidySpravok?.[0]?.ID !== "df3abe60-af16-11e7-80c3-5cf3fcba9afe" ? (
                                <div className={style.flex}>
                                    <div className={style.flex_calendar}>
                                        <div className={style.flex_calendar_title}>Начало периода</div>
                                        <InputCalendar
                                            editData={editData}
                                            errorMessage={error.Error}
                                            name="monthStart"
                                            type="dropdown"
                                            maxDetail="year"
                                            minDetail="year"
                                            // titleText="начало периода"
                                            titleTextSlide="Введите месяц и год"
                                            key="InpCalendarDismissal"
                                            value={userData.monthStart.replace(".", ".20")}
                                            icon="Calendar-icon"
                                            placeholder="ММ.ГГГГ"
                                            maxDate={new Date(templateYear, 11, 31)}
                                            minDate={new Date(1999, 12, 31)}
                                        />
                                    </div>
                                    <div className={style.flex_calendar}>
                                        <div className={style.flex_calendar_title}>Конец периода</div>
                                        <InputCalendar
                                            editData={editData}
                                            errorMessage={error.Error}
                                            type="dropdown"
                                            maxDetail="year"
                                            minDetail="year"
                                            // titleText="окончание периода"
                                            titleTextSlide="Введите месяц и год"
                                            key="InpCalendarDismissal"
                                            value={userData.monthEnd.replace(".", ".20")}
                                            name="monthEnd"
                                            icon="Calendar-icon"
                                            placeholder="ММ.ГГГГ"
                                            maxDate={new Date(templateYear, 11, 31)}
                                            minDate={new Date(1999, 12, 31)}
                                        />
                                    </div>
                                </div>
                            ) : null}
                            {informats.TipPerioda === "PoMesyacam" && informats?.VidySpravok?.[0]?.ID == "df3abe60-af16-11e7-80c3-5cf3fcba9afe" ? (
                                <div className={style.flex}>
                                    <div className={style.flex_calendar}>
                                        <div className={style.flex_calendar_title}>Начало периода</div>
                                        <InputCalendar
                                            editData={editData}
                                            errorMessage={error.Error}
                                            name="monthStart"
                                            type="dropdown"
                                            maxDetail="year"
                                            minDetail="year"
                                            // titleText="начало периода"
                                            titleTextSlide="Введите месяц и год"
                                            key="InpCalendarDismissal"
                                            value={userData.monthStart.replace(".", ".20")}
                                            icon="Calendar-icon"
                                            placeholder="ММ.ГГГГ"
                                            maxDate={new Date(templateYear, templateMonth - 1, templateDay - 15)}
                                            minDate={new Date(1999, 12, 31)}
                                        />
                                    </div>
                                    <div className={style.flex_calendar}>
                                        <div className={style.flex_calendar_title}>Конец периода</div>
                                        <InputCalendar
                                            editData={editData}
                                            errorMessage={error.Error}
                                            type="dropdown"
                                            maxDetail="year"
                                            minDetail="year"
                                            // titleText="окончание периода"
                                            titleTextSlide="Введите месяц и год"
                                            key="InpCalendarDismissal"
                                            value={userData.monthEnd.replace(".", ".20")}
                                            name="monthEnd"
                                            icon="Calendar-icon"
                                            placeholder="ММ.ГГГГ"
                                            maxDate={new Date(templateYear, templateMonth - 1, templateDay - 15)}
                                            minDate={new Date(1999, 12, 31)}
                                        />
                                    </div>
                                </div>
                            ) : null}
                            {informats.TipPerioda === "PoGodam" ? (
                                <div className={style.flex}>
                                    <div className={style.flex_calendar}>
                                        <InputCalendar
                                            editData={editData}
                                            errorMessage={error.Error}
                                            type="dropdown"
                                            maxDetail="decade"
                                            minDetail="decade"
                                            titleText="Период"
                                            titleTextSlide="Введите год"
                                            key="InpCalendarDismissal"
                                            value={userData.yearStart.replace("01.01.", "20")}
                                            name="yearStart"
                                            icon="Calendar-icon"
                                            placeholder="ГГГГ"
                                            maxDate={new Date(templateYear, templateMonth, templateDay)}
                                            minDate={new Date(1999, 12, 1)}
                                        />
                                    </div>
                                    {/* <div className={style.flex_calendar}>
                                        <InputCalendar
                                            editData={editData}
                                            errorMessage={error.Error}
                                            type="dropdown"
                                            maxDetail="decade"
                                            minDetail="decade"
                                            titleText="Введите год"
                                            titleTextSlide="Введите год"
                                            key="InpCalendarDismissal"
                                            value={userData.yearEnd.replace("01.01.", "20")}
                                            name="yearEnd"
                                            icon="Calendar-icon"
                                            placeholder="ГГГГ"
                                            maxDate={new Date(templateYear, templateMonth, templateDay)}
                                            minDate={new Date(1999, 12, 1)}
                                        />
                                    </div> */}
                                </div>
                            ) : null}
                            {informats.TipPerioda === "BezPerioda" ? (
                                <div className={style.flex}>
                                    <div className={style.flex_calendar}></div>
                                    <div className={style.flex_calendar}></div>
                                </div>
                            ) : null}

                            <p className={style.title}>Тип документа</p>
                            <div className={style.choose}>
                                <div className={style.choose_question} onClick={handleTextClick1}>
                                    <InputRadio checked={radioButtonstate === "Бумажный"} onClick={() => radioclick("Бумажный")} secondTitle="Бумажный" id_key="sam" name="why" />
                                </div>
                                {context?.userdata?.edo_aval1 &&
                                (informats?.VidySpravok?.[0]?.ID == "b25f052d-af14-11e7-80c3-5cf3fcba9afe" ||
                                    informats?.VidySpravok?.[0]?.ID == "b966b6c3-da19-11ee-827c-00155d1b9306" ||
                                    informats?.VidySpravok?.[0]?.ID == "ebd050b8-4fa1-4a94-995e-30ab9f1db443") ? (
                                    <div className={styles.choose_question} onClick={handleTextClick2}>
                                        <InputRadio
                                            checked={radioButtonstate === "Электронный"}
                                            onClick={() => radioclick2("Электронный")}
                                            secondTitle="Электронный"
                                            id_key="nesam"
                                            name="why"
                                        />
                                    </div>
                                ) : null}
                            </div>
                            {isContentVisible1 && (
                                <div className={style.title}>
                                    <p className={style.title}>Количество экземпляров</p>
                                    {/* <InputNum type={"num"} placeholder="1" /> */}
                                    <InputNumIMask
                                        value={inputCount}
                                        onChange={(event) => setInputCount(event.target.value)}
                                        placeholder="Введите кол-во"
                                        type="num"
                                        onFocus={() => setViewSlide(false)}
                                        onBlur={() => setViewSlide(true)}
                                    />
                                </div>
                            )}
                            {isContentVisible2 && (
                                <div className={style.info}>
                                    <div className={style.info_img}>
                                        <Info />
                                    </div>
                                    <span className={style.info_title}>Как только справка сформируется вы увидите ее на портале НR-link</span>
                                </div>
                            )}
                            {isContentVisible1 && informats.TipPerioda == "PoGodam" ? (
                                <>
                                    {viewSlide ? (
                                        <SlideScreen altitudeLevel="service">
                                            <Button type="save_not_margin" disabled={inputCount == "" || inputCount == "0" || userData.yearStart == ""} onClick={next}>
                                                Далее
                                            </Button>
                                        </SlideScreen>
                                    ) : null}
                                </>
                            ) : null}
                            {isContentVisible1 && informats.TipPerioda == "PoMesyacam" ? (
                                <>
                                    {viewSlide ? (
                                        <SlideScreen altitudeLevel="service">
                                            <Button
                                                type="save_not_margin"
                                                disabled={inputCount == "" || inputCount == "0" || userData.monthStart == "" || userData.monthEnd == ""}
                                                onClick={next}
                                            >
                                                Далее
                                            </Button>
                                        </SlideScreen>
                                    ) : null}
                                </>
                            ) : null}
                            {isContentVisible2 && informats.TipPerioda == "PoGodam" ? (
                                <>
                                    {viewSlide ? (
                                        <SlideScreen altitudeLevel="service">
                                            <Button type="save_not_margin" disabled={userData.yearStart == ""} onClick={next2}>
                                                Далее
                                            </Button>
                                        </SlideScreen>
                                    ) : null}
                                </>
                            ) : null}
                            {isContentVisible2 && informats.TipPerioda == "PoMesyacam" ? (
                                <>
                                    {viewSlide ? (
                                        <SlideScreen altitudeLevel="service">
                                            <Button type="save_not_margin" disabled={userData.monthStart == "" || userData.monthEnd == ""} onClick={next2}>
                                                Далее
                                            </Button>
                                        </SlideScreen>
                                    ) : null}
                                </>
                            ) : null}
                            {isContentVisible1 && informats.TipPerioda == "BezPerioda" ? (
                                <>
                                    {viewSlide ? (
                                        <SlideScreen altitudeLevel="service">
                                            <Button type="save_not_margin" disabled={inputCount == "" || inputCount == "0"} onClick={next}>
                                                Далее
                                            </Button>
                                        </SlideScreen>
                                    ) : null}
                                </>
                            ) : null}
                            {isContentVisible2 && informats.TipPerioda == "BezPerioda" ? (
                                <>
                                    {viewSlide ? (
                                        <SlideScreen altitudeLevel="service">
                                            <Button type="save_not_margin" onClick={next2}>
                                                Далее
                                            </Button>
                                        </SlideScreen>
                                    ) : null}
                                </>
                            ) : null}
                            {/* {isContentVisible3 ? (
                                <SlideScreen altitudeLevel="service">
                                    <Button type="save_not_margin" disabled>
                                        Далее
                                    </Button>
                                </SlideScreen>
                            ) : null} */}
                        </div>
                    </>
                ) : null}
                {screens === 2 ? (
                    <>
                        {load ? <Loader /> : null}
                        <Navigation prev={prev} goToMain={goToMain}></Navigation>
                        <div className={styles.doc}>
                            <p className={styles.doc_title}>Информация для справки</p>
                            <p className={styles.doc_title_second}>Адрес получения</p>
                            <p className={style.infoplace}>Выбери место получения из списка ниже</p>
                            {/* <AddressList name="stores" errorMessage={error?.Error} selectedStore={prev} editData={editData} items={informats.MestaPolucheniya} setPrev={setPrev} /> */}
                            <div onClick={visinlescreenButton}>
                                <AddressListSpravka
                                    name="stores"
                                    errorMessage={error?.Error}
                                    selectedStore={currentAddress}
                                    editData={editData}
                                    items={informats.MestaPolucheniya}
                                    setPrev={setCurrentAddress}
                                />
                            </div>
                            {/* <Adress next={next} data={informats} /> */}
                            {/* {screenButton && ( */}
                            <SlideScreen altitudeLevel="service">
                                <Button type="save_not_margin" disabled={screenButton} onClick={next}>
                                    Далее
                                </Button>
                            </SlideScreen>
                            {/* )} */}
                        </div>
                    </>
                ) : null}
                {screens === 3 ? (
                    <>
                        {load ? <Loader /> : null}
                        <Navigation prev={prev2} goToMain={goToMain}></Navigation>
                        <div className={styles.doc}>
                            <p className={styles.doc_title}>Информация для справки</p>
                            <div className={style.error}>{err}</div>
                            <div className={style.info}>
                                <div className={style.info_img}>
                                    <Info />
                                </div>
                                <div className={style.info_title}>Проверьте введённые данные и отправьте заявку.</div>
                            </div>
                            <div className={style.dannieproverka}>
                                <p className={style.zagolovok}>Тип справки</p>
                                <div className={style.text}>
                                    <Spravki2 result next={next} data={informats}></Spravki2>
                                </div>
                                {informats.TipPerioda == "PoGodam" ? (
                                    <>
                                        <p className={style.zagolovok}>Период</p>
                                        <div className={style.text}>{userData?.yearStart.replace("01.01.", "20")}</div>
                                    </>
                                ) : null}
                                {informats.TipPerioda == "PoMesyacam" ? (
                                    <>
                                        {userData?.monthStart == userData?.monthEnd ? (
                                            <>
                                                <p className={style.zagolovok}>Выбранный месяц</p>
                                                <div className={style.text}>{userData?.monthStart.replace(".", ".20")}</div>
                                            </>
                                        ) : (
                                            <>
                                                <p className={style.zagolovok}>Выбранный Период</p>
                                                <div className={style.text}>
                                                    C {userData?.monthStart.replace(".", ".20")} по {userData?.monthEnd.replace(".", ".20")}
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : null}
                                <p className={style.zagolovok}>Тип документа</p>
                                {isContentVisible1 && (
                                    <>
                                        <p className={style.text}>Бумажный</p>
                                    </>
                                )}
                                {isContentVisible2 && (
                                    <>
                                        <p className={style.text}>Электронный</p>
                                    </>
                                )}
                                {isContentVisible1 && (
                                    <>
                                        <p className={style.zagolovok}>Количество экземпляров</p>
                                        <p className={style.text}>{inputCount}</p>
                                        <p className={style.zagolovok}>Адрес получения</p>
                                        <div className={style.text}>
                                            {currentAddress.Name}
                                            {/* <Adress next={next} data={currentAddress} /> */}
                                        </div>
                                    </>
                                )}
                            </div>
                            <SlideScreen altitudeLevel="service">
                                <Button type="save_not_margin" onClick={Approve}>
                                    Подтвердить
                                </Button>
                                <Button type="cancel_white" onClick={prev2}>
                                    Изменить данные
                                </Button>
                            </SlideScreen>
                        </div>
                    </>
                ) : null}
                {screens === 4 ? (
                    <>
                        {/* {load ? <Loader /> : null} */}
                        {/* <Navigation prev={prev} goToMain={goToMain}></Navigation> */}
                        <div className={styles.doc}>
                            <div className={style.final}>
                                <Mail />
                                <div className={style.final_zagolovok}>Заявка успешно сформирована</div>
                                {/* <div className={style.final_text}>
                                    Повседневная практика показывает, что новая модель организационной деятельности играет важную роль в формировании направлений прогрессивного
                                    развития.
                                </div> */}
                            </div>
                            <SlideScreen altitudeLevel="service">
                                <Button type="save_not_margin" onClick={nextbegining}>
                                    Запросить еще справку
                                </Button>
                                <Button type="cancel_white" onClick={() => navigate("/requestsList")}>
                                    Мои заявления
                                </Button>
                            </SlideScreen>
                        </div>
                    </>
                ) : null}
            </>
        </Spravka.Provider>
    );
};

export default Inquiries;
