import React, { ReactElement, useContext, useState } from "react";
import styles from "./index.module.scss";
import Navigation from "../../../../components/Navigation";
import { NavigationContext } from "../../../../template";
import { useNavigate } from "react-router";
import Listok from "./../../../../icons/listok2.svg";
import Loader from "../../../../components/Loader";
import { Spravka } from "..";
const MainItem = (props): ReactElement => {
    const spravka = useContext(Spravka);
    const open = () => {
        edit();
        props.setLoad(true);
        props.next();
    };
    const edit = () => {
        props.setLoad(true);
        fetch(`/dev/vacation_spravki_new?sp_vid=${props.current.ID}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    // Получены данные
                    console.log(json);
                    props.setLoad(false);
                    spravka.editInform(json);
                } else {
                    // Данные не получены
                    console.log(json);
                    const json_example = {
                        res: false,
                    };
                    props.setLoad(false);
                    // Может быть, только если не доступна ИБ HRM
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };
    return (
        <>
            {props.load ? <Loader /> : null}
            <div onClick={open} className={styles.menu}>
                <div className={styles.menu_listok}>
                    <Listok />
                </div>
                <div className={styles.block}>{props.current.Name}</div>
            </div>
        </>
    );
};

export default MainItem;
