import React, { ReactElement, useState } from "react";
import styles from "../index.module.scss";
import { IInputCheckBox } from "../../../typec/IInputs";
import clsx from "clsx";

const InputCheckBox = (props: IInputCheckBox): ReactElement => {
    const { titleText, editData, onClick = () => null, checked, style, reverse = false, children } = props;
    const [checkedLC, setCheck] = useState(checked ? checked : false);

    const check = () => {
        setCheck(!checkedLC);
        editData && editData();
        onClick();
    };

    return (
        <div style={style}>
            <label className={clsx(styles["container"], reverse && styles["container_reverse"])}>
                <input type="checkbox" onClick={() => check()} checked={checkedLC} />
                <span className={clsx(styles["checkmark"], reverse && styles["checkmark_reverse"])}></span>
                {titleText}
                {children}
            </label>
        </div>
    );
};

export { InputCheckBox };
