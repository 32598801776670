import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router";

const Cancel = (props) => {
    const [load, setLoad] = useState(false);
    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
        context.prev();
    };
    const goToMain = () => {
        prev();
        navigate("/");
        context.goToMain();
    };
    const CancelApplication = (id_app, comment = "") => {
        setLoad(true);
        fetch("/dev/vacation_cancel_new", {
            method: "POST",
            body: JSON.stringify({
                IDApp: props.current.IDApp,
                Comment: comment,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    // Отменено
                    console.log(json);
                    const json_example = {
                        res: true,
                    };
                    setLoad(false);
                    goToMain();
                    props.setInfo(false);
                } else {
                    // Не отменено
                    console.log(json);
                    const json_example = {
                        res: false,
                        Error: "Какая-то ошибка...",
                    };
                    setLoad(false);
                    goToMain();
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };
    console.log(props.current.IDApp);
    return (
        <>
            {load ? (
                <Loader></Loader>
            ) : (
                <>
                    <div onClick={CancelApplication} className={styles.buttonBlock}>
                        <Button type="cancel_red">Отменить</Button>
                    </div>
                </>
            )}
        </>
    );
};

export default Cancel;
