import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "../index.module.scss";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../components/Navigation";
import { VacOfSubItemDep, VacOfSubItemName } from "../componets/VacOfSubItem";
import Loader from "../../../components/Loader";
import clsx from "clsx";
import SlideScreen from "../../../components/SlideScreen";
import Button from "../../../components/Button";
import { InputCalendar, InputError } from "../../../components/Inputs";
import { ISubOfVacationName, IVacOfSubordinates } from "../../../typec/IDocumentRequest";
import Notification from "../../../components/Notification";

const VacationOfSubordinates = (): ReactElement => {
    const [load, setLoad] = useState(true);
    const [loadBtn, setLoadBtn] = useState(false);
    const [loadBtnDown, setLoadBtnDown] = useState(false);
    const [isDepartment, setDepartment] = useState({
        actual: true,
        dep: false,
        fio: false,
    });
    const [vacData, setData] = useState<IVacOfSubordinates>();
    const [vacDataName, setDataName] = useState<ISubOfVacationName>();
    const [isName, setIsName] = useState(false);
    const [vacDataDep, setDataDep] = useState<ISubOfVacationName>();
    const [isDep, setIsDep] = useState(false);
    const [imgData, setImgData] = useState({
        img: "",
        start: "",
        konets: "",
    });
    const [isImg, setIsImg] = useState({
        show: false,
        img: false,
    });
    const [error, setError] = useState("");
    const clearError = () => {
        setError("");
    };

    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        if (isImg.img) {
            setIsImg({
                img: false,
                show: false,
            });
        }
        if (isName) {
            setIsName(false);
        }
        if (isDep && !isImg.img) {
            setIsDep(false);
        }
        if (!isName && !isDep) navigate("/");
    };
    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_subordinates_new", {
                method: "POST",
                body: JSON.stringify({
                    Year: `${new Date().getFullYear()}`,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res == true) {
                        setLoad(false);
                        setData(json);
                    } else {
                        setLoad(false);
                    }
                });
        }
    });
    const getDate = (el) => {
        const year = el.split(".")[2].replace("2", "202");
        const month = el.split(".")[1];
        const day = el.split(".")[0];
        return new Date(Number(year), Number(month) - 1, Number(day)).getTime();
    };

    // функция для формирования графика наложение отпусков
    const putVacOfSub = (nameDep, start, konets) => {
        if (getDate(start) <= getDate(konets)) {
            setLoadBtn(true);
            fetch("/dev/vacation_subordinates_new", {
                method: "PUT",
                body: JSON.stringify({
                    StartDate: start.replace(".2", ".202"),
                    KonetsDate: konets.replace(".2", ".202"),
                    PodrazdeleniyeName: nameDep,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res == true) {
                        document.body.style.overflow = "auto";
                        setLoadBtn(false);
                        setIsImg({
                            img: true,
                            show: false,
                        });
                        setImgData({
                            img: json.OverlaysContent,
                            start: start,
                            konets: konets,
                        });
                        console.log(json);
                    } else {
                        setLoadBtn(false);
                        setError(json?.exception && "Не найдено ни одного отпуска за данный период");
                    }
                });
        } else setError("Дата начала не может быть позднее даты конца периода");
    };

    // функция для скачивания графика наложение отпусков
    const putDownVac = (nameDep, start, konets) => {
        setLoadBtnDown(true);
        fetch("/dev/vacation_subordinates_new", {
            method: "PUT",
            body: JSON.stringify({
                StartDate: start.replace(".2", ".202"),
                KonetsDate: konets.replace(".2", ".202"),
                PodrazdeleniyeName: nameDep,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    setLoadBtnDown(false);
                    document.body.style.overflow = "auto";
                    setIsImg({
                        img: false,
                        show: false,
                    });
                    setImgData({
                        img: json.OverlaysContent,
                        start: start,
                        konets: konets,
                    });
                    downloadAsFile(json.OverlaysContent, json.OverlaysName);
                } else {
                    setLoadBtnDown(false);
                }
            });
    };

    const downloadAsFile = (data, name) => {
        const a = document.createElement("a");
        a.href = data;
        a.download = name;
        a.click();
    };

    const setClickInfoName = (name) => {
        setDataName(vacData?.Vacations?.filter((el) => el.Name == name));
        setIsName(true);
    };
    const setClickInfoDep = (nameDep) => {
        setDataDep(vacData?.Vacations?.filter((el) => el.PodrazdeleniyeName == nameDep));
        setIsDep(true);
    };
    const getActualVacations = (vacData) => {
        return vacData?.Vacations.filter((el) => {
            const now = new Date().getTime();
            if (getDateTime(el.KonetsDate) >= now && getDateTime(el.StartDate) <= now) return true;
            else return false;
        });
    };
    const getBoolActivVacation = (start, finish) => {
        const now = new Date().getTime();
        return getDateTime(start) <= now && getDateTime(finish) >= now ? true : false;
    };
    const getDateTime = (str) => {
        return new Date(Number(str.split(".")[2]), Number(str.split(".")[1]) - 1, Number(str.split(".")[0])).getTime();
    };

    const viewOverlays = () => {
        setIsImg({
            show: true,
            img: false,
        });
    };

    const editDataImg = (element) => {
        setImgData({
            ...imgData,
            ...element,
        });
    };

    const closeEdit = () => {
        setIsImg({ img: false, show: false });
    };

    useEffect(() => {
        if (isImg.show) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isImg.show]);

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    {isName && (
                        <div className={styles.doc}>
                            <p className={styles.doc_title}>{vacDataName && vacDataName[0]?.Name}</p>
                            {vacDataName?.map((el, index) => (
                                <VacOfSubItemName
                                    key={`VacOfSubItemName_2_${index}`}
                                    typeVac={el.TypeVacationName}
                                    konetsDays={el.KonetsDate}
                                    numberDays={String(el.NumberDays)}
                                    startDays={el.StartDate}
                                    isActual={el.IsActual}
                                    isActiv={getBoolActivVacation(el.StartDate, el.KonetsDate)}
                                />
                            ))}
                        </div>
                    )}
                    {isDep && !isName && (
                        <div className={styles.doc}>
                            {isImg.img ? (
                                <>
                                    <p className={styles.doc_title}>{vacDataDep && vacDataDep[0].PodrazdeleniyeName}</p>
                                    <div className={styles.doc_img}>
                                        <img src={imgData.img} alt="" />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p className={styles.doc_title}>{vacDataDep && vacDataDep[0].PodrazdeleniyeName}</p>
                                    {vacDataDep?.map((el, index) => (
                                        <VacOfSubItemName
                                            key={`VacOfSubItemName_2_${index}`}
                                            name={el.Name}
                                            typeVac={el.TypeVacationName}
                                            konetsDays={el.KonetsDate}
                                            numberDays={String(el.NumberDays)}
                                            startDays={el.StartDate}
                                            isActual={el.IsActual}
                                            isActiv={getBoolActivVacation(el.StartDate, el.KonetsDate)}
                                        />
                                    ))}
                                    {isImg.show ? (
                                        <SlideScreen titleText="Выберите даты" altitudeLevel="calendar" onClose={() => closeEdit()}>
                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "410px" }}>
                                                <div>
                                                    <InputCalendar
                                                        editVacation={editDataImg}
                                                        name="start"
                                                        value={imgData.start}
                                                        clearError={clearError}
                                                        dateNow={false}
                                                        type="dropdown_small"
                                                        titleText="Начальная дата"
                                                        titleTextSlide="Начальная дата"
                                                        placeholder="ДД.ММ.ГГ"
                                                        style={{ marginTop: "24px" }}
                                                    />
                                                    <InputCalendar
                                                        editVacation={editDataImg}
                                                        name="konets"
                                                        value={imgData.konets}
                                                        clearError={clearError}
                                                        dateNow={false}
                                                        type="dropdown_small"
                                                        titleText="Конечная дата"
                                                        titleTextSlide="Конечная дата"
                                                        placeholder="ДД.ММ.ГГ"
                                                    />
                                                </div>
                                                <div>
                                                    <InputError titleText={error} />
                                                    <Button
                                                        load={loadBtn}
                                                        onClick={() => putVacOfSub(vacDataDep && vacDataDep[0].PodrazdeleniyeName, imgData.start, imgData.konets)}
                                                        type="save"
                                                    >
                                                        Сформировать
                                                    </Button>
                                                    <Button
                                                        load={loadBtnDown}
                                                        onClick={() => putDownVac(vacDataDep && vacDataDep[0].PodrazdeleniyeName, imgData.start, imgData.konets)}
                                                        type="cancel_grey"
                                                    >
                                                        Сформировать и скачать
                                                    </Button>
                                                </div>
                                            </div>
                                        </SlideScreen>
                                    ) : (
                                        <SlideScreen altitudeLevel="service">
                                            <Button load={loadBtn} onClick={() => viewOverlays()} type="save_not_margin">
                                                Наложения отпусков
                                            </Button>
                                        </SlideScreen>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                    {!isDep && !isName && (
                        <div className={styles.doc}>
                            <p className={styles.doc_title}>Отпуска подчиненных</p>
                            <div className={styles.doc_title_container}>
                                <span
                                    onClick={() => setDepartment({ actual: true, dep: false, fio: false })}
                                    className={clsx(styles["doc_title_filter"], isDepartment.actual && styles["doc_title_filter_activ"])}
                                >
                                    В отпуске
                                </span>
                                <span
                                    onClick={() => setDepartment({ actual: false, dep: true, fio: false })}
                                    className={clsx(styles["doc_title_filter"], isDepartment.dep && styles["doc_title_filter_activ"])}
                                >
                                    Отдел
                                </span>
                                <span
                                    onClick={() => setDepartment({ actual: false, dep: false, fio: true })}
                                    className={clsx(styles["doc_title_filter"], isDepartment.fio && styles["doc_title_filter_activ"])}
                                >
                                    ФИО
                                </span>
                            </div>
                            {isDepartment.dep ? (
                                <>
                                    {vacData?.Subdivisions?.map((el, index) => (
                                        <VacOfSubItemDep onClick={setClickInfoDep} key={`VacOfSubItemDep_${index}`} nameDep={el} />
                                    ))}
                                </>
                            ) : isDepartment.fio ? (
                                <>
                                    {vacData?.Names?.map((el, index) => (
                                        <VacOfSubItemName onClick={setClickInfoName} key={`VacOfSubItemName_${index}`} name={el} />
                                    ))}
                                </>
                            ) : isDepartment.actual ? (
                                <>
                                    {getActualVacations(vacData).length > 0 ? (
                                        <>
                                            {getActualVacations(vacData)?.map((el, index) => (
                                                <VacOfSubItemName
                                                    key={`VacOfSubItemName_3_${index}`}
                                                    name={el.Name}
                                                    typeVac={el.TypeVacationName}
                                                    konetsDays={el.KonetsDate}
                                                    numberDays={String(el.NumberDays)}
                                                    startDays={el.StartDate}
                                                    isActual={el.IsActual}
                                                />
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            <Notification type="info" icon="info">
                                                Все ваши подчиненные работают
                                            </Notification>
                                        </>
                                    )}
                                </>
                            ) : null}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default VacationOfSubordinates;
