import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import { InputText, InputNumIMask, InputCalendar } from "../../../../../../components/Inputs";
import { IVacationEditinput } from "../../../../../../typec/IVacations";

const VacationInputItem = (props: IVacationEditinput): ReactElement => {
    const { typeEditInput, titleText, defaultValueInput, clearError, editVacation = () => null, error, disable, dateNow, style, maxDate, minDate } = props;

    return (
        <div className={styles.edit_item} style={style}>
            {typeEditInput === "text" ? (
                <InputText
                    clearError={clearError}
                    editVacation={editVacation}
                    titleText={titleText}
                    type="fullString"
                    value={String(defaultValueInput)}
                    errorMessage={"" || ""}
                    disable={disable}
                />
            ) : typeEditInput === "num" ? (
                <InputNumIMask
                    type="num"
                    clearError={clearError}
                    editData={editVacation}
                    placeholder="Введите дни"
                    titleText={titleText}
                    value={String(defaultValueInput) == "0" ? "" : String(defaultValueInput)}
                    disable={disable}
                    errorMessage={
                        error.num === "ER_HRM01" ||
                        error.num === "ER_HRM02" ||
                        error.num === "ER_HRM23" ||
                        error.num === "ER_HRM24" ||
                        error.num === "ER_HRM03" ||
                        error.num === "ER_HRM08" ||
                        error.num === "ER_HRM13" ||
                        error.num === "ER_HRM14" ||
                        error.num === "ER_HRM15" ||
                        error.num === "ER_HRM21" ||
                        error.num === "ER_HRM22" ||
                        error.num === "ER_HRM33"
                            ? error.text
                            : ""
                    }
                />
            ) : (
                <InputCalendar
                    maxDate={maxDate}
                    minDate={minDate}
                    titleTextSlide={titleText}
                    titleText={titleText}
                    type="dropdown_small"
                    value={String(defaultValueInput)}
                    name="valueStartDay"
                    clearError={clearError}
                    editVacation={editVacation}
                    dateNow={dateNow}
                    disable={disable}
                    placeholder="ДД.ММ.ГГ"
                    errorMessage={
                        error.num === "ER_HRM12" ||
                        error.num === "ER_HRM03" ||
                        error.num === "ER_HRM13" ||
                        error.num === "ER_HRM14" ||
                        error.num === "ER_HRM15" ||
                        error.num === "ER_HRM21" ||
                        error.num === "ER_HRM22" ||
                        error.num === "ER_HRM24" ||
                        error.num === "ER_HRM26"
                            ? error.text
                            : ""
                    }
                />
            )}
        </div>
    );
};

export default VacationInputItem;
