import React, { ReactElement, useEffect, useState } from "react";
import styles from "../../index.module.scss";
import { InputError, InputRadio } from "../../../../components/Inputs";
import SlideScreen from "../../../../components/SlideScreen";
import Button from "../../../../components/Button";
import { IQuestionsList } from "../../../../typec/IDismissalStaff";

const QuestionsList = (props: IQuestionsList): ReactElement => {
    const { questions, postDismissal = () => null, addData, questionsData, error, clearError = () => null, load } = props;
    const [data, setData] = useState<object>(questionsData ? questionsData : []);

    const editQuestions = (key, element) => {
        setData((prevState) => {
            return {
                ...prevState,
                [key]: element,
            };
        });
        clearError();
    };

    const saveData = (key, element) => {
        addData(key, element);
        postDismissal();
    };

    const countObjProperties = (obj: object): number => {
        let res = 0;
        if (!isObjectEmpty(obj)) {
            res = Object.keys(obj).length;
        }
        return res;
    };
    const isObjectEmpty = (obj) => {
        return !Object.keys(obj).length;
    };

    return (
        <>
            <p className={styles.dis_text}>Пожалуйста, ответьте на вопросы от отдела кадров</p>
            {error?.Error && <InputError titleText={error.Error} />}
            {questions?.map((element, index) => (
                <>
                    <p className={styles.dis_text_item}>{element}</p>

                    <div className={styles.dis_questions}>
                        <InputRadio editKeyData={editQuestions} checked={data[element]} nameEditItem={element} name={`${index}`} id_key={`1_${index}`} secondTitle="Да" />
                        <InputRadio
                            editKeyData={editQuestions}
                            checked={data[element] != undefined ? !data[element] : false}
                            nameEditItem={element}
                            name={`${index}`}
                            id_key={`2_${index}`}
                            secondTitle="Нет"
                        />
                    </div>
                </>
            ))}

            <div className={styles.footer}>
                <SlideScreen altitudeLevel="service">
                    <Button load={load} onClick={() => saveData("questionsList", data)} disabled={!(questions?.length == countObjProperties(data))} type="save_not_margin">
                        Сформировать заявление
                    </Button>
                </SlideScreen>
            </div>
        </>
    );
};

export default QuestionsList;
