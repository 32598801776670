import React, { ReactElement } from "react";
import MainItem2 from "../ListIDName";

const Spravki2 = (props): ReactElement => {
    const { result = false } = props;
    // console.log(props.data.VidySpravok);
    return (
        <>
            {props.data?.VidySpravok?.length ? (
                <div>
                    {props.data.VidySpravok.map((list) => (
                        <MainItem2 result={result} userData={props.userData} setData={props.setData} informats={props.next} current={list} key={list.ID} />
                    ))}
                </div>
            ) : null}
        </>
    );
};

export default Spravki2;
