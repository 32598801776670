import React, { LegacyRef, ReactElement, createRef } from "react";
import styles from "../index.module.scss";
import clsx from "clsx";
import { InputTitle } from "../InputTitle";
import { InputError } from "../InputError";
import { IInputProps } from "../../../typec/IInputs";

const InputText = (props: IInputProps): ReactElement => {
    const { onChange = () => null, type, value, titleText, placeholder, clearError = () => null, editVacation = () => null, errorMessage, disable, style } = props;
    const ref = createRef<HTMLInputElement>();
    const change = (e) => {
        clearError("text");
        editVacation({ valueCause: e.target.value });
        onChange(e.target.value);
    };

    const scrollDown = () => {
        setTimeout(() => {
            ref.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 200);
    };

    return (
        <div style={style} onClick={scrollDown} ref={ref}>
            {titleText && <InputTitle titleText={titleText} />}
            <input
                disabled={disable}
                onChange={(e) => change(e)}
                placeholder={placeholder}
                className={clsx(styles["inp"], styles[`inp_${type}`], errorMessage && styles[`inp_error`])}
                type="text"
                defaultValue={value}
            />
            {errorMessage && <InputError titleText={""} />}
        </div>
    );
};

export { InputText };
