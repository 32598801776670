import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import clsx from "clsx";
import DynamicIcon from "../DynamicIcon";
import Loader from "../Loader";

export interface IButtonProps {
    children: React.ReactNode;
    onClick?: () => void;
    type?:
        | "black"
        | "exit"
        | "back"
        | "cancel"
        | "change"
        | "change_not_vw"
        | "invisible"
        | "save"
        | "save_not_margin"
        | "cancel_red"
        | "cancel_grey"
        | "cancel_grey_red"
        | "change_large"
        | "add_vacation"
        | "cancel_white"
        | "tinkoff"
        | "sber"
        | "vtb";
    icon?: string;
    load?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
}

const Button = (props: IButtonProps): ReactElement => {
    const { children, onClick = () => null, type, icon, load, disabled = false, style } = props;
    const click = () => {
        onClick();
    };
    return (
        <button
            style={style}
            onClick={click}
            className={clsx(styles["btn"], styles[`btn_${type}`], load && styles[`btn_${type}_load`], disabled && styles["btn_disabled"])}
            type="button"
            disabled={disabled}
        >
            {icon ? <DynamicIcon name={icon} /> : null}
            {children}
            {load && <Loader forBtn={type == "save" || type == "save_not_margin" ? "blue" : type == "cancel_red" ? "red" : "green"} />}
        </button>
    );
};

export default Button;
