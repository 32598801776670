import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import { InputRadio } from "../../../../../../../components/Inputs";
import { FilterItemCity } from "../FilterItemCity";

const FilterItemRegion = (props): ReactElement => {
    const { firstTitle, secondTitle, id_key, editData, editKeyData, name, nameEditItem, value, items, region, editCity } = props;

    const edit = () => {
        if (editData) {
            editData(value);
        }
    };

    return (
        <>
            <div className={styles.inp_container} key={id_key}>
                <div className={styles.inp_container_radio}>
                    <label htmlFor={`radio_region_${id_key}`} className={styles.inp_container_radio_label}></label>
                    <input id={`radio_region_${id_key}`} name={`radio_region_${name}`} className={styles.inp_container_radio_btn} type="radio" onChange={edit} value={value} />
                    <label htmlFor={`radio_region_${id_key}`} className={styles.inp_container_radio_label}>
                        {value}
                    </label>
                </div>
                <>
                    {region == value ? (
                        <>
                            {items[region].map((element, index) => (
                                <FilterItemCity editCity={editCity} name={name} key={index} id_key={`radio_inp_city_${index}`} valueCity={element} />
                            ))}
                        </>
                    ) : null}
                </>
            </div>
        </>
    );
};

export { FilterItemRegion };
