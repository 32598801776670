import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import { InputRadio } from "../../../../../../../components/Inputs";

const FilterItemCity = (props): ReactElement => {
    const { id_key, name, valueCity, editCity } = props;

    const edit = () => {
        editCity(valueCity);
    };

    return (
        <>
            <div className={styles.inp_container} key={id_key}>
                <div className={styles.inp_container_radio}>
                    <label htmlFor={`radio_region_${id_key}`} className={styles.inp_container_radio_label}></label>
                    <input id={`radio_city_${id_key}`} name={`radio_city_${name}`} className={styles.inp_container_radio_btn} type="radio" onChange={edit} value={valueCity} />
                    <label htmlFor={`radio_city_${id_key}`} className={styles.inp_container_radio_label}>
                        {valueCity}
                    </label>
                </div>
            </div>
        </>
    );
};

export { FilterItemCity };
