import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "../index.module.scss";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../components/Navigation";
import { InputCalendar, InputError } from "../../../components/Inputs";
import SlideScreen from "../../../components/SlideScreen";
import Button from "../../../components/Button";
import SendingData from "../../PODetails/components/SendingData";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";
import VacationCheckItem from "../../Vacation/components/VacationCheck/components/VacationCheckItem";
import VacationCheckItemDetails from "../../Vacation/components/VacationCheck/components/VacationCheckItemDetails";

const PaySlip = (): ReactElement => {
    const [screen, setScreen] = useState(false);
    const [screenPutSlip, setScreenPutSlip] = useState(false);
    const [load, setLoad] = useState(true);
    const [loadBtn, setLoadBtn] = useState(false);
    const [userData, setData] = useState({
        period: "",
        month: "",
        year: "",
        name: "Ушаков Денис Витальевич",
        boss_name: "",
        direction: false,
    });
    const [error, setError] = useState({
        Error: "",
    });

    const editData = (key, element) => {
        setData((prevState) => ({
            ...prevState,
            [key]: element,
        }));
    };
    const createError = (err) => {
        setError({
            Error: err,
        });
    };
    const clearError = () => {
        setError({
            Error: "",
        });
    };

    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_payslip_new", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    setLoad(false);
                    editData("direction", json.res);
                    setLoadBtn(false);
                });
        }
    });

    const postPaySlip = () => {
        setLoadBtn(true);
        fetch("/dev/vacation_payslip_new", {
            method: "POST",
            body: JSON.stringify({
                Period: userData.period.replace(".", ".20"),
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    setScreen(true);
                    editData("month", json.Month);
                    editData("year", json.Year);
                } else {
                    createError(json.Error);
                }
                setLoadBtn(false);
            });
    };
    const dateNow = (): string => {
        const addZero = (value) => (value < 10 ? `0${value}` : value);
        const year = new Date().getFullYear();
        const month = new Date().getMonth();
        const day = new Date().getDate();
        return `${addZero(day)}.${addZero(month + 1)}.${year}`;
    };

    const putPaySlip = () => {
        setLoadBtn(true);
        fetch("/dev/vacation_payslip_new", {
            method: "PUT",
            body: JSON.stringify({}),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    setScreenPutSlip(true);
                } else {
                    createError(json.Error);
                }
            });
    };

    useEffect(() => {
        console.log(userData.period);
    });

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    {userData.direction ? (
                        <>
                            {!screen ? (
                                <>
                                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                                    <div className={styles.doc}>
                                        <p className={styles.doc_title}>Запрос расчетного листа</p>
                                        <InputCalendar
                                            titleTextSlide="Введите месяц и год"
                                            maxDetail="year"
                                            clearError={clearError}
                                            editData={editData}
                                            errorMessage={error.Error}
                                            name="period"
                                            type="dropdown_small"
                                            titleText="Введите месяц и год"
                                            style={{ marginTop: "24px" }}
                                            placeholder="ММ.ГГ"
                                            value={userData.period}
                                        />
                                        <SlideScreen altitudeLevel="service">
                                            <Button type="save_not_margin" onClick={postPaySlip} load={loadBtn}>
                                                Запросить расчётный лист
                                            </Button>
                                        </SlideScreen>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                                    <SendingData
                                        goToMain={goToMain}
                                        closeScreenPut={() => setScreen(false)}
                                        title="Запрос отправлен"
                                        secTitle={`Расчетный лист за ${userData.month} ${userData.year} года будет направлен Вам в ближайшее время Telegram-ботом SOKOLOVHR.`}
                                    />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Navigation prev={prev} goToMain={goToMain}></Navigation>
                            {screenPutSlip ? (
                                <>
                                    <SendingData
                                        title="Заявление отправлено"
                                        secTitle="Расчетные листы будут отправляться вам Telegram-ботом SOKOLOVHR"
                                        closeScreenPut={prev}
                                        goToMain={goToMain}
                                    />
                                </>
                            ) : (
                                <>
                                    <div className={styles.doc}>
                                        <p className={styles.doc_title}>Заявление о направлении расчетных листов в Telegram-бот</p>
                                        <Notification type="info" icon="info">
                                            Проверьте данные и отправьте заявку на согласование
                                        </Notification>
                                        <VacationCheckItem info={context.userdata.name} title="ФИО сотрудника" />
                                        <VacationCheckItem info={context.userdata.boss_name} title="Руководитель" />
                                        <VacationCheckItemDetails title="Подробная информация" info={["Заявление о направлении расчетных листов, получение в телеграм-бот"]} />
                                    </div>
                                    <SlideScreen altitudeLevel="service">
                                        {error.Error ? <InputError titleText={error.Error} /> : null}
                                        <Button load={loadBtn} onClick={putPaySlip} type="save_not_margin">
                                            Отправить заявление
                                        </Button>
                                    </SlideScreen>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default PaySlip;
