import styles from "../index.module.scss";
import { IInputSearchDropDown } from "../../../typec/IInputs";
import { useEffect, useState } from "react";

const InputSearchDrodpown = (props: IInputSearchDropDown): React.ReactElement => {
    const { value, editData = () => null, name, active, onClick = () => null } = props;

    const [clicks, setclick] = useState<any>({});

    const click = (key, element) => {
        setclick({ key, element });
        onClick();
        const div = document.getElementById("inp_src_dropdown_content");
        if (div) {
            div.style.display = "none";
        }
    };

    useEffect(() => {
        if (!active && clicks.key) {
            editData(clicks.key, clicks.element);
            setclick({});
        }
    }, [active]);

    useEffect(() => {
        if (active) {
            const div = document.getElementById("inp_src_dropdown_content");
            if (div) {
                div.style.display = "block";
            }
        } else {
            const div = document.getElementById("inp_src_dropdown_content");
            if (div) {
                div.style.display = "none";
            }
        }
    }, [value]);

    return (
        <>
            <div id="inp_src_dropdown_content" className={styles.inp_src_dropdown_content}>
                {value?.map((element, index) => (
                    <p key={`search_address_${index}`} onClick={() => click(name, element?.value)}>
                        {element?.value}
                    </p>
                ))}
            </div>
        </>
    );
};

export { InputSearchDrodpown };
