import React, { useState } from "react";
import styles from "./index.module.scss";
import Button from "../../../components/Button";
import SlideScreen from "../../../components/SlideScreen";
import Loader from "../../../components/Loader";
import { InputPhone, InputRadio, InputText } from "../../../components/Inputs";
import { json } from "react-router";

const WhoWillPay = (props) => {
    const { actionElement = () => null, getLoad = () => null, clear, stores } = props;
    const [data, setData] = useState({
        stores: stores,
    });
    const [err, setError] = useState("");
    const createError = (element) => {
        setError(element);
    };
    const [load, setLoad] = useState(false);
    const [isContentVisible1, setContentVisible1] = useState(false);
    const [isContentVisible2, setContentVisible2] = useState(false);
    const [isContentVisible3, setContentVisible3] = useState(true);
    const [isContentVisible4, setContentVisible4] = useState(false);
    const handleTextClick1 = () => {
        setContentVisible1(true);
        setContentVisible2(false);
        setContentVisible3(false);
        setContentVisible4(true);
    };
    const handleTextClick2 = () => {
        setContentVisible2(true);
        setContentVisible1(false);
        setContentVisible3(false);
        setContentVisible4(true);
    };
    const handleTextClick3 = () => {
        setContentVisible3(false);
        setContentVisible1(false);
    };
    const [info, setInfo] = useState({
        will_pay: true,
        full_name_pay: "",
        phone_number_pay: "",
        bank_name_pay: "",
        sum_pay: "",
    });
    const editData = (name: string, element: string) => {
        setInfo({
            ...info,
            [name]: element,
        });
    };
    const clearError = () => {
        setError("");
    };
    const Reject = () => {
        setLoad(true);
        fetch("/dev/vacation_rose_users", {
            method: "POST",
            body: JSON.stringify({
                full_name: props.infofr.full_name,
                phone_number: props.infofr.phone_number,
                will_pay: info.will_pay,
                shop_guid: data.stores.guid,
                phone_number_pay: info.phone_number_pay,
                full_name_pay: info.full_name_pay,
                bank_name_pay: info.bank_name_pay,
                sum_pay: info.sum_pay,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    // OK
                    actionElement();
                    setLoad(false);
                    getLoad();
                    clear(true);
                    console.log(json);
                } else {
                    // Не OK
                    setLoad(false);
                    console.log(json);
                    // json = err;
                    if (!json.res) createError(json.err);
                }
            })
            .catch((e) => {
                console.error(e);
                setLoad(false);
                actionElement();
                getLoad();
            });
    };
    const people = data.stores.users_required;
    console.log(people);
    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                </>
            ) : (
                <></>
            )}
            <div className={styles.magazin}>
                <div className={styles.magazin_date}>{data.stores.local_time.replace(":00.000", "")}</div>
                <div className={styles.magazin_name}>{data.stores.store_name}</div>
                <div className={styles.magazin_adress}>{data.stores.address}</div>
                <div className={styles.magazin_rose}>Необходимое количество роз: {data.stores.employees_number}</div>
            </div>
            {people == 1 && (
                <div className={styles.info}>
                    <div>В этой ТТ ты сам справишься с задачей вдохновить продавцов и подарить всем розы.</div>
                </div>
            )}
            {people == 2 && (
                <div className={styles.info}>
                    <div>В этой ТТ вдохновлять продавцов ты будешь вместе с напарником. Мы сообщим тебе, когда он запишется.</div>
                </div>
            )}
            {people > 2 && (
                <div className={styles.info}>
                    <div>В этой ТТ вдохновлять продавцов вы будете командой. Вступай в чат, чтобы решить вопрос о покупке роз.</div>
                </div>
            )}
            <div className={styles.zagolovok}>Сможешь самостоятельно купить розы или нужна помощь из фонда?</div>
            <div>
                <div className={styles.many}>
                    <div className={styles.many_question} onClick={handleTextClick1}>
                        <InputRadio secondTitle="Куплю сам(а)" id_key="sam" name="many" onClick={() => setInfo({ ...info, will_pay: true })} />
                    </div>
                    <div className={styles.many_question} onClick={handleTextClick2}>
                        <InputRadio secondTitle="Из фонда" id_key="fond" name="many" onClick={() => setInfo({ ...info, will_pay: false })} />
                    </div>
                </div>
                {isContentVisible3 && (
                    <div className={styles.many_text} onClick={handleTextClick3}>
                        Можно изменить позже
                    </div>
                )}
                {isContentVisible1 && (
                    <div className={styles.many_text} onClick={handleTextClick1}>
                        Можно изменить позже
                    </div>
                )}
                {isContentVisible2 && (
                    <div>
                        <div className={styles.many_answer}>
                            <div className={styles.many_answer_err}>{err}</div>
                            {/* <div className={styles.many_answer_zagolovok}>Номер телефона для СБП</div> */}
                            {/* <InputText type={"smallFixRosa"} onChange={(value) => setInfo({ ...info, phone_number_pay: value })} placeholder="+7 999 999 99 99" /> */}
                            <InputPhone
                                // onChange={() => setInfofr({ ...infofr.phone_number })}
                                editData={editData}
                                name="phone_number_pay"
                                type="phone"
                                titleText="Номер телефона для СБП"
                                placeholder="+7 999 999 99 99"
                                value={info.phone_number_pay}
                                errorMessage={err}
                                clearError={clearError}
                            />
                            <div className={styles.many_answer_zagolovok}>Введите сумму для перевода</div>
                            <InputText type={"smallFixRosa"} onChange={(value) => setInfo({ ...info, sum_pay: value })} placeholder="250₽" />
                            <div className={styles.many_answer_zagolovok}>Имя получателя</div>
                            <InputText type={"smallFixRosa"} onChange={(value) => setInfo({ ...info, full_name_pay: value })} placeholder="Дмитрий Сергеевич Д." />
                            <div className={styles.many_answer_zagolovok}>Банк получения средств</div>
                            <InputText type={"smallFixRosa"} onChange={(value) => setInfo({ ...info, bank_name_pay: value })} placeholder="Сбер/Тинькофф/Альфа и т.д." />
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.footer}>
                {isContentVisible4 && (
                    <SlideScreen altitudeLevel="service">
                        <Button onClick={Reject} type="save_not_margin">
                            Подтвердить участие
                        </Button>
                    </SlideScreen>
                )}
            </div>
        </>
    );
};

export default WhoWillPay;
