/* eslint-disable prettier/prettier */
import React, { ReactElement, RefObject, useEffect, useMemo, useRef, useState } from "react";
import styles from "../PhotoButton/index.module.scss";
import PlusIcon from "../../../../../icons/plus-interface_large.svg";
import clsx from "clsx";
import { InputNewPhotoProps } from "../../../../../typec/IClientSupport";
import ImageItem from "../ImageItem";
import { v4 as uuidv4 } from "uuid";

export interface INewPhotoButtonLimit {
    onChange?: (el) => void;
    limit: number;
    reset?: boolean;
    setReset?: (el) => void;
    data?: any;
}

const NewPhotoButtonLimit = (props: INewPhotoButtonLimit): ReactElement => {
    const { onChange = () => void 0, limit, reset, setReset = () => null, data } = props;
    const [click, setClick] = useState(false);
    const [array, setArray] = useState<any>(data ? data : []);
    const [multiple, setMultiple] = useState(false);

    const fileRef: RefObject<HTMLInputElement> = useRef(null);
    const avatarRef: RefObject<HTMLDivElement> = useRef(null);

    const showFileDialog = () => {
        setTimeout(() => {
            fileRef.current?.dispatchEvent(new MouseEvent("click"));
            setClick(true);
        }, 280);
    };

    const onFileChoose = (e) => {
        Object.entries(e.target.files).forEach(([key]) => {
            if (Number(key) < limit - array.length) {
                const file: File = e.target.files[key];
                const reader: FileReader = new FileReader();
                reader.onload = onFileRead;
                reader.readAsDataURL(file);
            }
        });
    };

    const onFileRead = (e) => {
        setArray((prevState) => [...prevState, { id: uuidv4(), data: e.target.result }]);
    };

    const del = (element) => {
        setArray((prevState) => prevState.filter((value) => value.id !== element));
    };

    useEffect(() => {
        if (array) {
            onChange(array);
        }
        if (fileRef.current) {
            fileRef.current.value = "";
        }
        setClick(false);
    }, [array]);

    useEffect(() => {
        if (reset) {
            setArray([]);
        }
        setReset(false);
    }, [reset]);

    useEffect(() => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (navigator.userAgentData.platform.toLowerCase() != "android") {
                setMultiple(true);
            }
        } catch (error) {
            setMultiple(true);
        }
    }, []);

    return (
        <>
            {array?.map((element) => (
                <ImageItem
                    key_photo={`support_img_${element?.id.slice(-3)}`}
                    src={element.data}
                    onClick={() => {
                        del(element.id);
                    }}
                />
            ))}
            {limit > array?.length ? (
                <div className={clsx(styles["photo"])}>
                    <div id="img_div_btn" className={styles["photo_group"]} ref={avatarRef}>
                        <span className={styles["photo_group_empty"]}>
                            <PlusIcon />
                        </span>
                        <button type="button" className={clsx(styles["photo_group_btn"], click && styles["photo_group_btn_click"])} data-action="photo" onClick={showFileDialog} />
                    </div>
                    <input
                        id="inp_image"
                        className={styles["photo_group_file"]}
                        type="file"
                        multiple={multiple}
                        // accept="image/*, .pdf, .txt"
                        ref={fileRef}
                        onChange={(e) => {
                            onFileChoose(e);
                        }}
                    />
                </div>
            ) : null}
        </>
    );
};

export default NewPhotoButtonLimit;
