import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { NavigationContext } from "../../template";
import { useNavigate, useSearchParams } from "react-router-dom";
import Navigation from "../../components/Navigation";
import SlideScreen from "../../components/SlideScreen";
import Button from "../../components/Button";
import SendingData from "../PODetails/components/SendingData";
import Loader from "../../components/Loader";
import { InputCheckBox, InputTitle } from "../../components/Inputs";
import ChildrenItem from "./components/ChildrenItem";
import NewPhotoButtonLimit from "../ClietSupport/NewAppeal/components/NewPhotoButtonLimit";
import Notification from "../../components/Notification";
import VacationCheck2 from "../Vacation/components/VacationCheck/index2";

const DeductionForChildren = (props): ReactElement => {
    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };

    const [screen, setScreen] = useState(false);
    const [load, setLoad] = useState(true);
    const [loadBtn, setLoadBtn] = useState(false);
    const [disableBtn, setDisableBtn] = useState(true);
    const [viewEditChildren, setViewEditChildren] = useState(false);
    const [viewSlider, setViewSlider] = useState(true);
    const [userComment, setComment] = useState({
        comment: "",
    });
    const [error, setError] = useState("");
    const [more, setMore] = useState(false);

    // текст для отображения на форме загрузки изображений
    const documentText = [
        "Справка 2-НДФЛ за 2024 с предыдущих мест работы/СТД-Р",
        "Свидетельство о рождении ребенка",
        "Свидетельство о браке/разводе/установлении отцовства/документы об опекунстве",
        "Справка с места учебы",
        "Справка об инвалидности (с 2-х сторон)",
    ];

    // массив данный о детях и прошлых заявок
    const [dataChild, setDataChild] = useState<any>({});

    // промежуточные переменные для редактирования и отправки заявки, удаляются после каждого отправления заявки
    const [editDataChild, setEditDataChild] = useState<any>({});

    const editComment = (key, element) => {
        setComment((prevState) => ({
            ...prevState,
            [key]: element,
        }));
        clearError();
    };
    const createError = (err) => {
        setError(err);
    };
    const clearError = () => {
        setError("");
    };

    const addPhoto = (element) => {
        setEditDataChild((prevState) => ({
            ...prevState,
            photo: element,
        }));
        clearError();
    };

    const redirectInChenge = () => {
        props.redirectOn();
        navigate("/changePersonalDate");
    };

    const getDisableBtn = (): boolean => {
        let bool = true;
        const len = getNDFL(context.userdata.start_works) ? editDataChild.photo?.length : editDataChild.photo?.length + 1;
        if (editDataChild.student && editDataChild.disability && len >= 6) bool = false;
        else if (editDataChild.student && !editDataChild.disability && len >= 4) bool = false;
        else if (editDataChild.disability && !editDataChild.student && len >= 5) bool = false;
        else if (!editDataChild.student && !editDataChild.disability && len >= 3) bool = false;
        return bool;
    };

    useEffect(() => {
        setDisableBtn(getDisableBtn());
    }, [editDataChild]);

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_vychety_new", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res == true) {
                        // Получены данные
                        setDataChild(json);
                    }
                    setLoad(false);
                });
        }
        if (!viewEditChildren) {
            setEditDataChild({});
            setComment({ comment: "" });
        }
    }, [load, viewEditChildren]);

    const openEditScreen = (data) => {
        setEditDataChild(data);
        setViewEditChildren(true);
    };

    const postDataChild = (child_id, child_invalid, child_student, screens, comment) => {
        setLoadBtn(true);
        const screensTrue = screens.map((el, index) => {
            return {
                Content: el.data,
                Name: `screen_${index}.${el.data.split(";")[0].split("/")[1]}`,
            };
        });
        fetch("/dev/vacation_vychety_new", {
            method: "POST",
            body: JSON.stringify({
                ChildID: child_id,
                ChildInvalid: child_invalid,
                ChildStudent: child_student,
                Screens: screensTrue,
                Comment: comment,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    setScreen(true);
                } else {
                    createError(json.Error);
                }
                setLoadBtn(false);
                setScreen(false);
                setViewEditChildren(false);
                setLoad(true);
            });
    };

    const cutStr = (s, w) => (s.length > w ? `"` + s.substring(0, w).trim() + `..." ` : s);

    const [searchParam, setSearchParam] = useSearchParams();

    const redirect = (el) => {
        if (el.AppData) {
            setSearchParam({ app_id: el.AppData.IDApp, forward_to: "requestsList" });
        }
    };

    const getNDFL = (el) => {
        let bool = true;
        if (el) {
            const date = el.split("T")[0].split("-");
            if ((Number(date[0]) == new Date().getFullYear() && date[2] == "01") || Number(date[0]) < new Date().getFullYear()) bool = false;
        }
        return bool;
    };

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    {false ? (
                        <>
                            {" "}
                            <Navigation prev={prev} goToMain={goToMain}></Navigation>
                            <SendingData
                                goToMain={goToMain}
                                closeScreenPut={() => prev()}
                                title="Заявка уже отправлена"
                                secTitle={`Следующее заявление вы можете оформить в ${new Date().getFullYear() + 1} году.`}
                            />
                        </>
                    ) : (
                        <>
                            {!screen ? (
                                <>
                                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                                    <div className={styles.doc}>
                                        <p className={styles.doc_title}>Оформить вычет на детей</p>
                                        <Notification type="info" icon="info">
                                            {dataChild?.children?.length > 0
                                                ? "Проверьте введенные данные и отправьте заявление."
                                                : "Информация о детях, на которых можно оформить вычет, отсутствует."}
                                        </Notification>
                                        <div className={styles.doc_container}>
                                            {dataChild?.children?.map((el, index) => (
                                                <ChildrenItem
                                                    key={`children_item_name_${el.Name}_${index}`}
                                                    name={el.Name}
                                                    dateBirthday={el.Birthday}
                                                    status={el.IsApp}
                                                    statusText={el.AppData.StatusVychet}
                                                    onClick={() => openEditScreen(el)}
                                                    onRedirect={() => redirect(el)}
                                                />
                                            ))}
                                        </div>
                                        <p className={styles.doc_title_sub_grey}>
                                            Если сведения о детях неполные или искажены, поставьте&nbsp;
                                            <a onClick={() => redirectInChenge()}>заявление на изменение персональных данных</a>
                                        </p>
                                        <div style={{ paddingTop: "80px" }} />
                                        {viewEditChildren ? (
                                            <SlideScreen
                                                altitudeLevel="full"
                                                titleText="Вычет на ребенка"
                                                onClose={() => {
                                                    setViewEditChildren(false);
                                                    setMore(false);
                                                }}
                                            >
                                                <div className={styles.doc_item_edit}>
                                                    <div>
                                                        {editDataChild.AppData.StatusCommentary?.length > 0 ? (
                                                            <Notification type="info" icon="info">
                                                                <div style={{ width: "100%" }}>
                                                                    <p className={styles.doc_item_edit_notification_title}>{`Заявление на вычет от ${
                                                                        editDataChild.AppData.Date
                                                                    } было ${editDataChild.AppData.StatusDisplay?.toLowerCase()}:`}</p>
                                                                    <p className={styles.doc_item_edit_notification_text}>
                                                                        {more
                                                                            ? `"${editDataChild.AppData.StatusCommentary}"`
                                                                            : cutStr(`${editDataChild.AppData.StatusCommentary}`, 65)}
                                                                        {more ? null : editDataChild.AppData.StatusCommentary.length > 65 ? (
                                                                            <span className={styles.doc_item_edit_notification_more} onClick={() => setMore(true)}>
                                                                                Подробнее
                                                                            </span>
                                                                        ) : null}
                                                                    </p>
                                                                </div>
                                                            </Notification>
                                                        ) : null}

                                                        <ChildrenItem
                                                            key={`children_item_name_${editDataChild.Name}`}
                                                            name={editDataChild.Name}
                                                            dateBirthday={editDataChild.Birthday}
                                                            status={true}
                                                        />
                                                        {editDataChild.Age >= 16 ? (
                                                            <InputCheckBox
                                                                titleText={`Студент очной формы обучения`}
                                                                reverse
                                                                style={{ display: "flex", flexDirection: "column", marginTop: "16px", letterSpacing: "0.5px", lineHeight: "24px" }}
                                                                onClick={() =>
                                                                    setEditDataChild((prevState) => ({
                                                                        ...prevState,
                                                                        student: editDataChild.student != undefined ? !editDataChild.student : true,
                                                                    }))
                                                                }
                                                            />
                                                        ) : null}
                                                        <InputCheckBox
                                                            titleText={`Инвалидность`}
                                                            reverse
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                marginTop: "16px",
                                                                letterSpacing: "0.5px",
                                                                lineHeight: "24px",
                                                            }}
                                                            onClick={() =>
                                                                setEditDataChild((prevState) => ({
                                                                    ...prevState,
                                                                    disability: editDataChild.disability != undefined ? !editDataChild.disability : true,
                                                                }))
                                                            }
                                                        />
                                                        <InputTitle style={{ marginTop: "32px", marginBottom: "0" }} titleText="Фото подтверждающих документов" />

                                                        {documentText.map((el, index) => {
                                                            if (getNDFL(context.userdata.start_works)) {
                                                                if (editDataChild.student && editDataChild.disability) {
                                                                    return <p className={styles.doc_title_sub_grey}>{`${index + 1}. ${el}`}</p>;
                                                                } else if (editDataChild.student) {
                                                                    if (index < 4) return <p className={styles.doc_title_sub_grey}>{`${index + 1}. ${el}`}</p>;
                                                                } else if (editDataChild.disability) {
                                                                    if (index < 3) return <p className={styles.doc_title_sub_grey}>{`${index + 1}. ${el}`}</p>;
                                                                    else if (index > 3) {
                                                                        return <p className={styles.doc_title_sub_grey}>{`${index}. ${el}`}</p>;
                                                                    }
                                                                } else {
                                                                    if (index < 3) return <p className={styles.doc_title_sub_grey}>{`${index + 1}. ${el}`}</p>;
                                                                }
                                                            } else if (index != 0) {
                                                                if (editDataChild.student && editDataChild.disability) {
                                                                    return <p className={styles.doc_title_sub_grey}>{`${index}. ${el}`}</p>;
                                                                } else if (editDataChild.student) {
                                                                    if (index < 4) return <p className={styles.doc_title_sub_grey}>{`${index}. ${el}`}</p>;
                                                                } else if (editDataChild.disability) {
                                                                    if (index < 3) return <p className={styles.doc_title_sub_grey}>{`${index}. ${el}`}</p>;
                                                                    else if (index > 3) {
                                                                        return <p className={styles.doc_title_sub_grey}>{`${index - 1}. ${el}`}</p>;
                                                                    }
                                                                } else {
                                                                    if (index < 3) return <p className={styles.doc_title_sub_grey}>{`${index}. ${el}`}</p>;
                                                                }
                                                            }
                                                        })}
                                                        <div className={styles.doc_photos}>
                                                            <NewPhotoButtonLimit limit={20} data={editDataChild.photo} onChange={addPhoto} />
                                                        </div>
                                                        {error ? <p className={styles.doc_item_edit_error}>{error}</p> : null}
                                                    </div>
                                                    <Button style={{ marginTop: "16px" }} type="save_not_margin" disabled={disableBtn} onClick={() => setScreen(true)}>
                                                        Сформировать заявление
                                                    </Button>
                                                </div>
                                            </SlideScreen>
                                        ) : (
                                            <SlideScreen altitudeLevel="service">
                                                <Button type="save_not_margin" onClick={() => navigate("/requestsList")}>
                                                    Мои заявления
                                                </Button>
                                            </SlideScreen>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                                    <div className={styles.doc_item_check}>
                                        <VacationCheck2
                                            nameStaff={context.userdata.name}
                                            nameChildren={editDataChild.Name}
                                            writeComment={true}
                                            writeCommentEdit={editComment}
                                            valueComment={userComment.comment}
                                            nameEditItemComment="comment"
                                            disableNo={true}
                                            typeVacation="вычет на детей"
                                            statementDay={new Date().toLocaleString("ru-RU").split(",")[0]}
                                            photoArr={editDataChild.photo}
                                            // disableVoid={true}
                                            onBlur={() => setViewSlider(true)}
                                            onFocus={() => setViewSlider(false)}
                                        />
                                        {viewSlider ? (
                                            <SlideScreen altitudeLevel="service">
                                                <Button
                                                    type="save_not_margin"
                                                    load={loadBtn}
                                                    onClick={() =>
                                                        postDataChild(
                                                            editDataChild.ID,
                                                            editDataChild.disability != undefined ? editDataChild.disability : false,
                                                            editDataChild.student != undefined ? editDataChild.student : false,
                                                            editDataChild.photo,
                                                            userComment.comment
                                                        )
                                                    }
                                                >
                                                    Отправить заявление
                                                </Button>
                                                <Button
                                                    type="cancel_grey"
                                                    onClick={() => {
                                                        setScreen(false);
                                                        setViewEditChildren(false);
                                                    }}
                                                >
                                                    Отмена
                                                </Button>
                                            </SlideScreen>
                                        ) : null}
                                        {/* <div className={styles.doc_item_check_btn}>
                                            <Button
                                                type="save_not_margin"
                                                load={loadBtn}
                                                onClick={() =>
                                                    postDataChild(
                                                        editDataChild.ID,
                                                        editDataChild.disability != undefined ? editDataChild.disability : false,
                                                        editDataChild.student != undefined ? editDataChild.student : false,
                                                        editDataChild.photo,
                                                        ""
                                                    )
                                                }
                                            >
                                                Отправить заявление
                                            </Button>
                                            <Button
                                                type="cancel_grey"
                                                onClick={() => {
                                                    setScreen(false);
                                                    setViewEditChildren(false);
                                                }}
                                            >
                                                Отмена
                                            </Button>
                                        </div> */}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default DeductionForChildren;
