import React, { ReactElement, useState } from "react";
import styles from "./index.module.scss";
import { IInputRadioProps } from "../../../typec/IInputs";
import clsx from "clsx";

const InputRadioAddressSpravka = (props) => {
    const { value, id_key, name, editData, selectedStore } = props;

    // const [check, setCheck] = useState(false);

    // const isChecked = () => {
    //     check ? setCheck(false) : setCheck(true);
    // };

    const edit = () => {
        // if (editKeyData) {
        //     editKeyData(nameEditItem, secondTitle == "Да" ? true : false);
        //     checked();
        // }
        if (editData) {
            editData(value);
            // isChecked();
        }
    };

    return (
        <div className={clsx(styles[`${selectedStore?.ID == value?.ID ? "active" : "item"}`], value.enough_users && styles[`disabled`])} key={id_key}>
            <input
                className={styles.input}
                type="radio"
                id={`radio_${id_key}`}
                name={`radio_${name}`}
                value={value}
                onChange={edit}
                // disabled={value.enough_users}
                checked={selectedStore?.ID === 0}
            />
            <label className={styles.label} htmlFor={`radio_${id_key}`}>
                <p className={styles.text_Fat}>{value.Name.split(",")[0]},</p>
                <p className={styles.text}>
                    {value.Name.split(",")[1]}
                    {value.Name.split(",")[2]}
                    {value.Name.split(",")[3]}
                    {value.Name.split(",")[4]}, {value.Description}
                </p>
                {/* <p className={styles.text}>{value.Description}</p> */}
                {/* <p className={styles.text}>{value.address}</p> */}
                {/* {value.enough_users ? <div className={styles.info}>Занято другим</div> : null} */}
            </label>
        </div>
    );
};
export { InputRadioAddressSpravka };
