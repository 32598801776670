import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "../index.module.scss";
import Loader from "../../../components/Loader";
import Button from "../../../components/Button";
import Navigation from "../../../components/Navigation";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router-dom";
import VacationList from "../components/VacationList";
import SlideScreen from "../../../components/SlideScreen";
import VacationEdit from "../components/VacationEdit";
import VacationItem from "../components/VacationItem";
import VacationCheck from "../components/VacationCheck";
import VacExpenseNull from "./componets/VacExpenseNull";
import { IUserVacationOwn } from "../../../typec/IVacations";
import word from "../../../components/word";

const VacationExpense = (): ReactElement => {
    const [showChange, setShowChange] = useState({
        show: false,
        showCancel: false,
        modified: false,
    });
    const [screenCheck, setScreenCheck] = useState(false);
    const [error, setError] = useState({
        text: "",
        num: "",
    });
    const [userData, setUserData] = useState<IUserVacationOwn>();
    const [newData, setNewData] = useState({
        valueCause: "По семейным обстоятельствам",
        valueStartDay: "",
        valueFinishDay: "",
        valueAmount: "",
        Date: "",
        view: false,
    });
    const [load, setLoad] = useState(true);
    const [loadBtn, setLoadBtn] = useState(false);

    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };

    const createError = (el) => {
        setError({
            ...error,
            ...el,
        });
    };
    const clearError = (key: string) => {
        setError({
            ...error,
            [key]: "",
        });
    };

    const loadHero = () => {
        setLoad(true);
    };

    const editPutInfo = (element) => {
        setUserData({
            ...userData,
            ...element,
        });
    };
    const editVacationNew = (element) => {
        setNewData({
            ...newData,
            ...element,
        });
    };
    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_vac_own_new", {
                method: "GET",
            })
                .then((res) => res.json())
                .then((json) => {
                    setUserData(json);
                    setLoad(false);
                });
        }
    }, [load]);
    const reRender = () => {
        setLoad(true);
        setScreenCheck(false);
        setShowChange({
            modified: false,
            show: false,
            showCancel: false,
        });
    };
    const postVacationNew = () => {
        setLoadBtn(true);
        fetch("/dev/vacation_vac_own_new", {
            method: "POST",
            body: JSON.stringify({
                StartDate: newData.valueStartDay.replace(".2", ".202"),
                NumberDays: newData.valueAmount,
                Comment: newData.valueCause,
            }),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    setShowChange({
                        show: false,
                        showCancel: false,
                        modified: true,
                    });
                    setLoadBtn(false);
                    setNewData({
                        valueCause: newData.valueCause,
                        valueStartDay: newData.valueStartDay,
                        valueFinishDay: json.KonetsDate.replace(".20", "."),
                        valueAmount: newData.valueAmount,
                        Date: json.Date,
                        view: true,
                    });
                    showScreenCheck();
                } else {
                    createError({ text: json.Error, num: json.ErrorCode });
                    setLoadBtn(false);
                }
            });
    };
    const putVacationNew = () => {
        setLoadBtn(true);
        fetch("/dev/vacation_vac_own_new", {
            method: "PUT",
            body: JSON.stringify({}),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    setLoadBtn(false);
                    showScreenCheck();
                    show({ modified: false });
                    setLoad(true);
                    setNewData({
                        valueCause: "По семейным обстоятельствам",
                        valueStartDay: "",
                        valueFinishDay: "",
                        valueAmount: "",
                        Date: "",
                        view: false,
                    });
                } else {
                    setLoadBtn(false);
                    createError({ text: json.Error, num: json.ErrorCode });
                }
            });
    };

    const show = (element) => {
        setShowChange({
            ...showChange,
            ...element,
        });
    };
    const showScreenCheck = () => {
        screenCheck ? setScreenCheck(false) : setScreenCheck(true);
    };

    const isNullVac = (): boolean => {
        let bool = false;
        const vacations = userData?.user_vacations
            ?.map((el) => {
                return el.Status;
            })
            .filter((value) => value != "Отменена" && value != "Информация передана руководителю IT, заявка закрыта");
        if (`${vacations}` == "") {
            bool = true;
        }
        return bool;
    };

    const loading = () => {
        setLoad(true);
    };

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    {!screenCheck ? (
                        <>
                            {isNullVac() ? (
                                <VacExpenseNull loading={loading} />
                            ) : (
                                <div className={styles.hero_container_vacations}>
                                    <p className={styles.main_heading}>
                                        Заявление на отпуск за <br /> свой счёт
                                    </p>
                                    <p className={styles.second_title}>Выберите даты отпуска</p>
                                    <VacationList
                                        // postVacationExternal={postVacationNew}
                                        typeVacation="expense"
                                        userData={userData}
                                        loadHero={loadHero}
                                        editPutInfo={editPutInfo}
                                        reRender={reRender}
                                    />
                                    {newData.view && (
                                        <VacationItem
                                            typeVacation="expense"
                                            valueCause={newData.valueCause}
                                            valueAmount={newData.valueAmount}
                                            valueFinishDay={newData.valueFinishDay}
                                            valueStartDay={newData.valueStartDay}
                                            typeItem="change"
                                            postVacationExternal={postVacationNew}
                                            editPutInfo={editVacationNew}
                                            loadExternal={loadBtn}
                                            errorExternal={error}
                                            clearErrorExternal={clearError}
                                        />
                                    )}
                                    {showChange.modified ? null : (
                                        <>
                                            <Button type="add_vacation" icon="plus-interface" onClick={() => show({ show: true })}>
                                                Добавить отпуск
                                            </Button>
                                        </>
                                    )}
                                </div>
                            )}
                            {showChange.show ? (
                                <SlideScreen altitudeLevel="full" titleText="Новая дата отпуска" show={show}>
                                    <VacationEdit
                                        editForExpense={false}
                                        clearError={clearError}
                                        editVacation={editVacationNew}
                                        error={error}
                                        load={loadBtn}
                                        postVacation={postVacationNew}
                                        textButton="Сформировать заявление"
                                        dateNow={false}
                                        valueCause={newData.valueCause}
                                        valueStartDay={newData.valueStartDay}
                                        typeVacation="expense"
                                    />
                                </SlideScreen>
                            ) : null}
                            {showChange.modified ? (
                                <SlideScreen altitudeLevel="service">
                                    <Button type="save_not_margin" onClick={() => showScreenCheck()}>
                                        Сформировать заявление
                                    </Button>
                                </SlideScreen>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <VacationCheck
                                finishDay={""}
                                quantityDay={newData.valueAmount}
                                typeVacation="Отпуск за свой счет"
                                startDay={""}
                                statementDay={newData.Date}
                                nameStaff={context.userdata.name}
                                position={context.userdata.job_name}
                                subdivision={context.userdata.subdiv_name}
                                supervisor={context.userdata.boss_name}
                                periodArr={[
                                    `С ${newData.valueStartDay?.replace(".2", ".202")} по ${newData.valueFinishDay?.replace(".2", ".202")}, ${newData.valueAmount} ${word(
                                        Number(newData.valueAmount),
                                        ["день", "дня", "дней"]
                                    )}`,
                                ]}
                                disableDetails
                            />
                            <SlideScreen altitudeLevel="service">
                                <Button load={loadBtn} type="save_not_margin" onClick={() => putVacationNew()}>
                                    Отправить на согласование
                                </Button>
                                <Button type="cancel_grey" onClick={() => showScreenCheck()}>
                                    Отмена
                                </Button>
                            </SlideScreen>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default VacationExpense;
