import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Filter from "../../../icons/filter.svg";
import Navigation from "../../../components/Navigation";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router-dom";

const NewRequest = () => {
    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        // context.goToMain();
        navigate("/");
        context.click(context.currentMenu.find((el) => el.id == 1));
        // console.log(context.currentMenu.find((el) => el.id == 1)?.children);
    };
    return (
        <>
            <div className={styles.buttonBlock}>
                <button onClick={goToMain} className={styles.button}>
                    Оформить новое заявление
                </button>
            </div>
        </>
    );
};

export default NewRequest;
