import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "../index.module.scss";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../components/Navigation";
import { VacOfSubItemDep, VacOfSubItemName } from "../componets/VacOfSubItem";
import Loader from "../../../components/Loader";
import clsx from "clsx";
import { ISubOfTimeOffName, ITimeOffOfSubordinates } from "../../../typec/ITimeOff";
import TimeOffItemName from "./components/TimeOffItemName";
import TimeOffItemDep from "./components/TimeOffItemDep";
import Notification from "../../../components/Notification";

const TimeOffOfSub = (): ReactElement => {
    const [load, setLoad] = useState(true);
    const [isScreen, setIsScreen] = useState({
        actual: true,
        dep: false,
        name: false,
    });
    const [timeOffData, setTimeOffData] = useState<ITimeOffOfSubordinates>();
    const [timeOffDataName, setTimeOffDataName] = useState<ISubOfTimeOffName>();
    const [isName, setIsName] = useState(false);
    const [timeOffDataDep, setTimeOffDataDep] = useState<ISubOfTimeOffName>();
    const [isDep, setIsDep] = useState(false);

    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        if (isName) {
            setIsName(false);
        }
        if (isDep) {
            setIsDep(false);
        }
        if (!isName && !isDep) navigate("/");
    };
    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };

    useEffect(() => {
        if (load) {
            fetch(`/dev/vacation_dayoff_subs_new?do_date=${""}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        setTimeOffData(json);
                    }
                    setLoad(false);
                });
        }
    });

    const setClickInfoName = (name) => {
        setTimeOffDataName(timeOffData?.Dayoffs?.filter((el) => el.Name == name));
        setIsName(true);
    };

    const setClickInfoDep = (nameDep) => {
        setTimeOffDataDep(timeOffData?.Dayoffs?.filter((el) => el.PodrazdeleniyeName == nameDep));
        setIsDep(true);
    };

    const getActualTimeOff = (data) => {
        return data?.Dayoffs.filter((el) => {
            const now = new Date().toLocaleString("ru-RU").split(",")[0];
            if (el.Date == now) return true;
            else return false;
        });
    };

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    {isName && (
                        <div className={styles.doc}>
                            <p className={styles.doc_title}>{timeOffDataName && timeOffDataName[0]?.Name}</p>
                            {timeOffDataName?.map((el, index) => (
                                <TimeOffItemDep key={`TimeOffItemDep_${index}_${el.Name}`} forDate={el.Date} workDate={el.WorkDate} isActual={el.IsActual} isActive={el.IsActive} />
                            ))}
                        </div>
                    )}
                    {isDep && !isName && (
                        <div className={styles.doc}>
                            <>
                                <p className={styles.doc_title}>{timeOffDataDep && timeOffDataDep[0].PodrazdeleniyeName}</p>
                                {timeOffDataDep?.map((el, index) => (
                                    <TimeOffItemName
                                        key={`TimeOffItemName_${index}_${el.Name}`}
                                        name={el.Name}
                                        forDate={el.Date}
                                        workDate={el.WorkDate}
                                        isActual={el.IsActual}
                                        isActive={el.IsActive}
                                    />
                                ))}
                            </>
                        </div>
                    )}
                    {!isDep && !isName && (
                        <div className={styles.doc}>
                            <div className={styles.doc_flex}>
                                <p className={styles.doc_flex_title}>Отгулы подчиненных</p>
                            </div>
                            <div className={styles.doc_title_container}>
                                <span
                                    onClick={() => setIsScreen({ actual: true, dep: false, name: false })}
                                    className={clsx(styles["doc_title_filter"], isScreen.actual && styles["doc_title_filter_activ"])}
                                >
                                    В отгуле
                                </span>
                                <span
                                    onClick={() => setIsScreen({ actual: false, dep: true, name: false })}
                                    className={clsx(styles["doc_title_filter"], isScreen.dep && styles["doc_title_filter_activ"])}
                                >
                                    Отдел
                                </span>
                                <span
                                    onClick={() => setIsScreen({ actual: false, dep: false, name: true })}
                                    className={clsx(styles["doc_title_filter"], isScreen.name && styles["doc_title_filter_activ"])}
                                >
                                    ФИО
                                </span>
                            </div>
                            {isScreen.dep ? (
                                <>
                                    {timeOffData && timeOffData?.Subdivisions?.length > 0 ? (
                                        <>
                                            {timeOffData?.Subdivisions?.map((el, index) => (
                                                <VacOfSubItemDep onClick={setClickInfoDep} key={`VacOfSubItemDep_${index}`} nameDep={el} />
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            <Notification type="info" icon="info">
                                                У ваших подчиненных нет отгулов.
                                            </Notification>
                                        </>
                                    )}
                                </>
                            ) : isScreen.name ? (
                                <>
                                    {timeOffData && timeOffData?.Names?.length > 0 ? (
                                        <>
                                            {timeOffData?.Names?.map((el, index) => (
                                                <VacOfSubItemName onClick={setClickInfoName} key={`VacOfSubItemName_${index}`} name={el} />
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            <Notification type="info" icon="info">
                                                У ваших подчиненных нет отгулов.
                                            </Notification>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {getActualTimeOff(timeOffData).length > 0 ? (
                                        <>
                                            {getActualTimeOff(timeOffData)?.map((el, index) => (
                                                <TimeOffItemName key={`TimeOffItemName_${index}_${el.Name}`} name={el.Name} forDate={el.Date} workDate={el.WorkDate} />
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            <Notification type="info" icon="info">
                                                Все ваши подчиненные работают.
                                            </Notification>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default TimeOffOfSub;
