import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Button from "../../../components/Button";
import { InputSearch } from "../../../components/Inputs/inputSearch";
import AddressList from "./component/AddressList";
import Loader from "../../../components/Loader";
import { useDebounce } from "../../../shared/helpers/useDebounce";
import { ISearchAddress } from "../../../typec/IInputs";
import { FilterCity } from "./component/FilterCity";

const Adress = (props) => {
    const { addData, actionElement = () => null, clearError = () => null, error, stores, dataFilter, prev, setPrev } = props;
    const [filter, setFilter] = useState<boolean>(true);
    const [searchAdr, setSearchAdr] = useState("");
    // const [prev, setPrev] = useState({});
    const [data, setData] = useState({
        stores: stores,
    });

    const [err, setErr] = useState("");

    const [dataAddress, setDataAddress] = useState<any>([]);
    const [load, setLoad] = useState<boolean>(false);

    useEffect(() => {
        // if (filter == true) {
        //     setLoad(true);
        postAddress();
        // } else {
        //     setLoad(false);
        //     setDataAddress([]);
        // }
    }, [filter]);

    // useEffect(() => {
    const postAddress = (substr = "", city = "", region = "") => {
        setLoad(true);
        fetch("/dev/vacation_rose_stores", {
            method: "POST",
            body: JSON.stringify({
                substr: substr,
                city: city,
                region: region,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    // Заявление сформировано
                    console.log(json);
                    setDataAddress(json.data);
                    setLoad(false);
                    // goToMain();
                } else {
                    // Заявление не сформировано
                    console.log(json);
                    setLoad(true);
                }
            })
            .catch((e) => {
                console.error(e);
            });
        setLoad(false);
    };
    // }, [load]);

    const clearErr = () => {
        clearError();
        setErr("");
    };
    const editData = (key, element) => {
        setData(element);
        clearError();
    };
    const saveData = (key, element) => {
        addData(key, element);
        actionElement();
    };

    const changeSearchHandler = useDebounce((value) => {
        if (value.length >= 3) {
            setSearchAdr(value || "");
            postAddress(value || "");
        } else if (value.length == 0) {
            setSearchAdr("");
            postAddress("");
        }
        setPrev({});
    }, 850);

    const changeSearch = (e) => {
        changeSearchHandler(e.target.value);
    };

    const switchFilter = () => {
        if (filter) {
            setFilter(false);
        } else {
            setFilter(true);
        }
        setPrev({});
    };
    console.log(dataAddress);
    return (
        <>
            <div className={styles.adress}>
                {filter ? (
                    <>
                        <div className={styles.adress_zagolovok}>
                            Выбери любой магазин из списка ниже. Фильтр поможет выбрать нужный тебе город, чтобы найти ближайший магазин. Нажми на магазин, чтобы выбрать его.
                        </div>
                        {load ? (
                            <Loader></Loader>
                        ) : (
                            <div className={styles.list}>
                                <AddressList name="stores" errorMessage={error?.Error} selectedStore={prev} editData={editData} items={dataAddress} setPrev={setPrev} />
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <p className={styles.adress_title_search}>Давайте найдём магазин в поиске</p>
                        <InputSearch onChange={changeSearch} value={searchAdr} name="place" clearError={clearError} placeholder="Адрес, ТЦ" type={"search"} />
                        {load ? (
                            <Loader type="search"></Loader>
                        ) : (
                            <div className={styles.list}>
                                <AddressList name="stores" errorMessage={error?.Error} selectedStore={prev} editData={editData} items={dataAddress} setPrev={setPrev} />
                            </div>
                        )}
                    </>
                )}
                <div className={styles.putScr_btn}>
                    {filter ? (
                        Object.keys(prev).length ? (
                            <Button onClick={() => saveData("stores", data)} type="save_not_margin">
                                Далее
                            </Button>
                        ) : (
                            <FilterCity
                                key="FilterLocation"
                                errorMessage={error?.Error}
                                value={data.stores}
                                editData={editData}
                                name="FilterLocation"
                                items={dataFilter?.regions}
                                postAddress={postAddress}
                                titleText="Фильтр магазинов"
                            />
                        )
                    ) : Object.keys(prev).length ? (
                        <Button onClick={() => saveData("stores", data)} type="save_not_margin">
                            Далее
                        </Button>
                    ) : (
                        <Button type="save_not_margin">Выберите магазин из списка</Button>
                    )}
                    <Button onClick={switchFilter} type="cancel_grey">
                        {filter ? "Поиск магазинов" : "Перейти к списку магазинов"}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default Adress;
