import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Navigation from "../../components/Navigation";
import { NavigationContext } from "../../template";
import { useNavigate } from "react-router-dom";
import LeaveItem from "./components/LeaveItem";
import ChildrenIcon from "../../icons/children_icon.svg";
import { InputBill, InputCalendar, InputError, InputMonthYear, InputRadio, InputText, InputTitle } from "../../components/Inputs";
import NewPhotoButtonLimit from "../ClietSupport/NewAppeal/components/NewPhotoButtonLimit";
import Button from "../../components/Button";
import SlideScreen from "../../components/SlideScreen";
import Notification from "../../components/Notification";
import VacationCheck2 from "../Vacation/components/VacationCheck/index2";
import clsx from "clsx";
import OneHalfYear from "./components/OneHalfYear";
import BankCardAndCheck from "./components/BankCard";

export interface IDataChild {
    bankCardNumber: string;
    bankCardEndDate: string;
    actualChildren: [
        {
            name: string; // ФИО ребенка
            birthdate: string; // Дата рождения ребенка в формате "ДД.ММ.ГГГГ"
            files: [];
        }
    ];
    previousChildren: [
        {
            name: string;
            birthdate: string;
        }
    ];
    previousChildrenFiles: [];
}

const MaternityLeave = (): ReactElement => {
    const context = useContext(NavigationContext);
    const [viewLeave, setViewLeave] = useState(0);
    const [viewBank, setViewBank] = useState(false);
    const [viewFinish, setViewFinish] = useState(false);
    const [viewBankSlider, setViewBankSlider] = useState(false);
    const [error, setError] = useState<any>();
    const [loadBtn, setLoadBtn] = useState<any>();
    const [cardSelect, setCardSelect] = useState<boolean>();

    const navigate = useNavigate();
    const prev = () => {
        if (viewFinish) setViewFinish(false);
        else if (viewBank) setViewBank(false);
        else if (viewMoreChild) setViewMoreChild(false);
        else navigate("/");
    };
    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };

    // функции и переменные связанные с отпуском по уходу до 1,5 года
    const [oneСhild, setOneChild] = useState<boolean>();
    const [viewMoreChild, setViewMoreChild] = useState<boolean>();
    const [indexChild, setIndexChild] = useState<number>(0);
    const [indexChildPrevious, setIndexChildPrevious] = useState<number>(0);
    const [viewSlider, setViewSlider] = useState(false);
    const [dataChild, setDataChild] = useState({
        bankCardNumber: "",
        bankCardEndDate: "",
        actualChildren: [
            {
                name: "", // ФИО ребенка
                birthdate: "", // Дата рождения ребенка в формате "ДД.ММ.ГГГГ"
                files: [],
            },
        ],
        previousChildren: [
            {
                name: "",
                birthdate: "",
            },
        ],
        previousChildrenFiles: [],
    });

    const createError = (key, element) => {
        setError((prevState) => {
            return {
                ...prevState,
                [key]: element,
            };
        });
    };
    const clearError = () => {
        setError({});
    };

    const createLoad = (key) => {
        setLoadBtn((prevState) => {
            return {
                ...prevState,
                [key]: true,
            };
        });
    };
    const clearLoad = () => {
        setLoadBtn({});
    };

    const editBank = (key, element) => {
        setDataChild((prevState) => {
            return {
                ...prevState,
                [key]: element,
            };
        });
    };
    const addPhotoActualChildren = (element, index) => {
        setDataChild((prevState) => {
            const newData = prevState.actualChildren.map((el, i) => {
                if (index == i) {
                    return {
                        name: el.name, // ФИО ребенка
                        birthdate: el.birthdate, // Дата рождения ребенка в формате "ДД.ММ.ГГГГ"
                        files: element,
                    };
                } else {
                    return {
                        name: el.name, // ФИО ребенка
                        birthdate: el.birthdate, // Дата рождения ребенка в формате "ДД.ММ.ГГГГ"
                        files: el.files,
                    };
                }
            });
            return {
                ...prevState,
                actualChildren: newData.length > 0 ? newData : [...prevState.actualChildren],
            };
        });
        clearError();
    };
    const editActualChildren = (key, element, index) => {
        setDataChild((prevState) => {
            const newData = prevState.actualChildren.map((el, i) => {
                if (index == i) {
                    return {
                        ...prevState.actualChildren[i],
                        [key]: element,
                    };
                } else {
                    return {
                        ...prevState.actualChildren[i],
                    };
                }
            });
            return {
                ...prevState,
                actualChildren: newData,
            };
        });
        clearError();
    };
    const addActualChildren = () => {
        setDataChild((prevState) => {
            const newData = prevState.actualChildren;
            newData.push({
                name: "", // ФИО ребенка
                birthdate: "", // Дата рождения ребенка в формате "ДД.ММ.ГГГГ"
                files: [],
            });
            return {
                ...prevState,
                actualChildren: newData,
            };
        });
        setIndexChild(dataChild.actualChildren.length);
    };
    const deleteActualChildren = (index, bool = false) => {
        if ((dataChild.actualChildren[index].name == "" && dataChild.actualChildren[index].birthdate == "" && dataChild.actualChildren[index].files.length == 0) || bool)
            setDataChild((prevState) => {
                const newData = prevState.actualChildren.filter((el, i) => i != index);
                return {
                    ...prevState,
                    actualChildren: newData,
                };
            });
    };
    const addPhotoPreviousChildren = (element) => {
        setDataChild((prevState) => {
            return {
                ...prevState,
                previousChildrenFiles: element,
            };
        });
        clearError();
    };
    const editPreviousChildren = (key, element, index) => {
        setDataChild((prevState) => {
            const newData = prevState.previousChildren.map((el, i) => {
                if (index == i) {
                    return {
                        ...prevState.previousChildren[i],
                        [key]: element,
                    };
                } else {
                    return {
                        ...prevState.previousChildren[i],
                    };
                }
            });
            return {
                ...prevState,
                previousChildren: newData,
            };
        });
        clearError();
    };
    const addPreviousChildren = () => {
        setDataChild((prevState) => {
            const newData = prevState.previousChildren;
            newData.push({
                name: "", // ФИО ребенка
                birthdate: "", // Дата рождения ребенка в формате "ДД.ММ.ГГГГ"
            });
            return {
                ...prevState,
                previousChildren: newData,
            };
        });
        setIndexChildPrevious(dataChild.previousChildren.length);
    };
    const deletePreviousChildren = (index, bool = false) => {
        if ((dataChild.previousChildren[index].name == "" && dataChild.previousChildren[index].birthdate == "") || bool)
            setDataChild((prevState) => {
                const newData = prevState.previousChildren.filter((el, i) => i != index);
                return {
                    ...prevState,
                    previousChildren: newData,
                };
            });
    };

    const getChildNames = (element) => {
        return element.map((el) => {
            return [el.name, ` ${el.birthdate}`];
        });
    };

    const getXXXNumCard = (string) => {
        let res = "";
        let j = 0;
        if (string.includes("*")) {
            for (let i = 0; i < string.length; i++) {
                // debugger;
                // if (i != 9 && i > 4 && i < 14) {
                //     res = res + "x";
                // } else res = res + string[i];
                j++;
                if (i > 3 && i < 12) {
                    res += "x";
                } else res += string[i];
                if ((i + 1) % 4 == 0 && string[j] != " ") {
                    res += " ";
                    j--;
                }
            }
        } else {
            for (let i = 0; i < string.length; i++) {
                if (i != 9 && i > 4 && i < 14) {
                    res += "x";
                } else res += string[i];
            }
        }
        return res.trim();
    };

    const getAllPhotos = (element) => {
        const phArr = element?.actualChildren?.map((el) => {
            return [...el.files];
        });
        phArr.push(...element?.previousChildrenFiles);
        return phArr.flat();
    };

    // функции API
    const testCardMIR = (bank_card_number, finish = false, transition = true) => {
        createLoad("bank");
        fetch("/dev/vacation_vac_child_new", {
            method: "PUT",
            body: JSON.stringify({
                BankCardNumber: bank_card_number,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    setViewBankSlider(false);
                    if (transition) {
                        setViewFinish(true);
                    } else {
                        setCardSelect(false);
                    }
                } else {
                    createError("bank", json.Error);
                    if (!finish) {
                        setViewFinish(true);
                        setViewBankSlider(false);
                        // setCardSelect(true);
                    }
                }
                clearLoad();
            });
    };

    const postDataChild = (bank_card_number, bank_card_end_date, actual_children, previous_children, previous_children_files) => {
        createLoad("finish");
        fetch("/dev/vacation_vac_child_new", {
            method: "POST",
            body: JSON.stringify({
                BankCardNumber:
                    cardSelect || (cardSelect == undefined && context.userdata?.bank_card) ? context?.userdata?.bank_card_number : bank_card_number?.replaceAll(" ", ""),
                BankCardEndDate: cardSelect || (cardSelect == undefined && context.userdata?.bank_card) ? context?.userdata?.bank_card_end_date : bank_card_end_date,
                ActualChildren:
                    actual_children[0].birthdate != ""
                        ? actual_children?.map((el) => {
                              return {
                                  Name: el.name,
                                  Birthdate:
                                      Number(el.birthdate?.slice(6)) > Number(`${new Date().getFullYear()}`.slice(2))
                                          ? [el.birthdate?.split(".")[0], el.birthdate?.split(".")[1], ["19", ...el.birthdate?.split(".")[2]].join("")].join(".")
                                          : [el.birthdate?.split(".")[0], el.birthdate?.split(".")[1], ["20", ...el.birthdate?.split(".")[2]].join("")].join("."),
                                  Files: el.files?.map((e, i) => {
                                      return {
                                          Name: `screen_${i}.${e.data.split(";")[0].split("/")[1]}`,
                                          Content: e.data,
                                      };
                                  }),
                              };
                          })
                        : null,
                PreviousChildren:
                    previous_children[0].name != ""
                        ? previous_children?.map((el) => {
                              return {
                                  Name: el?.name,
                                  Birthdate:
                                      el.birthdate.length > 0
                                          ? Number(el?.birthdate?.slice(6)) > Number(`${new Date().getFullYear()}`.slice(2))
                                              ? [el?.birthdate?.split(".")[0], el?.birthdate?.split(".")[1], ["19", ...el?.birthdate?.split(".")[2]].join("")].join(".")
                                              : [el?.birthdate?.split(".")[0], el?.birthdate?.split(".")[1], ["20", ...el?.birthdate?.split(".")[2]].join("")].join(".")
                                          : "",
                              };
                          })
                        : [],
                PreviousChildrenFiles: previous_children_files?.map((el, i) => {
                    return {
                        Name: `screen_${i}.${el.data.split(";")[0].split("/")[1]}`,
                        Content: el.data,
                    };
                }),
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    navigate("/requestsList");
                } else {
                    createError("finish", json.Error);
                }
                clearLoad();
            });
    };

    useEffect(() => {
        if (viewBankSlider || viewSlider) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [viewBankSlider, viewSlider]);

    return (
        <>
            <Navigation prev={prev} goToMain={goToMain}></Navigation>

            {viewLeave == 0 && !viewBank ? (
                <div className={styles.hero}>
                    <p className={styles.hero_main_heading}>Декретный отпуск</p>
                    <p className={styles.hero_second_title}>Выберите тип отпуска</p>
                    <div className={styles.hero_list}>
                        <LeaveItem Icon={ChildrenIcon} text="Отпуск по уходу за ребенком до&nbsp;1,5&nbsp;лет" onClick={() => setViewLeave(3)} />
                    </div>
                </div>
            ) : viewLeave == 1 && !viewBank ? (
                // БЛ 140 КД
                <></>
            ) : viewLeave == 2 && !viewBank ? (
                // БЛ 16 КД
                <></>
            ) : viewLeave == 3 && !viewBank ? (
                // до 1,5 года
                <>
                    <OneHalfYear
                        addActualChildren={addActualChildren}
                        addPhotoActualChildren={addPhotoActualChildren}
                        addPhotoPreviousChildren={addPhotoPreviousChildren}
                        addPreviousChildren={addPreviousChildren}
                        dataChild={dataChild}
                        deleteActualChildren={deleteActualChildren}
                        deletePreviousChildren={deletePreviousChildren}
                        editActualChildren={editActualChildren}
                        editPreviousChildren={editPreviousChildren}
                        indexChild={indexChild}
                        indexChildPrevious={indexChildPrevious}
                        oneСhild={oneСhild}
                        setIndexChild={setIndexChild}
                        setIndexChildPrevious={setIndexChildPrevious}
                        setOneChild={setOneChild}
                        setViewBank={setViewBank}
                        setViewMoreChild={setViewMoreChild}
                        setViewSlider={setViewSlider}
                        viewLeave={viewLeave}
                        viewMoreChild={viewMoreChild}
                        viewSlider={viewSlider}
                    />
                </>
            ) : viewBank ? (
                //заполнение карты
                <>
                    <BankCardAndCheck
                        cardSelect={cardSelect}
                        clearError={clearError}
                        dataChild={dataChild}
                        editBank={editBank}
                        error={error}
                        getAllPhotos={getAllPhotos}
                        getChildNames={getChildNames}
                        getXXXNumCard={getXXXNumCard}
                        loadBtn={loadBtn}
                        postDataChild={postDataChild}
                        setCardSelect={setCardSelect}
                        setViewBankSlider={setViewBankSlider}
                        setViewFinish={setViewFinish}
                        testCardMIR={testCardMIR}
                        viewBankSlider={viewBankSlider}
                        viewFinish={viewFinish}
                    />
                </>
            ) : null}
        </>
    );
};

export default MaternityLeave;
