import "./index.scss";
import Menu from "./components/Menu";
import menuData from "./components/Menu/Menu";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Test from "./pages/NavigationPages";
import React, { createContext, useState, useEffect } from "react";
import Loader from "./components/Loader";
import RequestsList from "./pages/RequestsList";
import Approve from "./pages/Approve";
import VacationExpense from "./pages/Vacation/VacationExpense";
import VacationIT from "./pages/Vacation/VacationIT";
import TranspositionVacation from "./pages/Vacation/TranspositionVacation";
import PODetails from "./pages/PODetails";
import DismissalStaff from "./pages/DismissalStaff";
import NewAppeals from "./pages/ClietSupport/NewAppeal";
import MyAppeals from "./pages/ClietSupport/MyAppeals";
import PaySlip from "./pages/DocumentRequest/PaySlip";
import Rose from "./pages/Rose";
import VacationBalances from "./pages/DocumentRequest/VacationBalances";
import VacationSchedule from "./pages/DocumentRequest/VacationSchedule";
import VacationOfSubordinates from "./pages/DocumentRequest/VacationsOfSubordinates";
import AssignedInventory from "./pages/DocumentRequest/AssignedInventory";
import AssignedInventoryOfSub from "./pages/DocumentRequest/AssignedInventoryOfSub";
import PaidVacation from "./pages/Vacation/PaidVacation";
import ChangePersonalData from "./pages/ChangePersonalData";
import Inquiries from "./pages/DocumentRequest/Inquiries";
import { YMInitializer } from "react-yandex-metrika";
import { Redirect } from "./Redirect";
import DeductionForChildren from "./pages/DeductionForChildren";
import TimeOff from "./pages/TimeOff";
import TimeOffOfSub from "./pages/DocumentRequest/TimeOffOfSub";

export const NavigationContext = createContext<{
    prev: () => void;
    goToMain: () => void;
    click: (menu: any) => void;
    currentMenu: any;
    history: [];
    userdata: any;
    userdat: any;
    guest: any;
    idTgBotGroup: Array<string>;
    dataRedirect: any;
    clearDataRedirect: () => void;
}>({
    prev: () => null,
    goToMain: () => null,
    click: () => null,
    currentMenu: [],
    history: [],
    userdata: {},
    userdat: {},
    guest: false,
    idTgBotGroup: [],
    dataRedirect: null,
    clearDataRedirect: () => null,
});

import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/legacy/build/pdf.worker.min.js", import.meta.url).toString();
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import MaternityLeave from "./pages/MaternityLeave";

const Template = () => {
    const [load, setLoad] = useState(true);
    const [hiddenLoad, setHiddenLoad] = useState(false);
    const [jsonIsRes, setJsonIsRes] = useState({});
    const [jsonRes, setJsonRes] = useState<any>({});
    const [currentMenu, setCurrentMenu] = useState<[]>(menuData);
    const [history, setHistory] = useState<any>([]);
    const idTgBotGroup = ["429452300", "88504731", "1827844160", "373206271", "385329543", "396944172", "399019067", "1108069416", "450998839", "679612460"]; // список tg_id команды tg bot
    const click = (menu) => {
        if (menu.children && menu.children.length) {
            setCurrentMenu(menu.children);
            setHistory([...history, currentMenu]);
            console.log("click");
        }
    };
    const prev = () => {
        if (history.length) {
            const newIndex = history.length - 1;
            setCurrentMenu(history[newIndex]);
            setHistory(history.slice(0, newIndex));
            console.log("prev back");
        }
        setHiddenLoad(true);
    };

    const goToMain = () => {
        setCurrentMenu(menuData);
        setHistory([]);
        console.log("go main");
        setLoad(false);
        setHiddenLoad(true);
    };

    const expandWebApp = () => {
        const tg = window.Telegram.WebApp;
        tg.expand();
        tg.MainButton.text = "Placeholder";
        tg.MainButton.textColor = tg.themeParams.secondary_bg_color;
        // tg.MainButton.text = "secondary_bg";
        // tg.MainButton.textColor = "#00ff00";
        tg.MainButton.color = tg.themeParams.secondary_bg_color;
        tg.MainButton.show();
        tg.MainButton.disable();
        // tg.MainButton.enable();
        // tg.onEvent("mainButtonClicked", function () {
        //     let rnd = Math.floor(Math.random() * 13);
        //     switch (rnd) {
        //         case 0:
        //             tg.MainButton.color = tg.themeParams.bg_color;
        //             tg.MainButton.text = "bg";
        //             break;
        //         case 1:
        //             tg.MainButton.color = tg.themeParams.text_color;
        //             tg.MainButton.text = "text";
        //             break;
        //         case 2:
        //             tg.MainButton.color = tg.themeParams.hint_color;
        //             tg.MainButton.text = "hint";
        //             break;
        //         case 3:
        //             tg.MainButton.color = tg.themeParams.link_color;
        //             tg.MainButton.text = "link";
        //             break;
        //         case 4:
        //             tg.MainButton.color = tg.themeParams.button_color;
        //             tg.MainButton.text = "button";
        //             break;
        //         case 5:
        //             tg.MainButton.color = tg.themeParams.button_text_color;
        //             tg.MainButton.text = "button_text";
        //             break;
        //         case 6:
        //             tg.MainButton.color = tg.themeParams.secondary_bg_color;
        //             tg.MainButton.text = "secondary_bg";
        //             break;
        //         case 7:
        //             tg.MainButton.color = tg.themeParams.header_bg_color;
        //             tg.MainButton.text = "header_bg";
        //             break;
        //         case 8:
        //             tg.MainButton.color = tg.themeParams.accent_text_color;
        //             tg.MainButton.text = "accent_text";
        //             break;
        //         case 9:
        //             tg.MainButton.color = tg.themeParams.section_bg_color;
        //             tg.MainButton.text = "section_bg";
        //             break;
        //         case 10:
        //             tg.MainButton.color = tg.themeParams.section_header_text_color;
        //             tg.MainButton.text = "section_header_text";
        //             break;
        //         case 11:
        //             tg.MainButton.color = tg.themeParams.subtitle_text_color;
        //             tg.MainButton.text = "subtitle_text";
        //             break;
        //         case 12:
        //             tg.MainButton.color = tg.themeParams.destructive_text_color;
        //             tg.MainButton.text = "destructive_text";
        //             break;
        //         default:
        //             console.log(rnd);
        //     }
        //     rnd = Math.floor(Math.random() * 13);
        // });
        // tg.enableClosingConfirmation();
    };

    const auth = (): string => {
        expandWebApp();
        const boss_access = new URLSearchParams(window.location.search).get("boss_access");
        if (boss_access === "true") {
            return "88504731";
        } else {
            const auth = window.Telegram.WebApp?.initDataUnsafe?.user?.id;
            return auth != undefined ? `${auth}` : "123";
        }
    };

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_auth_new", {
                method: "PUT",
                body: JSON.stringify({
                    TgData: window.Telegram.WebApp.initData,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        // проверка на открытие из приложение telegram
                        fetch("/dev/vacation_auth_new/", {
                            method: "POST",
                            body: JSON.stringify({
                                tg_id: auth(), // "429452300", //"1827844160",
                            }),
                        })
                            .then((res) => res.json())
                            .then((json) => {
                                console.log(json);
                                setJsonRes(json);
                                fetch("/dev/vacation_auth_new/", {
                                    method: "GET",
                                })
                                    .then((res) => res.json())
                                    .then((json) => {
                                        setJsonIsRes(json);
                                        console.log(json);
                                        setLoad(false);
                                    });
                            });
                    } else {
                        setLoad(false);
                        setJsonRes({
                            res: false,
                            err: "Выявлен вход вне приложения Telegram.", // Текст ошибки
                            errc: "ER_HRM",
                        });
                        setJsonIsRes({ res: false });
                    }
                });
        }
        if (hiddenLoad) {
            fetch("/dev/vacation_auth_new/", {
                method: "GET",
            })
                .then((res) => res.json())
                .then((json) => {
                    setJsonIsRes(json);
                    console.log(json);
                    setLoad(false);
                    setHiddenLoad(false);
                });
        }
    }, [load, hiddenLoad]);
    useEffect(() => {
        console.log(load);
        console.log(history);
        if (!history?.length && !load) {
            setLoad(false);
            setHiddenLoad(true);
        }
    }, [history]);

    // переадресация для вычетов
    const vidPersonalnyhDannyh = "Изменение семейного положения без смены паспорта";
    const prichina = "Рождение/усыновление ребенка";
    const [redirectInChange, setRedirectInChange] = useState({
        vidPersonalnyhDannyh: "",
        prichina: "",
    });
    const redirectOn = () => {
        setRedirectInChange({
            vidPersonalnyhDannyh: vidPersonalnyhDannyh,
            prichina: prichina,
        });
        setTimeout(() => {
            setRedirectInChange({ vidPersonalnyhDannyh: "", prichina: "" });
        }, 200);
    };

    // переадресация по заявлениям
    const [dataRedirect, setDataRedirect] = useState<any>();

    const setRedirect = (element) => {
        setDataRedirect(element);
    };

    const clearDataRedirect = () => {
        setDataRedirect(null);
    };

    return (
        <NavigationContext.Provider
            value={{
                prev,
                goToMain,
                guest: false, // jsonRes.res == false && jsonRes.errc !== "ER_HRMCN" && jsonRes.errc !== "ER_HRMDC" && jsonRes.errc !== "ER_HRMUN" && jsonRes.errc !== "ER_HRM",
                click,
                currentMenu,
                history,
                userdata: jsonIsRes,
                userdat: jsonRes,
                idTgBotGroup: idTgBotGroup,
                dataRedirect,
                clearDataRedirect,
            }}
        >
            <div className="App">
                {load ? (
                    <>
                        <Loader></Loader>
                        <BrowserRouter basename="/">
                            <Routes>
                                <Route path="/" Component={() => <Menu></Menu>} />
                            </Routes>
                        </BrowserRouter>
                    </>
                ) : (
                    <>
                        {!idTgBotGroup.includes(auth()) ? (
                            <YMInitializer
                                accounts={[96725155]}
                                options={{ webvisor: true, defer: true, clickmap: true, tarckLinks: true, accurateTrackBounce: true }}
                                version="2"
                            />
                        ) : null}
                        <BrowserRouter basename="/">
                            <Redirect auth={auth()} idTgBotGroup={idTgBotGroup} setRedirect={setRedirect} />
                            <Routes>
                                <Route path="/" element={<Menu />} />
                                <Route path="/test/" element={<Test />} />
                                <Route path="/vacation/" element={<PaidVacation />} />
                                <Route path="/requestsList/" element={<RequestsList />} />
                                <Route path="/approve/" element={<Approve />} />
                                <Route path="/vacationExpense/" element={<VacationExpense />} />
                                <Route path="/vacationIT/" element={<VacationIT />} />
                                <Route path="/vacationTransposition/" element={<TranspositionVacation />} />
                                <Route path="/PODetails/" element={<PODetails />} />
                                <Route path="/dismissalStaf/" element={<DismissalStaff />} />
                                <Route
                                    path="/changePersonalDate/"
                                    element={<ChangePersonalData vidPersonalnyhDannyh={redirectInChange.vidPersonalnyhDannyh} prichina={redirectInChange.prichina} />}
                                />
                                <Route path="/newAppeals/" element={<NewAppeals />} />
                                <Route path="/myAppeals/" element={<MyAppeals />} />
                                <Route path="/paySlip/" element={<PaySlip />} />
                                <Route path="/inquiries/" element={<Inquiries />} />
                                <Route path="/rose/" element={<Rose />} />
                                <Route path="/vacBalances/" element={<VacationBalances />} />
                                <Route path="/vacSchedule/" element={<VacationSchedule />} />
                                <Route path="/vacOfSubord/" element={<VacationOfSubordinates />} />
                                <Route path="/assignInventory/" element={<AssignedInventory />} />
                                <Route path="/assignInventoryOfSub/" element={<AssignedInventoryOfSub />} />
                                <Route path="/deductionForChildren/" element={<DeductionForChildren redirectOn={redirectOn} />} />
                                <Route path="/timeOff/" element={<TimeOff />} />
                                <Route path="/timeOffOfSub/" element={<TimeOffOfSub />} />
                                <Route path="/vacationChildCare/" element={<MaternityLeave />} />
                            </Routes>
                        </BrowserRouter>
                    </>
                )}
            </div>

            <script src="https://telegram.org/js/telegram-web-app.js"></script>
        </NavigationContext.Provider>
    );
};

export default Template;
