import React, { ReactElement } from "react";
import styles from "../../index.module.scss";
import { IChildrenItem } from "../../../../typec/IDeducationForChildren";
import Notification from "../../../../components/Notification";
import Button from "../../../../components/Button";

const ChildrenItem = (props: IChildrenItem): ReactElement => {
    const { name, dateBirthday, status, onClick = () => null, statusText, onRedirect = () => null } = props;
    return (
        <>
            <div className={styles.doc_item} onClick={() => (!status ? null : onRedirect())}>
                <p className={styles.doc_item_text}>{name}</p>
                <div className={styles.doc_item_container}>
                    <p className={styles.doc_item_title}>{dateBirthday}</p>
                    {statusText ? (
                        <>
                            <Notification
                                type={
                                    statusText.includes("Оформлен") || statusText.includes("Завершен")
                                        ? "graph"
                                        : statusText.includes("Отмен") || statusText.includes("Отказ")
                                        ? "red"
                                        : "coordination"
                                }
                            >
                                {statusText}
                            </Notification>
                        </>
                    ) : (
                        <div />
                    )}
                </div>
                {!status ? (
                    <>
                        <div className={styles.doc_item_btn}>
                            <Button type="change_not_vw" icon="change" onClick={() => onClick()}>
                                Оформить вычет
                            </Button>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
};

export default ChildrenItem;
