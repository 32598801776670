import React, { ReactElement, useContext, useEffect, useState } from "react";
import VacationList from "../components/VacationList";
import styles from "../index.module.scss";
import Navigation from "../../../components/Navigation";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import SlideScreen from "../../../components/SlideScreen";
import Button from "../../../components/Button";
import VacationCheck from "../components/VacationCheck";
import PaidVacNull from "./components/PaidVacNull";
import { IUserVacation } from "../../../typec/IVacations";
import VacationEdit from "../components/VacationEdit";
import PaidVacAdd from "./components/PaidVacAdd";
import VacationItem from "../components/VacationItem";
import VacationItemShell from "./components/VacationItemShell";

const PaidVacation = (): ReactElement => {
    const context = useContext(NavigationContext);
    const [load, setLoad] = useState(true);
    const [showAddVac, setShowAddVac] = useState(false);
    const [userVacation, setUserVacation] = useState<IUserVacation>();
    const [putInfo, setPutInfo] = useState({
        headerInfo: {
            Date: "",
            Number: "",
        },
        startDay: "",
        finishDay: "",
        quantityDay: "",
        typeVacation: "",
        periodArr: [""],

        error: false,
    });
    const [counterDay, setCounterDay] = useState(0);
    const [screenCheck, setScreenCheck] = useState<boolean>(false);
    const [divadeBool, setDivadeBool] = useState(false);

    const navigate = useNavigate();
    const prev = () => {
        if (screenCheck) {
            setScreenCheck(false);
        } else navigate("/");
        clearVacationPOST();
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };

    const dateNow = (): string => {
        const year = new Date().getFullYear();
        const month = new Date().getMonth();
        const day = new Date().getDate();
        return `${day}.${month + 1}.${year}`;
    };

    const switchDivadeBool = () => {
        divadeBool ? setDivadeBool(false) : setDivadeBool(true);
        clearVacationPOST();
    };

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_vac_shed_new", {
                method: "GET",
            })
                .then((res) => res.json())
                .then((json) => {
                    setUserVacation(json);
                    fetch("/dev/vacation_leftover_new", {
                        method: "POST",
                        body: JSON.stringify({
                            DateLeftover: dateNow(),
                        }),
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                    })
                        .then((res) => res.json())
                        .then((json) => {
                            if (json.res) {
                                setCounterDay(json.DaysLeft);
                                setLoad(false);
                            }
                        });
                });
        }
    }, [load]);

    const switchItem = () => {
        if (screenCheck) {
            setScreenCheck(false);
            clearPutInfo();
            setLoad(true);
        } else {
            setScreenCheck(true);
            setLoad(true);
        }
    };

    const editPutInfo = (element) => {
        setPutInfo({
            ...putInfo,
            ...element,
        });
    };

    const clearPutInfo = () => {
        setPutInfo({
            headerInfo: {
                Date: "",
                Number: "",
            },
            startDay: "",
            finishDay: "",
            quantityDay: "",
            typeVacation: "",
            periodArr: [""],
            error: false,
        });
        clearVacationPOST();
    };
    const clearVacationPOST = () => {
        fetch("/dev/vacation_vac_shed_new", {
            method: "DELETE",
        }).then((res) => res.json());
    };

    const putVacation = () => {
        setLoad(true);
        fetch("/dev/vacation_vac_shed_new", {
            method: "PUT",
            body: JSON.stringify({
                Transfer: false,
            }),
        })
            .then((res) => res.json())
            .then((json) => {
                setLoad(false);
                if (json.res == true) {
                    setPutInfo({
                        ...putInfo,
                        headerInfo: {
                            Date: json.Date,
                            Number: json.Number,
                        },
                        error: false,
                    });
                    switchItem();
                } else {
                    setPutInfo({
                        ...putInfo,
                        error: true,
                    });
                }
            });
    };

    const loadHero = () => {
        setLoad(true);
    };

    const isNullVac = (): boolean => {
        let bool = false;
        const schedule = userVacation?.user_schedule;
        if (`${schedule}` == "" || userVacation?.res == false) {
            const vacations = userVacation?.user_vacations
                ?.map((el) => {
                    return el.Status;
                })
                .filter((value) => value != "Отменена");
            if (`${vacations}` == "" || userVacation?.res == false) {
                bool = true;
            }
        }
        return bool;
    };

    const loading = () => {
        setLoad(true);
    };

    useEffect(() => {
        console.log(putInfo);
    }, [putInfo]);

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    {!screenCheck ? (
                        <>
                            {isNullVac() ? (
                                <PaidVacNull loading={loading} userVacation={userVacation} />
                            ) : (
                                <>
                                    <div className={styles.hero_container_vacations}>
                                        <p className={styles.main_heading}>
                                            Заявление на <br /> оплачиваемый отпуск
                                        </p>
                                        <div className={styles.null_count_text}>
                                            Остатки отпусков на сегодня: <a>{counterDay}</a>
                                        </div>
                                        <p className={styles.tooltip_text}>
                                            Для редактирования отпусков перейдите в раздел <a onClick={() => navigate("/vacationTransposition")}>Перенос&nbsp;отпуска</a>
                                        </p>
                                        <VacationList
                                            typeVacation="paid"
                                            ntfChange={true}
                                            switchItem={switchItem}
                                            loadHero={loadHero}
                                            userData={userVacation}
                                            editPutInfo={editPutInfo}
                                            divadeBool={divadeBool}
                                            switchDivadeBool={switchDivadeBool}
                                        />
                                        {putInfo.finishDay != "" ? (
                                            <VacationItemShell
                                                onActivElement={() => setShowAddVac(true)}
                                                typeText="Изменить"
                                                typeItem="change"
                                                valueAmount={putInfo.quantityDay}
                                                valueStartDay={putInfo.startDay}
                                                valueFinishDay={putInfo.finishDay}
                                            />
                                        ) : userVacation?.allowed_vacation ? (
                                            <Button type="add_vacation" icon="plus-interface" onClick={() => setShowAddVac(true)}>
                                                Добавить отпуск
                                            </Button>
                                        ) : null}
                                    </div>
                                    {showAddVac ? (
                                        <SlideScreen altitudeLevel="full" titleText="Оплачиваемый отпуск" onClose={() => setShowAddVac(false)}>
                                            <PaidVacAdd
                                                valueAmount={putInfo.quantityDay}
                                                valueStartDay={putInfo.startDay}
                                                successPost={() => setShowAddVac(false)}
                                                loading={loading}
                                                editPutInfo={editPutInfo}
                                                userVacation={userVacation}
                                            />
                                        </SlideScreen>
                                    ) : null}
                                </>
                            )}
                            {putInfo.finishDay != "" ? (
                                <SlideScreen altitudeLevel="service">
                                    <Button type="save_not_margin" onClick={switchItem}>
                                        Сформировать заявление
                                    </Button>
                                </SlideScreen>
                            ) : null}
                        </>
                    ) : null}
                    {screenCheck ? (
                        <>
                            <VacationCheck
                                nameStaff={context.userdata.name}
                                position={context.userdata.job_name}
                                supervisor={context.userdata.boss_name}
                                statementDay={putInfo.headerInfo.Date}
                                finishDay={putInfo.finishDay}
                                startDay={putInfo.startDay}
                                periodArr={putInfo.periodArr}
                                quantityDay={putInfo.quantityDay}
                                typeVacation="Оплачиваемый отпуск"
                                disableDetails
                            />
                            <SlideScreen altitudeLevel="service">
                                <Button type="save_not_margin" onClick={putVacation}>
                                    Отправить на согласование
                                </Button>
                                <Button type="cancel_grey" onClick={switchItem}>
                                    Отмена
                                </Button>
                            </SlideScreen>
                        </>
                    ) : null}
                </>
            )}
        </>
    );
};

export default PaidVacation;
