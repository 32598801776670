import React, { ReactElement } from "react";
import styles from "../index.module.scss";
import { IInputRadioProps } from "../../../typec/IInputs";

const InputRadio = (props: IInputRadioProps): ReactElement => {
    const { firstTitle, secondTitle, id_key, editData, editKeyData, checked, name, nameEditItem, onClick = () => null, style, selected, value } = props;

    const callbackText = firstTitle ? firstTitle : secondTitle ? secondTitle : "";

    const edit = () => {
        if (editKeyData) {
            editKeyData(nameEditItem, secondTitle == "Да" ? true : false);
        }
        if (editData) {
            editData(callbackText);
        }
    };

    return (
        <>
            <div className={styles.inp_container} key={id_key} style={style}>
                <div onClick={() => onClick()} className={styles.inp_container_radio}>
                    <label htmlFor={`radio_${id_key}`} className={styles.inp_container_radio_label_text}>
                        {firstTitle}
                    </label>
                    <input
                        id={`radio_${id_key}`}
                        name={`radio_${name}`}
                        className={styles.inp_container_radio_btn}
                        type="radio"
                        onChange={edit}
                        checked={checked != undefined ? checked : selected != undefined && value != undefined ? selected === value : undefined}
                        value={value != undefined ? value : undefined}
                    />
                    <label htmlFor={`radio_${id_key}`} className={styles.inp_container_radio_label}>
                        {secondTitle}
                    </label>
                </div>
            </div>
        </>
    );
};

export { InputRadio };
