import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "../index.module.scss";
import Navigation from "../../../components/Navigation";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";
import TranspositionList from "./components/TranspositionList";
import SlideScreen from "../../../components/SlideScreen";
import Button from "../../../components/Button";
import { InputError } from "../../../components/Inputs";
import { INewVacations, IUserVacation } from "../../../typec/IVacations";
import VacationItem from "../components/VacationItem";
import { v4 as uuidv4 } from "uuid";
import VacationCheck from "../components/VacationCheck";

const TranspositionVacation = (): ReactElement => {
    const context = useContext(NavigationContext);
    const [load, setLoad] = useState(true);
    const [loadExternal, setLoadExternal] = useState(false);
    const [loadBtn, setLoadBtn] = useState(false);
    const [userVacation, setUserVacation] = useState<IUserVacation>();
    const [userNewVac, setNewVac] = useState<INewVacations>();
    const [putSlide, setPutSlide] = useState(false);
    const [putScreen, setPutScreen] = useState(false);
    const [putInfo, setPutInfo] = useState({
        statementDay: "",
        periods: [""],
        quantityDay: "",
    });
    const [error, setError] = useState("");
    const [divadeBool, setDivadeBool] = useState(false);
    const [disableDivade, setDisableDivade] = useState(false); // запрет пользователям изменять второй отпуск
    const [errorExternal, setErrorExternal] = useState({
        text: "",
        num: "",
    });

    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };

    const createError = (el) => {
        setErrorExternal({
            ...errorExternal,
            ...el,
        });
    };

    const clearError = (key: string) => {
        setErrorExternal({
            ...errorExternal,
            [key]: "",
        });
    };

    const switchDivadeBool = () => {
        divadeBool ? setDivadeBool(false) : setDivadeBool(true);
    };

    const switchDisableDivade = () => {
        setDisableDivade(true);
    };

    const clearVacationPOST = () => {
        fetch("/dev/vacation_vac_shed_new", {
            method: "DELETE",
        }).then((res) => res.json());
    };

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_vac_shed_new", {
                method: "GET",
            })
                .then((res) => res.json())
                .then((json) => {
                    setUserVacation(json);
                    setLoad(false);
                });
        }
    }, [load]);

    const clearNewVacations = () => {
        setNewVac(undefined);
    };
    const addVacation = (
        id: string,
        startDay: string,
        konetsDay: string,
        numberDay: number,
        valueCause: string,
        typeVacationText: string,
        oldNumber?: string,
        oldFinishDay?: string
    ) => {
        // debugger;
        fetch("/dev/vacation_cancel_new", {
            method: "PUT",
            body: JSON.stringify({
                StartDate: konetsDay.replace(".2", ".202"),
                NumberDays: numberDay,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    if (numberDay >= 100) {
                        setError("Превышен максимальный лимит дней отпуска");
                    } else {
                        setNewVac((prevState) => {
                            if (prevState) {
                                let oldStart;
                                prevState.map((el, index) => {
                                    if (el.id == id) {
                                        oldStart = prevState[index].StartDateOrigin;
                                    }
                                });
                                return [
                                    ...prevState.filter((value) => value.id != id),
                                    {
                                        id: id,
                                        StartDateOrigin: oldStart ? oldStart : startDay.split(".")[2].length == 2 ? startDay.replace(".2", ".202") : startDay,
                                        StartDateTransfer: konetsDay.split(".")[2].length == 2 ? konetsDay.replace(".2", ".202") : konetsDay,
                                        NumberDays: numberDay,
                                        valueCause: prevState[0].valueCause,
                                        valueFinishDay: json.KonetsDate.replace(".202", ".2"),
                                        NumberDaysOld: oldNumber,
                                        valueFinishDayOld: oldFinishDay,
                                        typeVacationName: typeVacationText,
                                    },
                                ];
                            } else {
                                return [
                                    {
                                        id: id,
                                        StartDateOrigin: startDay.split(".")[2].length == 2 ? startDay.replace(".2", ".202") : startDay,
                                        StartDateTransfer: konetsDay.split(".")[2].length == 2 ? konetsDay.replace(".2", ".202") : konetsDay,
                                        NumberDays: numberDay,
                                        valueCause: valueCause,
                                        valueFinishDay: json.KonetsDate.replace(".202", ".2"),
                                        NumberDaysOld: oldNumber,
                                        valueFinishDayOld: oldFinishDay,
                                        typeVacationName: typeVacationText,
                                    },
                                ];
                            }
                        });
                    }
                } else {
                    // console.log(json);
                    setNewVac((prevState) => {
                        if (prevState) {
                            let oldStart;
                            prevState.map((el, index) => {
                                if (el.id == id) {
                                    oldStart = prevState[index].StartDateOrigin;
                                }
                            });
                            return [
                                ...prevState.filter((value) => value.id != id),
                                {
                                    id: id,
                                    StartDateOrigin: oldStart ? oldStart : startDay.split(".")[2].length == 2 ? startDay.replace(".2", ".202") : startDay,
                                    StartDateTransfer: konetsDay.split(".")[2].length == 2 ? konetsDay.replace(".2", ".202") : konetsDay,
                                    NumberDays: numberDay,
                                    valueCause: prevState[0].valueCause,
                                    valueFinishDay: "",
                                    NumberDaysOld: oldNumber,
                                    valueFinishDayOld: oldFinishDay,
                                    typeVacationName: typeVacationText,
                                },
                            ];
                        } else {
                            return [
                                {
                                    id: id,
                                    StartDateOrigin: startDay.split(".")[2].length == 2 ? startDay.replace(".2", ".202") : startDay,
                                    StartDateTransfer: konetsDay.split(".")[2].length == 2 ? konetsDay.replace(".2", ".202") : konetsDay,
                                    NumberDays: numberDay,
                                    valueCause: valueCause,
                                    valueFinishDay: "",
                                    NumberDaysOld: oldNumber,
                                    valueFinishDayOld: oldFinishDay,
                                    typeVacationName: typeVacationText,
                                },
                            ];
                        }
                    });
                }
            });

        setPutSlide(true);
        divadeBool ? switchDivadeBool() : null;
        clearVacationPOST();
        setError("");
    };

    const switchPutScreen = () => {
        setLoadBtn(true);
        const periods = userNewVac?.map((el) => {
            return {
                StartDateOrigin: el.StartDateOrigin,
                StartDateTransfer: el.StartDateTransfer,
                NumberDays: el.NumberDays,
            };
        });
        fetch("/dev/vacation_vac_shed_new", {
            method: "POST",
            body: JSON.stringify({
                Transfer: true,
                Periods: periods,
                Comment: userNewVac ? userNewVac[0].valueCause : "По семейным обстоятельствам",
            }),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    console.log(json);
                    setPutInfo({
                        statementDay: json.Date,
                        quantityDay: "",
                        periods: getListPeriods(json),
                    });

                    setPutScreen(true);
                }
                setLoadBtn(false);
                setError(json.Error);
            });
    };

    const getListPeriods = (data) => {
        const arrDate: string[] = [];
        const periods: any[] = [];

        data.Schedule.map((el) => {
            if (!arrDate.includes(el.StartDate)) {
                arrDate.push(el.StartDate);
                const a: string[] = [`<s>c ${el.StartDate} по ${el.KonetsDate}</s> `];
                data.Schedule.map((element) => {
                    if (el.StartDate == element.StartDate && el.KonetsDate == element.KonetsDate) {
                        a.push(`</br>c ${element.StartDateTransfer} по ${element.KonetsDateTransfer}`, ` ${element.NumberDaysTransfer} дней`);
                    }
                });
                periods.push(a);
            }
        });
        return periods;
    };

    const putVacation = () => {
        setLoadBtn(true);
        setError("");
        fetch("/dev/vacation_vac_shed_new", {
            method: "DELETE",
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    fetch("/dev/vacation_vac_shed_new", {
                        method: "POST",
                        body: JSON.stringify({
                            Transfer: true,
                            Periods: userNewVac,
                            Comment: userNewVac ? userNewVac[0].valueCause : "По семейным обстоятельствам",
                        }),
                    })
                        .then((res) => res.json())
                        .then((json) => {
                            if (json.res) {
                                fetch("/dev/vacation_vac_shed_new", {
                                    method: "PUT",
                                    body: JSON.stringify({
                                        Transfer: true,
                                    }),
                                })
                                    .then((res) => res.json())
                                    .then((json) => {
                                        setLoad(false);
                                        if (json.res) {
                                            setPutSlide(false);
                                            setLoad(true);
                                            setNewVac(undefined);

                                            reRender();
                                        } else setError(json.Error);
                                        setLoadBtn(false);
                                        setNewVac(undefined);
                                    });
                            } else {
                                setError(json.Error);
                                setLoadBtn(false);
                            }
                        });
                }
            });
    };

    const loadHero = () => {
        setLoad(true);
    };

    const reRender = () => {
        clearNewVacations();
        setPutScreen(false);
        setPutSlide(false);
        setUserVacation(undefined);
        setDisableDivade(false);

        setTimeout(() => {
            setLoad(true);
        }, 500);
    };

    const isNullVac = (): boolean => {
        let bool = false;
        const schedule = userVacation?.user_schedule;
        if (`${schedule}` == "" || userVacation?.res == false) {
            // const vacations = userVacation?.user_vacations
            //     ?.map((el) => {
            //         return el.Status;
            //     })
            //     .filter((value) => value != "Отменена");
            // if (`${vacations}` == "" || userVacation?.res == false) {
            bool = true;
            // }
        }
        return bool;
    };

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    {putScreen ? (
                        <>
                            <VacationCheck
                                nameStaff={context.userdata.name}
                                position={context.userdata.job_name}
                                subdivision={context.userdata.subdiv_name}
                                supervisor={context.userdata.boss_name}
                                statementDay={putInfo.statementDay}
                                finishDay={""}
                                startDay={""}
                                periodArr={putInfo.periods}
                                // periodArr={getListPeriods(arr)}
                                quantityDay={putInfo.quantityDay}
                                typeVacation="Оплачиваемый отпуск"
                                disableDetails
                            />
                            <SlideScreen altitudeLevel="service">
                                {error && <InputError titleText={error} />}
                                <Button type="save_not_margin" onClick={putVacation} load={loadBtn}>
                                    Отправить на согласование
                                </Button>
                                <Button
                                    type="cancel_grey"
                                    onClick={() => {
                                        reRender();
                                    }}
                                >
                                    Отмена
                                </Button>
                            </SlideScreen>
                        </>
                    ) : (
                        <>
                            <div className={styles.hero_container_vacations}>
                                <p className={styles.main_heading}>Перенос отпуска</p>
                                <p className={styles.tooltip_text}>Отредактируйте все желаемые отпуска до отправки на согласование руководителю.</p>
                                {error ? (
                                    <div style={{ margin: "0px -16px 0 -16px" }}>
                                        <Notification type="err" icon="exclamation-mark">
                                            {error}
                                        </Notification>
                                    </div>
                                ) : null}
                                {isNullVac() ? (
                                    <Notification icon="info" type="info">
                                        <div className={styles.hero_container_vacations_ntf}>
                                            У вас нет отпусков, которые можно перенести. <br /> Проверьте свой <a onClick={() => navigate("/vacSchedule")}>график отпусков</a>
                                            <br />
                                            или поставьте заявление на <a onClick={() => navigate("/vacation")}>оплачиваемый отпуск</a>.
                                        </div>
                                    </Notification>
                                ) : (
                                    <>
                                        <TranspositionList
                                            reRender={reRender}
                                            divadeBool={divadeBool}
                                            switchDivadeBool={switchDivadeBool}
                                            loadHero={loadHero}
                                            userData={userVacation}
                                            addVacationExternal={addVacation}
                                            idVacationOnTransition={uuidv4()}
                                            typeVacation="transition"
                                            disableDivade={disableDivade}
                                            switchDisableDivade={switchDisableDivade}
                                            userVacNewList={userNewVac}
                                        />
                                        {userNewVac?.map((el) => (
                                            <VacationItem
                                                oldValueStartDay={el.StartDateOrigin}
                                                oldValueFinishDay={el.valueFinishDayOld}
                                                oldValueAmount={el.NumberDaysOld}
                                                valueAmount={`${el.NumberDays}`}
                                                valueFinishDay={el.valueFinishDay}
                                                // valueFinishDay=""
                                                valueStartDay={el.StartDateTransfer.replace(".20", ".")}
                                                idVacationOnTransition={el.id}
                                                addVacationExternal={addVacation}
                                                typeItem="graph"
                                                // postVacationExternal={postVacation}
                                                defValueStartDay={el.StartDateOrigin}
                                                loadExternal={loadExternal}
                                                errorExternal={errorExternal}
                                                clearErrorExternal={clearError}
                                                typeVacation="transition"
                                                disableDivade={true}
                                                valueCause={el.valueCause}
                                                userVacNewList={userNewVac}
                                                disableHiddenVacation={true}
                                                modifiedVacation={true}
                                                typeText={el.typeVacationName}
                                            />
                                        ))}
                                    </>
                                )}
                            </div>
                            {putSlide ? (
                                <SlideScreen altitudeLevel="service">
                                    <Button type="save_not_margin" load={loadBtn} onClick={() => switchPutScreen()}>
                                        Сформировать заявление
                                    </Button>
                                    {/* {error && <InputError titleText={error} />} */}
                                </SlideScreen>
                            ) : null}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default TranspositionVacation;
