import React from "react";
import Navigacion from "../../components/Navigation";
import { NavigationContext } from "../../template";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const Test = () => {
    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };
    return (
        <div>
            <Navigacion prev={prev} goToMain={goToMain}></Navigacion>
        </div>
    );
};

export default Test;
