import React, { useEffect, useState } from "react";
import styles from "../../index.module.scss";
import SlideScreen from "../../../../components/SlideScreen";
import Button from "../../../../components/Button";
import CalendarClear from "react-calendar";
import "./Calendar.scss";
import ArrowLeft from "../../../../icons/arrow-left-calendar.svg";
import ArrowRight from "../../../../icons/arrow-right-calendar.svg";
import { IInputCalendar } from "../../../../typec/IDocumentRequest";

const LabelCalendar = (props: IInputCalendar): React.ReactElement => {
    const { editData, name, value, onClick = () => null, titleTextSlide } = props;
    const [prev, setPrev] = useState<string | undefined>(value);
    const [showSlide, setShow] = useState({
        show: false,
        showCancel: false,
        modified: false,
    });

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const getMaketDate = (element: string, array: Array<string>): string => {
        const date = String(element).split(" ");
        const year = `${date[3]}`.replace("20", "");
        const month = array.indexOf(date[1], 0) + 1;
        const day = date[2];
        const trueFormat = `${day}.${month < 10 ? `0${month}` : month}.${year}`;
        return trueFormat;
    };

    const show = (element) => {
        setShow({
            ...showSlide,
            ...element,
        });
    };
    const click = () => {
        onClick();
        show({ show: true });
    };

    const editClearData = (element) => {
        editPrev(String(element));
    };
    const editPrev = (element) => {
        setPrev(getMaketDate(element, months));
    };
    const saveDate = (key, element) => {
        editData && editData(key, element);
        show({ show: false });
    };

    const toDate = (element: string | undefined): string | undefined => {
        let date: string | undefined = undefined;
        if (element != "" && element != undefined) {
            const year = element.split(".")[2]?.replace("2", "202");
            const month = element.split(".")[1];
            const day = element.split(".")[0];
            date = `${year}-${month}-${day}`;
        }
        return date;
    };

    useEffect(() => {
        if (showSlide.show) {
            document.body.style.overflow = "hidden";
        } else document.body.style.overflow = "auto";
    });

    return (
        <>
            <a onClick={click} className={styles.doc_title_date}>
                {value}
            </a>
            {showSlide.show ? (
                <SlideScreen show={show} titleText={titleTextSlide} altitudeLevel="calendar">
                    <div className={styles.doc_calendar}>
                        <CalendarClear
                            value={toDate(prev)}
                            onChange={(value) => editClearData(value as object)}
                            minDetail="year"
                            prev2Label={null}
                            prevLabel={<ArrowLeft />}
                            next2Label={null}
                            nextLabel={<ArrowRight />}
                            navigationLabel={({ label }) => `${label}`.replace("г.", "")}
                        />
                        <div style={{ height: "48px", margin: "0px 0px 20px 0px" }}>
                            <Button onClick={() => saveDate(name, prev)} disabled={!prev} type="save_not_margin">
                                Выбрать
                            </Button>
                        </div>
                    </div>
                </SlideScreen>
            ) : null}
        </>
    );
};

export { LabelCalendar };
