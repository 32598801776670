import React, { ReactElement, useState } from "react";
import { InputCalendar, InputDropDown, InputError, InputRadio, InputTextArea, InputTitle } from "../../../../components/Inputs";
import SlideScreen from "../../../../components/SlideScreen";
import Button from "../../../../components/Button";
import styles from "./index.module.scss";
import Notification from "../../../../components/Notification";

export interface IQuestionsList {
    HasADFS: boolean;
    homeScreenData: any;
    actionElement?: () => void;
    reasons: any;
    addData: (key, element) => void;
    error?: any;
    clearError?: () => void;
    minDate?: Date;
    current?: any;
    empProbEnd: boolean;
}

const HomeUser = (props: IQuestionsList): ReactElement => {
    const { actionElement = () => null, reasons, current, addData, homeScreenData, clearError = () => null, error, empProbEnd } = props;
    const [data, setData] = useState(homeScreenData);

    const editData = (key, element) => {
        setData({
            ...data,
            [key]: element,
        });
        clearError();
    };
    const saveData = (key, element) => {
        addData(key, element);
        actionElement();
    };
    const dateAsArray = current.Date.split(".");
    const dateAsDate = new Date(Number(dateAsArray[2]), Number(dateAsArray[1]) - 1, Number(dateAsArray[0]));

    const getRecDate = (bool: boolean, date): string => {
        let data: Date;
        const month = ["января", "февраля", "апреля", "марта", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
        const min = bool ? 14 : 3;
        // new Date(Number(current.Date.split(".")[2]), Number(current.Date.split(".")[1]) - 1, Number(current.Date.split(".")[0])).getDay() == 0 ? ;
        if (dateAsDate.getDay() === 0) {
            data = new Date(Number(date.split(".")[2]), Number(date.split(".")[1]) - 1, Number(date.split(".")[0]) + min - 2);
        } else if (dateAsDate.getDay() === 6) {
            data = new Date(Number(date.split(".")[2]), Number(date.split(".")[1]) - 1, Number(date.split(".")[0]) + min - 1);
        } else {
            data = new Date(Number(date.split(".")[2]), Number(date.split(".")[1]) - 1, Number(date.split(".")[0]) + min);
        }
        return `${data.getDate()} ${month[data.getMonth()]} ${data.getFullYear()}`;
    };
    return (
        <>
            {error && <InputError titleText={error.Error} />}
            <InputDropDown
                //errorMessage={error.Error}
                value={data.cause}
                editData={editData}
                name="cause"
                items={reasons}
                icon="icon_drop_down"
                titleText="Причина увольнения"
            />
            <InputTextArea
                //errorMessage={error.Error}
                value={data.details}
                editData={editData}
                name="details"
                type="area_middle"
                titleText="Подробности"
                placeholder="Опишите подробнее причину..."
                counterView={true}
                counterValue="400"
                style={{ marginBottom: "24px" }}
            />
            <Notification type="info" icon="info" style={{ margin: "-12px 0px 112px 0px" }}>
                Сотрудник должен сообщить об увольнении не позднее чем за {empProbEnd ? "14 дней" : "3 дня"} до желаемой даты. Рекомендуемая&nbsp;дата:&nbsp;
                {getRecDate(empProbEnd, current.Date)}
            </Notification>
            <div style={{ margin: "0px 0px 0px 0px" }}>
                <div className={styles.podzag}>Желаемая дата увольнения сотрудника</div>
                <div className={styles.dateSotr}>{current.DataUvolneniya}</div>
                <InputCalendar
                    recDate={getRecDate(empProbEnd, current.Date)}
                    titleText="Дата увольнения"
                    titleTextSlide="Дата увольнения"
                    //errorMessage={error.Error}
                    value={data.dateDismissal}
                    placeholder="ДД.ММ.ГГ"
                    editData={editData}
                    name="dateDismissal"
                    icon="Calendar-icon"
                    minDate={dateAsDate}
                />
            </div>
            {props.HasADFS == true ? (
                <>
                    <InputTitle titleText="До даты увольнения сотрудник" />
                    <div className={styles.choose}>
                        <div className={styles.choose_question} onClick={() => editData("dismBool", false)}>
                            <InputRadio secondTitle="Переводится на работу в офис" id_key="sam" name="why" />
                        </div>
                        <div className={styles.choose_question} onClick={() => editData("dismBool", true)}>
                            <InputRadio
                                secondTitle="Продолжает работать дистанционно. В случае нарушения сотрудником режима конфиденциальности всю ответственность беру на себя, риски компании осознаю"
                                id_key="nesam"
                                name="why"
                            />
                        </div>
                    </div>
                </>
            ) : null}
            <div className={styles.footer}>
                <SlideScreen altitudeLevel="service">
                    <Button
                        onClick={() => saveData("homeScreen", data)}
                        disabled={!(data.cause && data.dateDismissal && data.details && (props.HasADFS == true ? data.dismBool != undefined : true))}
                        type="save_not_margin"
                    >
                        Сохранить
                    </Button>
                </SlideScreen>
            </div>
        </>
    );
};

export default HomeUser;
