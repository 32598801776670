import React, { ReactElement, RefObject, useRef } from "react";
import styles from "../index.module.scss";
import Mail from "../../../../icons/mail-check.svg";
import Button from "../../../../components/Button";
import { INewDataPO } from "../../../../typec/IPODetails";

const NewDataPO = (props: INewDataPO): ReactElement => {
    const { showScreenNewData, status = "", saveFileNewData = () => null, load } = props;
    const fileRef: RefObject<HTMLInputElement> = useRef(null);

    const onFileChoose = (e) => {
        // debugger;
        const file: File = e.target.files[0];
        const fileFD = new FormData();
        fileFD.append("file", file, file.name);
        saveFileNewData(fileFD);
    };
    const showFileDialog = () => {
        fileRef.current?.dispatchEvent(new MouseEvent("click"));
    };
    return (
        <>
            <div className={styles.data_title}>Новые</div>
            {status ? (
                <>
                    <div className={styles.data_icon}>
                        <Mail />
                        Отправлены на согласование
                    </div>
                </>
            ) : (
                <div className={styles.data_btn_add}>
                    <Button type="save_not_margin" onClick={showScreenNewData}>
                        Добавить вручную
                    </Button>
                    <Button load={load} type="cancel_grey" onClick={showFileDialog}>
                        Добавить файлом
                    </Button>
                    <input className={styles["data_btn_add_inp"]} type="file" accept=".png, .jpg, .jpeg, .txt, .pdf" multiple={false} ref={fileRef} onChange={onFileChoose} />
                </div>
            )}
        </>
    );
};

export default NewDataPO;
