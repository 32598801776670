import React from "react";
import styles from "./index.module.scss";

const FilterItem = ({ label, isSelected, onCheckboxChange }) => (
    <div>
        <label className={styles.category}>
            {label}
            <div>
                <input className={styles.input} type="checkbox" checked={isSelected} onChange={onCheckboxChange} />
            </div>
        </label>
    </div>
);
export default FilterItem;
