import React, { ReactElement } from "react";
import styles from "../index.module.scss";
import Button from "../../../../components/Button";
import MailCheck from "../../../../icons/mail-check-large.svg";
import { ISendingData } from "../../../../typec/IPODetails";

const SendingData = (props: ISendingData): ReactElement => {
    const {
        closeScreenPut,
        goToMain,
        firstBtn = "Перейти к главному меню",
        seconstBtn = "Закрыть",
        secTitle = "В ближайшее время с вами свяжется специалист и поможет решить возникшие проблемы.",
        title = "Реквизиты успешно отправлены",
    } = props;
    return (
        <>
            <div className={styles.data_putScr}>
                <MailCheck />
                <p className={styles.data_putScr_title}>{title}</p>
                <p className={styles.data_putScr_text}>{secTitle}</p>
            </div>
            <div className={styles.data_putScr_btn}>
                <Button onClick={goToMain} type="save_not_margin">
                    {firstBtn}
                </Button>
                <Button onClick={closeScreenPut} type="cancel_white">
                    {seconstBtn}
                </Button>
            </div>
        </>
    );
};

export default SendingData;
