import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import InfoIcon from "../../../../icons/info.svg";
import VacationCheckList from "./components/VacationCheckList";
import { IVacationCheck } from "../../../../typec/IVacations";

const VacationCheck = (props: IVacationCheck): ReactElement => {
    const { statementDay, nameStaff, position, subdivision, supervisor, typeVacation, startDay, finishDay, quantityDay, periodArr = [""], disableDetails = false } = props;
    return (
        <div className={styles.vac_ch}>
            <p className={styles.vac_ch_title}>
                Заявление на отпуск <br /> от {statementDay}
            </p>
            <div className={styles.vac_ch_info_container}>
                <InfoIcon className={styles.vac_ch_info_container_icon} />
                <p className={styles.vac_ch_info_container_text}>
                    Проверьте введённые данные <br /> и отправьте заявку на согласование.
                </p>
            </div>
            <div className={styles.vac_ch_list}>
                <VacationCheckList
                    fullName={nameStaff}
                    period={periodArr[0] != "" ? periodArr : `C ${startDay} по ${finishDay}`}
                    position={position}
                    quantityDay={quantityDay}
                    subdivision={subdivision}
                    supervisor={supervisor}
                    typeVacation={typeVacation}
                    disableDetails={disableDetails}
                />
            </div>
            <div className={styles.vac_ch_void} />
        </div>
    );
};

export default VacationCheck;
