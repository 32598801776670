import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "../index.module.scss";
import { NavigationContext } from "../../../template";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../components/Navigation";
import Loader from "../../../components/Loader";
import clsx from "clsx";
import { AsgInvOfDep, AsgInvOfName } from "../componets/AssignedInventoryOfSubItem";
import AssignedInventItem from "../componets/AssignedInventItem";
import { IAsgInvOfSub, IarrInv, IinfoDataDep } from "../../../typec/IDocumentRequest";

const AssignedInventoryOfSub = (): ReactElement => {
    const [load, setLoad] = useState(true);
    const [isDepartment, setDepartment] = useState(true);
    const [isInfoName, setIsInfoName] = useState(false);
    const [infoData, setInfoData] = useState<{ name: string; arrInv: IarrInv; quantity: number; depName: string }>();
    const [isInfoDep, setInfoDep] = useState(false);
    const [infoDataDep, setInfoDataDep] = useState<IinfoDataDep>();
    const [data, setData] = useState<IAsgInvOfSub>();
    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        if (isInfoName) {
            setIsInfoName(false);
        }
        if (isInfoDep && isInfoName) {
            setIsInfoName(false);
        }
        if (!isInfoName && isInfoDep) {
            setInfoDep(false);
        }
        if (!isInfoName && !isInfoDep) navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_subordinates_new", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res == true) {
                        setLoad(false);
                        setData(json);
                    }
                });
        }
    });

    const quantityForName = (name: string): string => {
        let num = "0";
        const arr = data?.TMCs?.filter((el) => el.Name == name);
        let count = 0;
        if (arr) {
            arr[0].ListTMTS?.map(() => {
                count = count + 1;
            });
        }
        num = `${count}`;

        return num;
    };
    const quantityForDep = (name: string): string => {
        let num = "0";
        const arr = data?.TMCs?.filter((el) => el.PodrazdeleniyeName == name);
        let count = 0;
        arr?.map((el) => {
            el.ListTMTS.map(() => {
                count = count + 1;
            });
        });
        num = `${count}`;

        return num;
    };

    const setClickInfo = (name, quantity) => {
        if (data) {
            setInfoData({
                name: name,
                arrInv: data?.TMCs?.filter((el) => el.Name == name)[0]["ListTMTS"],
                quantity: quantity,
                depName: data?.TMCs?.filter((el) => el.Name == name)[0]["PodrazdeleniyeName"],
            });
        }
        setIsInfoName(true);
    };
    const setClickInfoDep = (nameDep, quantity) => {
        if (data) {
            setInfoDataDep({
                nameDep: nameDep,
                quantity: quantity,
                arrNames: data?.TMCs?.filter((el) => el.PodrazdeleniyeName == nameDep),
            });
        }
        setInfoDep(true);
    };

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    {isInfoName && (
                        <div className={styles.doc}>
                            <p className={styles.doc_title}>{infoData?.name}</p>
                            <p className={styles.doc_title_name_dep}>{infoData?.depName}</p>
                            {infoData?.arrInv?.map((el, index) => (
                                <AssignedInventItem
                                    key={`AssignedInventItem_of_sub_${index}`}
                                    nameTMTS={el.NameTMTS}
                                    kolichestvo={el.Kolichestvo}
                                    yedinitsaIzmereniya={el.YedinitsaIzmereniya}
                                />
                            ))}
                        </div>
                    )}
                    {isInfoDep && !isInfoName && (
                        <div className={styles.doc}>
                            <p className={styles.doc_title}>{infoDataDep?.nameDep}</p>
                            {infoDataDep?.arrNames?.map((el, index) => (
                                <AsgInvOfName onClick={setClickInfo} key={`AssInvOfName_2_${index}`} name={el.Name} quantity={quantityForName(el.Name)} />
                            ))}
                        </div>
                    )}
                    {!isInfoName && !isInfoDep && (
                        <div className={styles.doc}>
                            <div className={styles.doc_flex}>
                                <p className={styles.doc_flex_title}>Закрепленные ТМЦ подчиненных</p>
                            </div>
                            <p className={styles.doc_title_sub}>ТМЦ - товарно-материальные ценности</p>
                            <div className={styles.doc_title_container}>
                                <span onClick={() => setDepartment(true)} className={clsx(styles["doc_title_filter"], isDepartment && styles["doc_title_filter_activ"])}>
                                    Отдел
                                </span>
                                <span onClick={() => setDepartment(false)} className={clsx(styles["doc_title_filter"], !isDepartment && styles["doc_title_filter_activ"])}>
                                    ФИО
                                </span>
                            </div>
                            {isDepartment ? (
                                <>
                                    {data?.Subdivisions?.map((el, index) => (
                                        <AsgInvOfDep onClick={setClickInfoDep} key={`AssInvOfDep_${index}`} nameDep={el} quantity={quantityForDep(el)} />
                                    ))}
                                </>
                            ) : (
                                <>
                                    {data?.Names?.map((el, index) => (
                                        <AsgInvOfName onClick={setClickInfo} key={`AssInvOfName_${index}`} name={el} quantity={quantityForName(el)} />
                                    ))}
                                </>
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default AssignedInventoryOfSub;
