/* eslint-disable prefer-spread */
import { useContext, useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import styles from "./index.module.scss";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import Button from "../../../../components/Button";
import { NavigationContext } from "../../../../template";

const Docs = (props) => {
    const { file, vid_doc } = props;
    const [baseFile, setBaseFile] = useState<any>();
    const [URlfile, setURLfile] = useState<any>();
    const [error, setError] = useState<boolean>(false);
    const [numPages, setNumPages] = useState<number>();
    // const [pageNumber, setPageNumber] = useState<number>(1);

    const context = useContext(NavigationContext);

    useEffect(() => {
        fetch("/dev/get_example_document", {
            method: "POST",
            body: JSON.stringify({
                vid_pers: file,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    // Все ОК
                    console.log(json);
                    setBaseFile(json);
                    // let json_example = {
                    //     res: true,
                    //     pdf_b64: "data:application/pdf;base64,...", // Данные документа в base64
                    // };
                } else {
                    // Все не ОК
                    console.log(json);
                    // let json_example = {
                    //     res: false,
                    // };
                }
            });
    }, []);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    const downloadFile = () => {
        window.Telegram.WebApp.openLink(`https://telegram.sokolov.io/dev/get_example_document?vid_pers=${file}&vid_doc=docx&tg_id=${context?.userdata.tg_id}`);
    };

    return (
        <div className={styles.main}>
            <>
                {/* {numPages && numPages > 1 ? (
                    <p>
                        Страница {pageNumber} из {numPages}
                    </p>
                ) : null} */}

                {baseFile !== undefined ? (
                    <div className={styles.pdf}>
                        <Document file={baseFile?.pdf_b64} onLoadSuccess={onDocumentLoadSuccess}>
                            {Array.apply(null, Array(numPages))
                                .map((x, i) => i + 1)
                                .map((page) => {
                                    return <Page pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false} />;
                                })}
                        </Document>
                    </div>
                ) : (
                    <div className={styles.information}></div>
                )}
            </>
            <div className={styles.btn}>
                <Button onClick={downloadFile} type="save_not_margin">
                    Скачать образец
                </Button>
            </div>
        </div>
    );
};
export default Docs;
