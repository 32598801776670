import React from "react";
import { InputRadioAddressSpravka } from "../../../../../components/Inputs/InputRadioAddressSpravka";

const AddressListSpravka = (props) => {
    const { items, name, editData, setPrev, selectedStore } = props;

    const editPrev = (element) => {
        setPrev(element);
        editData(name, element);
    };

    return (
        <>
            {items?.map((element) => (
                <InputRadioAddressSpravka name={name} editData={editPrev} key={element.ID} id_key={`radio_inp_${element.ID}`} value={element} selectedStore={selectedStore} />
            ))}
        </>
    );
};

export default AddressListSpravka;
