import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Navigation from "../../components/Navigation";
import { NavigationContext } from "../../template";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import WhoWillGo from "./WhoWillGo/index";
import WhoWillPay from "./WhoWillPay/index";
import Address from "./Adress";
import TextInfo from "./TextInfo";
import Button from "../../components/Button";
import RecordExist from "./RecordExists";

const Rose = (): ReactElement => {
    const [load, setLoad] = useState(true);
    const [loadScreen, setLoadScreen] = useState(false);
    const [screens, setScreen] = useState(0);
    const [role, setRole] = useState<boolean>(true);
    const [jsonData, setJson] = useState();
    const [clear, setClear] = useState(false);

    useEffect(() => {
        if (clear) {
            setInfofr({
                full_name: "",
                phone_number: "",
            });
            setClear(false);
        }
    }, [clear]);

    const [data, setData] = useState({
        stores: {},
    });

    console.log(data);

    const [currentAddress, setCurrentAddress] = useState({});
    const [dataFilter, setDataFilter] = useState<any>({});
    const [record, setRecord] = useState<any>({});
    const [error, setError] = useState({
        ErrorCode: "",
        Error: "",
    });

    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        if (screens === 0) {
            navigate("/");
            setCurrentAddress({});
        } else if (screens !== 0 && Object.keys(currentAddress).length === 0) {
            setScreen(screens - 1);
            // setCurrentAddress({});
        } else {
            setCurrentAddress({});
        }
    };

    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };

    const next = () => {
        if (screens < 3) {
            setScreen(screens + 1);
            setCurrentAddress({});
        } else {
            setScreen(0);
            getLoad();
        }
    };
    const start = () => {
        setScreen(0);
    };

    const editData = (key, element) => {
        setData({
            ...data,
            [key]: element,
        });
    };

    const clearError = () => {
        setError({
            ErrorCode: "",
            Error: "",
        });
    };

    const switchRole = (frst = false) => {
        if (screens == 0) {
            role ? setRole(false || frst) : setRole(true || frst);
        } else {
            setRole(true || frst);
        }
    };

    const recordIsNull = (data) => {
        let bool = true;
        const users = data.data.users;
        if (`${users}` == "" || users?.res == false) {
            bool = false;
        }
        return bool;
    };

    const getLoad = () => {
        setLoad(true);
    };
    const [infofr, setInfofr] = useState({
        full_name: "",
        phone_number: "",
    });

    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_rose_users", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res) {
                        console.log(json);
                        setRecord(json);
                    } else {
                        console.log(json);
                        setRecord({
                            res: false,
                            err: json.err,
                            data: {
                                users: [],
                            },
                        });
                    }
                    setLoad(false);
                });
        }
    }, [load]);

    useEffect(() => {
        fetch(`/dev/vacation_rose_stores?region=${""}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    setDataFilter(json.data);
                } else {
                    console.log(json);
                }
            });
    }, [load]);

    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <div className={styles.dis}>
                        <p className={styles.dis_title}>{role ? "Роза SOKOLOV" : "Стать спонсором акции"}</p>

                        <>
                            {screens === 0 ? (
                                recordIsNull(record) ? (
                                    <RecordExist actionElement={next} switchRole={switchRole} getLoad={getLoad} data={record} />
                                ) : (
                                    <TextInfo actionElement={next} switchRole={switchRole} role={role} error={error} />
                                )
                            ) : null}
                            {screens === 1 ? (
                                <Address
                                    addData={editData}
                                    clearError={clearError}
                                    actionElement={next}
                                    stores={data.stores}
                                    dataFilter={dataFilter}
                                    setPrev={setCurrentAddress}
                                    prev={currentAddress}
                                />
                            ) : null}

                            {screens === 2 ? <WhoWillGo stores={data.stores} setClear={setClear} setInfofr={setInfofr} infofr={infofr} actionElement={next} /> : null}
                            {screens === 3 ? <WhoWillPay stores={data.stores} setClear={setClear} infofr={infofr} actionElement={next} /> : null}
                        </>
                    </div>
                </>
            )}
        </>
    );
};

export default Rose;
