import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import { InputCalendar, InputRadio, InputText, InputTitle } from "../../../../components/Inputs";
import Button from "../../../../components/Button";
import NewPhotoButtonLimit from "../../../ClietSupport/NewAppeal/components/NewPhotoButtonLimit";
import SlideScreen from "../../../../components/SlideScreen";
import Notification from "../../../../components/Notification";

export interface IOneHalfYear {
    viewMoreChild: boolean | undefined;
    viewLeave: number;
    setOneChild: (el) => void;
    oneСhild: boolean | undefined;
    dataChild: any;
    setIndexChild: (el) => void;
    setViewSlider: (el) => void;
    addActualChildren: () => void;
    editActualChildren: (key, element, index) => void;
    addPhotoActualChildren: (el, index) => void;
    indexChild: number;
    setViewBank: (el) => void;
    setViewMoreChild: (el) => void;
    viewSlider: boolean;
    deleteActualChildren: (index, bool?) => void;
    setIndexChildPrevious: (el) => void;
    editPreviousChildren: (key, element, index) => void;
    addPreviousChildren: () => void;
    addPhotoPreviousChildren: (el) => void;
    deletePreviousChildren: (index, bool?) => void;
    indexChildPrevious: number;
}

const OneHalfYear = (props: IOneHalfYear): ReactElement => {
    const {
        dataChild,
        oneСhild,
        setIndexChild,
        setOneChild,
        setViewSlider,
        viewLeave,
        viewMoreChild,
        addActualChildren,
        addPhotoActualChildren,
        editActualChildren,
        indexChild,
        deleteActualChildren,
        setViewBank,
        setViewMoreChild,
        viewSlider,
        setIndexChildPrevious,
        editPreviousChildren,
        addPreviousChildren,
        addPhotoPreviousChildren,
        deletePreviousChildren,
        indexChildPrevious,
    } = props;
    return (
        <>
            <div className={styles.hero}>
                <p className={styles.hero_main_heading}>Отпуск по уходу за ребенком до&nbsp;1,5&nbsp;лет</p>
                {!viewMoreChild ? (
                    <>
                        <p className={styles.hero_questions_title}>Это ваш первый ребенок?</p>
                        <div className={styles.hero_questions} style={{ marginBottom: "16px" }}>
                            <InputRadio id_key={`1_${viewLeave}`} secondTitle="Да" onClick={() => setOneChild(true)} checked={oneСhild} />
                            <InputRadio id_key={`2_${viewLeave}`} secondTitle="Нет" onClick={() => setOneChild(false)} checked={oneСhild != undefined ? !oneСhild : undefined} />
                        </div>
                    </>
                ) : null}
                {(oneСhild || !oneСhild) && !viewMoreChild && oneСhild != undefined ? (
                    <>
                        <div className={styles.hero_one_child}>
                            <div>
                                {/* <Notification style={{ marginTop: "0px", marginBottom: "12px" }} icon="info" type="info">
                                        Для заполнения заявления вам понадобятся документы на всех детей (свидетельства о рождении)
                                    </Notification> */}
                                {oneСhild == false ? (
                                    <>
                                        <p className={styles.hero_title_sub_grey}>
                                            Для заполнения заявления вам понадобятся документы на ранее рожденных детей (свидетельства о рождении)
                                        </p>
                                        <Notification style={{ marginTop: "0px", marginBottom: "12px" }} icon="info" type="info">
                                            Заполните информацию о новорожденных детях
                                        </Notification>
                                    </>
                                ) : null}
                                {dataChild?.actualChildren[1]?.name?.length > 0 &&
                                dataChild?.actualChildren[1]?.birthdate?.length > 0 &&
                                dataChild?.actualChildren[1]?.files?.length > 0 ? (
                                    <>
                                        {dataChild.actualChildren?.map((element, index) => (
                                            <div key={`card_child_${index}_${element.name}`} className={styles.hero_card}>
                                                <p className={styles.hero_card_name}>{element.name}</p>
                                                <p className={styles.hero_card_birthdate}>{element.birthdate}</p>
                                                <Button
                                                    icon="change"
                                                    type="change_not_vw"
                                                    onClick={() => {
                                                        setIndexChild(index);
                                                        setViewSlider(true);
                                                    }}
                                                >
                                                    Изменить данные
                                                </Button>
                                            </div>
                                        ))}
                                        <Button
                                            style={{ marginTop: "12px" }}
                                            type="add_vacation"
                                            icon="plus-interface"
                                            onClick={() => {
                                                setViewSlider(true);
                                                addActualChildren();
                                            }}
                                        >
                                            Добавить еще ребенка
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <InputText
                                            type="fullString"
                                            titleText="ФИО ребенка"
                                            placeholder="Иванов Иван Иванович"
                                            value={dataChild.actualChildren[0]?.name}
                                            onChange={(e) => editActualChildren("name", e, 0)}
                                        />
                                        <InputCalendar
                                            titleTextSlide="Дата рождения"
                                            style={{ marginTop: "16px" }}
                                            titleText="Дата рождения ребенка"
                                            name="birthdate"
                                            value={dataChild.actualChildren[0]?.birthdate}
                                            disableValue
                                            placeholder="ДД.ММ.ГГ"
                                            maxDetail="month"
                                            minDetail="decade"
                                            editData={(key, element) => editActualChildren(key, element, 0)}
                                        />
                                        <InputTitle style={{ marginTop: "-8px", marginBottom: "0" }} titleText="Документы" />
                                        <p className={styles.hero_title_sub_grey}>
                                            Прикрепите фото документов:
                                            <br />
                                            1. Свидетельство о рождении,
                                            <br />
                                            2. СНИЛС,
                                            <br />
                                            3. Свидетельство о браке/разводе,
                                            <br />
                                            4. Справка об установлении отцовства.
                                        </p>
                                        <div className={styles.hero_photos}>
                                            <NewPhotoButtonLimit limit={20} onChange={(el) => addPhotoActualChildren(el, 0)} data={dataChild.actualChildren[0].files} />
                                        </div>
                                        {/* {error ? <p className={styles.doc_item_edit_error}>{error}</p> : null} */}
                                    </>
                                )}
                            </div>
                            <div>
                                {dataChild?.actualChildren[1]?.name?.length > 0 &&
                                dataChild?.actualChildren[1]?.birthdate?.length > 0 &&
                                dataChild?.actualChildren[1]?.files?.length > 0 ? null : (
                                    <Button
                                        disabled={
                                            dataChild.actualChildren[0].name.length > 0 &&
                                            dataChild.actualChildren[0].birthdate.length > 0 &&
                                            dataChild.actualChildren[0].files.length > 0
                                                ? false
                                                : true
                                        }
                                        type="cancel_grey"
                                        onClick={() => {
                                            setViewSlider(true);
                                            addActualChildren();
                                        }}
                                    >
                                        Добавить еще ребенка
                                    </Button>
                                )}
                                {oneСhild ? (
                                    <Button style={{ marginTop: "6px" }} type="save_not_margin" onClick={() => setViewBank(true)}>
                                        Заполнить реквизиты карты
                                    </Button>
                                ) : (
                                    <Button style={{ marginTop: "6px" }} type="save_not_margin" onClick={() => setViewMoreChild(true)}>
                                        Заполнить информацию о ранее&nbsp;рожденных&nbsp;детях
                                    </Button>
                                )}
                            </div>
                        </div>
                        {viewSlider ? (
                            <SlideScreen
                                altitudeLevel="full"
                                titleText="Добавить ребенка"
                                onClose={() => {
                                    setViewSlider(false);
                                    deleteActualChildren(indexChild);
                                }}
                            >
                                <div className={styles.hero_one_child_slider}>
                                    <div>
                                        <InputText
                                            style={{ marginTop: "16px" }}
                                            type="fullString"
                                            titleText="ФИО ребенка"
                                            placeholder="Иванов Иван Иванович"
                                            value={dataChild.actualChildren[indexChild].name}
                                            onChange={(e) => editActualChildren("name", e, indexChild)}
                                        />
                                        <InputCalendar
                                            titleTextSlide="Дата рождения"
                                            style={{ marginTop: "16px" }}
                                            titleText="Дата рождения ребенка"
                                            name="birthdate"
                                            value={dataChild.actualChildren[indexChild].birthdate}
                                            disableValue
                                            placeholder="ДД.ММ.ГГ"
                                            maxDetail="month"
                                            minDetail="decade"
                                            editData={(key, element) => editActualChildren(key, element, indexChild)}
                                        />
                                        <InputTitle style={{ marginTop: "-8px", marginBottom: "0" }} titleText="Документы" />
                                        <p className={styles.hero_title_sub_grey}>
                                            Прикрепите фото документов:
                                            <br />
                                            1. Свидетельство о рождении,
                                            <br />
                                            2. СНИЛС,
                                            <br />
                                            3. Свидетельство о браке/разводе,
                                            <br />
                                            4. Справка об установлении отцовства.
                                        </p>
                                        <div className={styles.hero_photos}>
                                            <NewPhotoButtonLimit
                                                data={dataChild.actualChildren[indexChild].files}
                                                limit={20}
                                                onChange={(el) => addPhotoActualChildren(el, indexChild)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            type="save_not_margin"
                                            onClick={() => setViewSlider(false)}
                                            disabled={
                                                dataChild.actualChildren[indexChild].name.length > 0 &&
                                                dataChild.actualChildren[indexChild].birthdate.length > 0 &&
                                                dataChild.actualChildren[indexChild].files.length > 0
                                                    ? false
                                                    : true
                                            }
                                        >
                                            Сохранить
                                        </Button>
                                        <Button
                                            type="cancel_white"
                                            onClick={() => {
                                                deleteActualChildren(indexChild, true);
                                                setViewSlider(false);
                                            }}
                                        >
                                            Удалить из списка
                                        </Button>
                                    </div>
                                </div>
                            </SlideScreen>
                        ) : null}
                    </>
                ) : viewMoreChild != undefined ? (
                    <>
                        <div className={styles.hero_more_child}>
                            <div>
                                <Notification icon="info" type="info" style={{ marginTop: "0px", marginBottom: "12px" }}>
                                    Заполните данные о ранее рожденных детях.
                                </Notification>
                                {dataChild?.previousChildren[1]?.name?.length > 0 && dataChild?.previousChildren[1]?.birthdate?.length > 0 ? (
                                    <>
                                        {dataChild.previousChildren?.map((element, index) => (
                                            <div key={`card_child_${index}_${element.name}`} className={styles.hero_card}>
                                                <p className={styles.hero_card_name}>{element.name}</p>
                                                <p className={styles.hero_card_birthdate}>{element.birthdate}</p>
                                                <Button
                                                    icon="change"
                                                    type="change_not_vw"
                                                    onClick={() => {
                                                        setIndexChildPrevious(index);
                                                        setViewSlider(true);
                                                    }}
                                                >
                                                    Изменить данные
                                                </Button>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <InputText
                                            key="input_text_first_children_previous"
                                            type="fullString"
                                            titleText="ФИО ребенка"
                                            placeholder="Иванов Иван Иванович"
                                            value={dataChild.previousChildren[0].name}
                                            onChange={(e) => editPreviousChildren("name", e, 0)}
                                        />
                                        <InputCalendar
                                            titleTextSlide="Дата рождения"
                                            style={{ marginTop: "16px", marginBottom: "-24px" }}
                                            titleText="Дата рождения ребенка"
                                            name="birthdate"
                                            value={dataChild.previousChildren[0].birthdate}
                                            disableValue
                                            placeholder="ДД.ММ.ГГ"
                                            maxDetail="month"
                                            minDetail="decade"
                                            editData={(key, element) => editPreviousChildren(key, element, 0)}
                                        />
                                    </>
                                )}
                                <Button
                                    style={{ marginTop: "12px" }}
                                    disabled={dataChild.previousChildren[0].name.length > 0 && dataChild.previousChildren[0].birthdate.length > 0 ? false : true}
                                    icon="plus-interface"
                                    type="add_vacation"
                                    onClick={() => {
                                        setViewSlider(true);
                                        addPreviousChildren();
                                    }}
                                >
                                    Добавить еще ребенка
                                </Button>
                                <InputTitle style={{ marginTop: "12px", marginBottom: "0" }} titleText="Документы" />
                                <p className={styles.hero_title_sub_grey}>
                                    Прикрепите фото документов:
                                    <br />
                                    1. Свидетельство о рождении ребенка (всех детей)
                                </p>
                                <div className={styles.hero_photos}>
                                    <NewPhotoButtonLimit limit={20} onChange={addPhotoPreviousChildren} data={dataChild.previousChildrenFiles} />
                                </div>
                                {/* {error ? <p className={styles.doc_item_edit_error}>{error}</p> : null} */}
                            </div>
                            <div>
                                <Button style={{ marginTop: "6px" }} type="save_not_margin" onClick={() => setViewBank(true)}>
                                    Заполнить реквизиты карты
                                </Button>
                            </div>
                        </div>
                        {viewSlider ? (
                            <SlideScreen
                                altitudeLevel="children"
                                titleText="Добавить ребенка"
                                onClose={() => {
                                    setViewSlider(false);
                                    deletePreviousChildren(indexChildPrevious);
                                }}
                            >
                                <div className={styles.hero_more_child_slider}>
                                    <div>
                                        <InputText
                                            style={{ marginTop: "16px" }}
                                            type="fullString"
                                            titleText="ФИО ребенка"
                                            placeholder="Иванов Иван Иванович"
                                            value={dataChild.previousChildren[indexChildPrevious].name}
                                            onChange={(e) => editPreviousChildren("name", e, indexChildPrevious)}
                                        />
                                        <InputCalendar
                                            titleTextSlide="Дата рождения"
                                            style={{ marginTop: "16px" }}
                                            titleText="Дата рождения ребенка"
                                            name="birthdate"
                                            value={dataChild.previousChildren[indexChildPrevious].birthdate}
                                            disableValue
                                            placeholder="ДД.ММ.ГГ"
                                            maxDetail="month"
                                            minDetail="decade"
                                            editData={(key, element) => editPreviousChildren(key, element, indexChildPrevious)}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            type="save_not_margin"
                                            onClick={() => setViewSlider(false)}
                                            disabled={
                                                dataChild.previousChildren[indexChildPrevious].name.length > 0 &&
                                                dataChild.previousChildren[indexChildPrevious].birthdate.length > 0
                                                    ? false
                                                    : true
                                            }
                                        >
                                            Сохранить
                                        </Button>
                                        <Button
                                            type="cancel_white"
                                            onClick={() => {
                                                deletePreviousChildren(indexChildPrevious, true);
                                                setViewSlider(false);
                                            }}
                                        >
                                            Удалить из списка
                                        </Button>
                                    </div>
                                </div>
                            </SlideScreen>
                        ) : null}
                    </>
                ) : null}
            </div>
        </>
    );
};

export default OneHalfYear;
