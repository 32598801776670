import React, { LegacyRef, ReactElement, useRef, useState } from "react";
import styles from "../index.module.scss";
import clsx from "clsx";
import { InputTitle } from "../InputTitle";
import { InputError } from "../InputError";
import { IInputINNProps } from "../../../typec/IInputs";

// const InputSearch = (props: IInputINNProps): ReactElement => {

const InputSearch = (props): ReactElement => {
    const { type, titleText, errorMessage, value, placeholder, style, onChange } = props;
    const ref = useRef<any>();

    const changeSearch = (e) => {
        onChange?.(e);
    };

    return (
        <div style={style}>
            {titleText && <InputTitle titleText={titleText} />}
            <input
                ref={ref}
                onChange={changeSearch}
                placeholder={placeholder}
                defaultValue={value}
                className={clsx(styles["inp"], styles[`inp_${type}`], errorMessage && styles[`inp_error`])}
            />
            {errorMessage && <InputError titleText={errorMessage} />}
        </div>
    );
};

export { InputSearch };
