import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import ym from "react-yandex-metrika";

export const Redirect = (props) => {
    const nav = useNavigate();
    const location = useLocation();
    const [redirect, setRedirect] = useState(false);
    const [searchParam, setSearchParam] = useSearchParams();

    useEffect(() => {
        if (!props.idTgBotGroup?.includes(props.auth)) {
            ym("hit", `${location.pathname}`);
            if (redirect) ym("hit", "/");
        }
    }, [location, redirect]);

    useEffect(() => {
        if (searchParam.size > 0) {
            if (searchParam.get("forward_to") != undefined && searchParam.get("app_id") != undefined) {
                props.setRedirect({
                    app_id: searchParam.get("app_id"),
                    forward_to: searchParam.get("forward_to"),
                });
            }
            if (searchParam.get("forward_to") != null) nav(`/${searchParam.get("forward_to")}`);
        }
    }, [location.search]);

    useEffect(() => {
        if (`${searchParam.size}` == "0") {
            setRedirect(true);
            nav("/");
        }
    }, []);

    return null;
};
