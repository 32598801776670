import React, { ReactElement, useState } from "react";
import { InputCalendar, InputDropDown, InputError, InputTextArea } from "../../../../components/Inputs";
import SlideScreen from "../../../../components/SlideScreen";
import Button from "../../../../components/Button";
import styles from "./index.module.scss";
import { IHomeSCRDisList } from "../../../../typec/IDismissalStaff";
import NewPhotoButtonLimit from "../../../ClietSupport/NewAppeal/components/NewPhotoButtonLimit";

const HomeScreenDismissal = (props: IHomeSCRDisList): ReactElement => {
    const { actionElement = () => null, reasons, addData, homeScreenData, clearError = () => null, error } = props;
    const [data, setData] = useState({
        cause: homeScreenData.cause,
        details: homeScreenData.details,
        dateDismissal: homeScreenData.dateDismissal,
        scan: homeScreenData.scan, //? homeScreenData.scan : ["data:image/jpeg;base64"],
    });
    const [err, setErr] = useState("");

    const templateYear = Number(`${new Date().getFullYear()}`);
    const templateMonth = Number(`${new Date().getMonth()}`);
    const templateDay = Number(`${new Date().getDate()}`);

    const editData = (key, element) => {
        clearErr();
        if (key != "dateDismissal") {
            setData({
                ...data,
                [key]: element,
            });
        } else {
            // if (prob_end) {
            const date = new Date().getTime() + 87400;
            if (toDate(element) > date) {
                setData({
                    ...data,
                    [key]: element,
                });
            } else {
                setErr("Нельзя ставить дату увольнения раньше чем за 1 день");
            }
            // } else {
            //     const date = new Date().getTime() + 172800000;
            //     if (toDate(element) > date) {
            //         setData({
            //             ...data,
            //             [key]: element,
            //         });
            //     } else setErr("Нельзя ставить дату увольнения раньше 3-х дней");
            // }
        }
    };
    const saveData = (key, element) => {
        addData(key, element);
        actionElement();
    };

    const clearErr = () => {
        clearError();
        setErr("");
    };

    const toDate = (el: string): number => {
        const year = el.split(".")[2].replace("2", "202");
        const month = el.split(".")[1];
        const day = el.split(".")[0];
        return new Date(Number(year), Number(month) - 1, Number(day)).getTime();
    };

    const addPhoto = (element) => {
        setData((prevState) => ({
            ...prevState,
            scan: element,
        }));
    };

    const thisPhoto = (arr): boolean => {
        let bool = false;
        arr?.map((element) => {
            if (element.data != "data:image/jpeg;base64" && element.data != "") {
                bool = true;
            }
        });
        return bool;
    };

    return (
        <>
            <InputDropDown
                key="InpDropDownDismassal"
                errorMessage={error?.Error}
                value={data.cause}
                editData={editData}
                name="cause"
                items={reasons}
                icon="icon_drop_down"
                titleText="Причина увольнения"
            />
            <InputTextArea
                key="InpTextAreaDismassal"
                errorMessage={error?.Error}
                value={data.details}
                editData={editData}
                name="details"
                type="area_large"
                titleText="Подробности"
                placeholder="Опишите подробнее причину..."
                counterView={true}
                counterValue="400"
                style={{ marginBottom: "24px" }}
            />
            <InputCalendar
                disableWeeks={false}
                minDate={new Date(templateYear, templateMonth, templateDay + 1)}
                key="InpCalendarDismissal"
                titleText="Дата увольнения"
                titleTextSlide="Дата увольнения"
                errorMessage={error?.Error}
                value={data.dateDismissal}
                editData={editData}
                name="dateDismissal"
                icon="Calendar-icon"
            />
            {err && <InputError titleText={err} />}
            <p className={styles.second_title}>Загрузите скан или фото заявления на увольнение</p>
            {/* <div key="DivDismassalPhotos" className={styles.photos}>
                <PhotoButton onChange={(value) => addPhoto(value)} />
                {data.scan?.map((element, index) => <>{<ImageItem src={element} key_photo={`dismissal_file_${index}`} onClick={() => deletePhoto(element)} />}</>).reverse()}
            </div> */}
            <div key="DivDismassalPhotos" className={styles.photos}>
                <NewPhotoButtonLimit data={thisPhoto(data.scan) ? data.scan : ""} onChange={(value) => addPhoto(value)} limit={20} />
            </div>
            <div className={styles.footer}>
                <SlideScreen altitudeLevel="service">
                    <Button onClick={() => saveData("homeScreen", data)} disabled={!(data.cause && data.dateDismissal && thisPhoto(data.scan))} type="save_not_margin">
                        Далее
                    </Button>
                </SlideScreen>
            </div>
        </>
    );
};

export default HomeScreenDismissal;
