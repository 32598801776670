import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Navigation from "../../components/Navigation";
import { NavigationContext } from "../../template";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Filter from "../../icons/filter.svg";
import MenuFiltr from "../RequestsList/MenuFiltr";
import NewRequest from "../RequestsList/NewRequest";
import FiltrComponent from "../RequestsList/FilterComponent";
import ListItem from "../RequestsList/ListItem";
import List from "../RequestsList/List";
import ListNull from "../RequestsList/ListNull";
import menu from "../../components/Menu/Menu";
import Submit from "./Submit";

const RequestsList = (props): ReactElement => {
    const context = useContext(NavigationContext);
    const [load, setLoad] = useState(false);
    const [reqNull, setReqNull] = useState(true);
    const [rawData, setRawData] = useState<Array<any>>([]);
    const [jsonData, setJsonData] = useState<Array<any>>([]);
    const [currentFilter, setCurrentFilter] = useState<Array<string>>([]);
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };
    useEffect(() => {
        if (!load) {
            setLoad(true);
        }
    }, []);

    const filterAdd = (filterName: string) => {
        setCurrentFilter([...currentFilter, filterName]);
    };
    const filterDelete = (filterName) => {
        const newArr = currentFilter.filter((str) => str !== filterName);
        setCurrentFilter(newArr);
    };
    const filterAction = (arr: Array<string>) => {
        setCurrentFilter(arr);
    };

    useEffect(() => {
        if (currentFilter.length) {
            const newList: Array<any> = [];
            if (currentFilter.includes("В работе")) {
                newList.push(...rawData.filter((el) => el.ApprDecl == 0));
            }
            if (currentFilter.includes("Завершено")) {
                newList.push(...rawData.filter((el) => el.ApprDecl == 1));
            }
            if (currentFilter.includes("Отклонено или отменено")) {
                newList.push(...rawData.filter((el) => el.ApprDecl == -1));
            }
            setJsonData(newList);
            console.log(currentFilter);
        }
    }, [currentFilter]);
    useEffect(() => {
        if (load) {
            fetch("/dev/vacation_list_boss_new/", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.res == true) {
                        setLoad(false);
                        setReqNull(false);
                        setJsonData(json.list);
                        setRawData(json.list);
                    } else {
                        setLoad(false);
                    }
                })
                .catch((e) => {
                    console.error(e);
                    setLoad(false);
                });
        }
    }, [load]);

    const getLoad = () => {
        setLoad(true);
    };
    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <MenuFiltr isApprovePage={true} filterAction={filterAction} data={jsonData} menu={menu}></MenuFiltr>
                    {reqNull ? (
                        <ListNull></ListNull>
                    ) : (
                        <div className={styles.list}>
                            <List getLoad={getLoad} isApprovePage={true} data={jsonData}></List>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default RequestsList;
